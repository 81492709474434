import { useState } from 'react'
import { ChannelModal } from './ChannelModal'
import { Table, Fields, Actions } from 'cf-components/table/Table'
import { Channel, Bot, Team, User } from 'classes/classes'

const fields: Fields<Channel> = [
  { name: 'name', type: 'avatar' },
  { name: 'teamIDs', type: 'avatarGroup', objectType: Team, label: 'Teams', sort: false },
  { name: 'userIDs', type: 'avatarGroup', objectType: User, label: 'Agents', sort: false },
  { name: 'botIDs', type: 'avatarGroup', objectType: Bot, label: 'Bots', sort: false }
]

export default function ChannelListPage (props: any): JSX.Element {
  const { data: channels, isLoading } = Channel.loadAll()
  const [editChannel, setEditChannel] = useState<Channel | null>(null)
  props.actionRef.current = () => setEditChannel(new Channel())

  const actions: Actions<Channel> = [
    { type: 'delete' },
    { type: 'edit', action: (row: Channel) => setEditChannel(row) }
  ]

  return (
    <div>
      <Table
        rows={channels.list}
        fields={fields}
        columnStyles='2fr 2fr 2fr 2fr'
        isLoading={isLoading}
        actions={actions}
      />
      {editChannel &&
        <ChannelModal
          channel={editChannel}
          onHide={() => setEditChannel(null)}
        />}
    </div>
  )
}
