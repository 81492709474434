import { createContext, useReducer } from 'react'
import { useQuery } from 'react-query'
import {
  getPaymentProfiles,
  getBillingSubscription,
  getSubscriptionComponents,
  getComponentUsage,
  getIntegrationUsage,
  getBillingSettings
} from 'api/billing'

import {
  updateIntegrationV2
} from 'api/integrations'

const BillingContext = createContext({
  usage: {
    users: {},
    tests: {},
    bots: {},
    texts: {},
    identification: {
      allocated: 0,
      component: ''
    },
    contact_credits: {
      allocated: 0,
      component: ''
    }
  },
  primaryPaymentProfileId: null,
  primaryPayment: {
    attributes: {
      masked_card_number: '',
      expiration_month: 1,
      expiration_year: 2023,
      card_type: ''
    }
  },
  billingSubscription: null,
  components: null,
  payments: null,
  integrations: null,
  billing: {
    attributes: {
      plan: '',
      price: 0,
      billing_period: '',
      current_period_ends: ''
    }
  },
  usageLoading: true,
  contactCredits: {},
  handleSetEnrichEnabled: () => null,
  billingSettings: {
    settings: {
      auto_provision: { 'reverse-ip': true },
      lookups_plus: false
    }
  }
})

const reducer = (state, changes) => {
  return { ...state, ...changes }
}

function BillingProvider (props) {
  const [contactCredits, setContactCredits] = useReducer(reducer, { allocated: 0, prospect_used: 0, total_limit: 0, enrich_enabled: true })

  const handleSetEnrichEnabled = () => {
    setContactCredits({ enrich_enabled: !contactCredits.enrich_enabled })
    updateIntegrationV2({ integrationName: 'apollo', enabled: !contactCredits.enrich_enabled })
  }

  const getPrimaryPayment = () => {
    if (!primaryPaymentProfileId) {
      return null
    }

    const primaryPayment = payments?.filter(p => p.id === primaryPaymentProfileId)?.[0]
    return primaryPayment
  }

  const getBilling = () => {
    return billingSubscription
  }

  const getBillingMode = () => {
    if (billingSubscription === null) {
      return 'not_subscribed'
    }

    if (billingSubscription?.attributes?.state) {
      const billingMode = billingSubscription?.attributes?.state
      return billingMode
    }
  }

  const refetchBilling = () => {
    return _refetchBilling()
      .then(
        _refetchComponents()
      )
  }

  const { data: billingSettings } = useQuery('billing_settings', () => getBillingSettings()
    .then(response => {
      if (response.data) {
        return response.data.attributes
      }
    }))

  const { data: integrations } = useQuery('integrations', () => getIntegrationUsage()
    .then(response => {
      if (response.data) {
        // console.log('Integrations data', response.data)
      }
    }), { staleTime: 120000 })

  const { data: usage, isLoading } = useQuery('component_usage', () => getComponentUsage()
    .then(response => {
      if (response.data) {
        const usage = response.data.attributes.usage
        setContactCredits(usage.contact_credits)
        return usage
      }
    }), { staleTime: 120000 })

  const { data: payments = null, refetch: refetchPayments } = useQuery('payments', () => getPaymentProfiles()
    .then(response => {
      if (response.data) {
        const payments = response.data || []
        return payments
      }
    }), { staleTime: 60000, refetchOnWindowFocus: true })

  const { data: components = null, refetch: _refetchComponents } = useQuery('components', () => getSubscriptionComponents()
    .then(response => {
      if (response.data) {
        const components = response.data || []
        return components
      }
    }), { staleTime: 60000, refetchOnWindowFocus: true })

  const {
    data:
    { primaryPaymentProfileId, billingSubscription } = { primaryPaymentProfileId: null, billingSubscription: {} },
    refetch: _refetchBilling
  } = useQuery('subscription', () => getBillingSubscription(1)
    .then(response => {
      if (response.data?.attributes) {
        const defaultPaymentId = response.data.attributes.default_payment_id || 0
        return { primaryPaymentProfileId: defaultPaymentId, billingSubscription: response.data }
      }
    }).catch(() => {
      return { primaryPaymentProfileId: null, billingSubscription: null }
    }), { staleTime: 60000, refetchOnWindowFocus: true })

  const primaryPayment = getPrimaryPayment()
  const billing = getBilling()
  const billingMode = getBillingMode()

  const usageLoading = isLoading

  return (
    <BillingContext.Provider
      value={{
        refetchPayments,
        refetchBilling,
        primaryPayment,
        billing,
        components,
        payments,
        billingMode,
        usage,
        integrations,
        usageLoading,
        contactCredits,
        handleSetEnrichEnabled,
        billingSettings
      }}
    >
      {props.children}
    </BillingContext.Provider>
  )
}

export { BillingContext, BillingProvider }
