import { dynamicButtonArray, Modal, ModalSection } from 'library/Modal';

export interface ConfirmationModalProps {
  title: string
  subtitle?: string
  children: JSX.Element | JSX.Element[]
  dynamicButtons?: dynamicButtonArray
  onHide?: () => void
}

export default function ConfirmationModal ({ title, subtitle, children, dynamicButtons, onHide }: ConfirmationModalProps) {
  return (
    <div style={{ zIndex: 2000 }}>
      <Modal
        open
        onHide={onHide || (() => undefined)}
        title={title}
        handleSave={() => undefined}
        dynamicButtons={dynamicButtons}
        key={`${title}_CFConModal`}
        noDividers
      >
        <ModalSection
          subtitle={subtitle}
        >
          {children}
        </ModalSection>
      </Modal>
    </div>
  )
}
