import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardCard from './DashboardCard'
import { getCalendarEvents, getCalendarEventsLog } from 'api/calendars'
import { getUsers } from 'api/users'
import { contactSearch } from 'api/contacts'
import Divider from '@material-ui/core/Divider'
import dateFormat from 'dateformat'
import EmptyBox from 'cf-components/EmptyBox'
import { Tooltip, Typography } from '@material-ui/core'

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
]

const useStyles = makeStyles(theme => ({
  cardContents: {
    height: '100%',
    minHeight: 160,
    fontFamily: 'poppins, sans serif'
  },
  loadingScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  meetingRow: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr 1fr 1fr',
    paddingBottom: 10,
    paddingTop: 10,
    alignItems: 'center'
  },
  calendarDiv: {
    backgroundColor: '#505050',
    color: '#FFFFFF',
    borderRadius: 8,
    paddingBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px'
  },
  month: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'poppins',
    paddingTop: '3px'
  },
  date: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center'
  },
  divider: {
    backgroundColor: '#D3D3D3'
  },
  userName: {
    color: '#343434',
    fontSize: '14px',
    fontWeight: 600
  },
  visitorName: {
    color: '#343434',
    fontSize: '14px',
    fontWeight: 100
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr 1fr 1fr',
    paddingTop: 14,
    paddingBottom: 8,
    alignItems: 'center',
    fontSize: '12px'
  },
  headerText: {
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080'
  },
  rows: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontStyle: 'poppins'
  }
}))

function LoadingScreen (props) {
  return (
    <div>
      Loading
    </div>
  )
}

function MeetingCardHeader () {
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <div style={{ paddingLeft: 65 }} className={classes.headerText}>
        Created Meeting
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.headerText}>
        Meeting With
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.headerText}>
        Company Name
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.headerText}>
        Time
      </div>
    </div>
  )
}

function MeetingRow (props) {
  const classes = useStyles()
  const contact = props.contact
  const meeting = props.meeting
  const startDate = new Date(meeting.attributes.scheduled_start)
  const lastItem = props.index === props.count - 1

  const month = months[startDate.getMonth()]
  const date = startDate.getDate()
  const time = dateFormat(startDate, 'h:MM TT')
  const user = props.admin ? props.users[meeting.relationships.host_user.data.id] : null
  const company = contact?.attributes?.company_name
  let name = 'Site Visitor'
  if (contact?.attributes?.first_name || contact?.attributes?.last_name) {
    name = contact.attributes.first_name + ' ' + contact.attributes.last_name
  } else if (contact?.attributes?.email) {
    name = contact.attributes.email
  }

  return (
    <>
      <div className={classes.meetingRow} style={lastItem ? { paddingBottom: 0 } : props.index === 0 ? { paddingTop: 0 } : null}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', fontSize: '17px', fontStyle: 'poppins', color: '#505050' }}>
          <div className={classes.calendarDiv} style={{ marginRight: '16px' }}>
            <div>
              <div className={classes.month}>
                {month}
              </div>
              <div className={classes.date}>
                {date}
              </div>
            </div>
          </div>
          {user?.deleted ? (
            <Tooltip title='Deleted User'>
              <Typography style={{ textDecoration: 'line-through' }}> {user?.name} </Typography>
            </Tooltip>
          ) : (
            <>
              {user?.name}
            </>
          )}
        </div>
        <div className={classes.rows}>
          {name}
        </div>
        <div className={classes.rows}>
          {company}
        </div>
        <div className={classes.rows}>
          {time}
        </div>
      </div>
      {(!lastItem) && <Divider className={classes.divider} />}
    </>
  )
}

function MeetingsCardContents (props) {
  const meetings = props.meetings
  const contacts = props.contacts
  const admin = props.admin
  const users = props.users

  if (admin && !users) {
    return (
      <LoadingScreen />
    )
  }

  if (meetings && contacts) {
    if (!meetings.length) {
      return (
        <EmptyBox
          message='No upcoming meetings'
        />
      )
    }
    return (
      <div>
        {meetings.map((meeting, index) => (
          <MeetingRow
            key={index}
            meeting={meeting}
            index={index}
            contact={contacts[meeting.relationships.attendee_contact.data.id]}
            count={meetings.length}
            users={users}
            admin={admin}
            calendarEvents={props.calendarEvents}
          />))}
      </div>
    )
  }

  return (
    <LoadingScreen />
  )
}

function MeetingsCard (props) {
  const classes = useStyles()
  const [meetings, setMeetings] = useState(null)
  const [contacts, setContacts] = useState(null)
  const [calendarEvents, setCalendarEvents] = useState(null)
  const [users, setUsers] = useState(null)
  const admin = props.admin

  useEffect(() => {
    getCalendarEvents({ admin })
      .then(response => {
        const upcomingMeetings = response.data.slice(0, 3)
        const contactIDs = upcomingMeetings.map(meeting => meeting.relationships.attendee_contact.data.id)
        setMeetings(upcomingMeetings)
        if (contactIDs.length) {
          contactSearch({ contactIDs })
            .then(response => {
              let contactDict = {}
              response.data.map(
                contact => (contactDict = { ...contactDict, [contact.id]: { ...contact } })
              )
              setContacts(contactDict)
            })
        } else {
          setContacts({})
        }
        getCalendarEventsLog({ admin })
          .then(respons => {
            const events = respons.data.filter(a => upcomingMeetings.some(b => a.id === b.id))
            setCalendarEvents(events)
          })
      })
    if (admin) {
      getUsers(true)
        .then(response => {
          let userDict = {}
          response.data.map(
            user => (userDict = { ...userDict, [user.id]: user.attributes })
          )
          setUsers(userDict)
        })
    }
  }, [admin])

  return (
    <DashboardCard
      title='Upcoming Meetings'
    >
      <div className={classes.cardContents}>
        <MeetingCardHeader />
        <MeetingsCardContents
          meetings={meetings}
          contacts={contacts}
          admin={admin}
          users={users}
          calendarEvents={calendarEvents}
        />
      </div>
    </DashboardCard>
  )
}

export default MeetingsCard
