
import React from 'react'

import SalesforceROI from './SalesforceROI'

export default function SalesforceROIPipelineWon () {
  document.title = 'Closed Won | Signals'
  return (
    <SalesforceROI
      label='Closed Won influenced by Signals'
      series='OpportunityType'
      filterFunction={row => {
        return row.OpportunityIsWon
      }}
    />
  )
}
