import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardCard from './DashboardCard'
import Divider from '@material-ui/core/Divider'
import { Tooltip } from 'library/materialUI'
import { getSegmentOverview } from 'api/segments'
import { AvatarWithInfo, getInitials } from 'cf-components/Avatars'
import Link from 'cf-components/Link'
import BusinessIcon from '@material-ui/icons/Business'
import { ScrollBox } from 'cf-components/ScrollBox'
import { getColor } from 'library/helpers'
// import BounceLoader from 'react-spinners/RiseLoader'
// import theme from 'theme'

const useStyles = makeStyles(theme => ({
  segmentRow: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    paddingBottom: 10,
    paddingTop: 10,
    alignItems: 'center'
  },
  divider: {
    backgroundColor: '#D3D3D3'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    paddingTop: 14,
    paddingBottom: 8,
    alignItems: 'center',
    fontSize: '12px'
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px'
  },
  loadingScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 160,
    width: '100%'
  },
  cardContents: {
    maxHeight: 280,
    overflow: 'auto'
  },
  headerText: {
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080'
  }
}))

function LoadingScreen (props) {
  // const classes = useStyles()

  return (
    <div>
      Loading
    </div>
  )

  // return (
  //   <div className={classes.loadingScreen}>
  //     <BounceLoader
  //       color={theme.palette.primary.main}
  //     />
  //   </div>
  // )
}

function SegmentCardHeader (props) {
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <div style={{ paddingLeft: 64 }} className={classes.headerText}>
        Segment
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.headerText}>
        Companies
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.headerText}>
        Conversations
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.headerText}>
        Meetings
      </div>
    </div>
  )
}

function SegmentRow (props) {
  const classes = useStyles()
  const lastItem = props.index === props.count - 1
  const segment = props.segment.attributes
  const name = segment.name || 'Segment'
  const initials = getInitials(name, 'S')
  const accounts = segment.companies
  const conversations = segment.conversations
  const meetings = segment.meetings

  const color = getColor(props.index)

  return (
    <>
      <div className={classes.segmentRow}>
        <Tooltip title={name}>
          <div style={{ maxWidth: '332px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Link to={segment.name === 'ICP' ? '/account_management/icp' : `/account_segments/${props.segment.id}`} target='_blank'>
              <AvatarWithInfo
                title={name}
                initials={initials}
                avatarColor={color}
                variant='circular'
                size='md'
              />
            </Link>
          </div>
        </Tooltip>
        <div className={classes.stats}>
          {accounts}
        </div>
        <div className={classes.stats}>
          {conversations}
        </div>
        <div className={classes.stats}>
          {meetings}
        </div>
      </div>
      {!lastItem && <Divider className={classes.divider} />}
    </>
  )
}

function SegmentsCardContents (props) {
  const classes = useStyles()
  const segments = props.segments

  if (!segments) {
    return (
      <LoadingScreen />
    )
  }

  const count = segments.length

  return (
    <ScrollBox className={classes.cardContents}>
      {segments.map((segment, index) => (
        <SegmentRow
          segment={segment}
          index={index}
          key={index}
          count={count}
        />
      ))}
    </ScrollBox>
  )
}

function SegmentsCard (props) {
  const [segments, setSegments] = useState(null)

  useEffect(() => {
    getSegmentOverview()
      .then(response => setSegments(response.data))
  }, [])

  const actions = [
    { name: 'View All', action: () => window.open('/#/account_management/segments', '_blank'), icon: BusinessIcon }
  ]

  return (
    <DashboardCard
      title='Company Segments'
      actions={actions}
    >
      <SegmentCardHeader />
      <SegmentsCardContents
        segments={segments}
      />
    </DashboardCard>
  )
}

export default SegmentsCard
