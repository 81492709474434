import UserManagementPage from 'pages/users/UserManagement/UserManagementPage'
import UserDetailsPage from 'pages/users/UserDetailsPage'
import { TeamPage } from 'pages/users/Teams/TeamPage'
import ChannelPage from 'pages/users/Channels/ChannelPage'
import { RouteType } from 'library/navigation/Route'

export const UserRoutes: RouteType[] = [
  { path: '/users/:user_id', component: UserDetailsPage },
  { path: '/user_management', component: UserManagementPage, exact: false },
  { path: '/teams/:team_id', component: TeamPage, exact: false },
  { path: '/channels/:channel_id', component: ChannelPage, exact: false }
]
