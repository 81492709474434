import { RouteType } from 'library/navigation/Route'
import BotPage from 'pages/bot/BotPage'
import EmailWizard from 'pages/bot/email/EmailWizard'
import SequenceEditPage from 'pages/bot/bot-edit/SequenceEditPage'
import BotTestingPage from 'pages/bot_testing/BotTestingPage'
import BotTestEdit from 'pages/bot_testing/BotTestEdit'
import { PlayPage } from 'pages/plays/play/PlayPage'
import EmailSelectionPage from 'pages/bot/email/EmailSelectionPage'
import { EngagementManager } from 'pages/engagement/EngagementManager'
import { SequencePage } from 'pages/plays/play/SequenceEditor'
// import BotManagementPage from 'pages/bot/BotManagement/BotManagement'
import TestReportDashboard from 'pages/bot_testing/test-reports/TestReportDashboard'
import FormPage from 'pages/FormPage'

export const EngagementRoutes: RouteType[] = [
  { path: '/bots/:bot_id', component: BotPage, exact: false },
  { path: '/emails/new', component: EmailSelectionPage },
  { path: '/emails/edit/:email_id', component: EmailWizard },
  { path: '/sequences/:sequence_id', component: SequenceEditPage, exact: false },
  { path: '/plays/:sequenceID', component: SequencePage },
  { path: '/bot_tests', component: BotTestingPage },
  { path: '/bot_tests/:bot_test_id', component: BotTestEdit },
  { path: '/bot_tests/dashboard/:bot_test_id', component: TestReportDashboard },
  { path: '/playmaker', component: PlayPage },
  { path: '/engagement', component: EngagementManager, exact: false },
  { path: '/forms/:form_id', component: FormPage, exact: false }
]
