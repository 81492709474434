import { doDelete, doPatch, doPost } from 'api/api'
import GenericObject from './genericObject'
import { LoadAllProps, SearchParams } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

export const UserSearchParams = (userId: number): SearchParams => {
  return ({
    filters: [
      [{ field: 'created_by_user_id', operator: 'eq', value: userId }],
      [{ field: 'cf_recipients', operator: 'of any', value: userId }]
    ]
  })
}

export const TypeSearchParams = (userId: number, type: string): SearchParams => {
  return ({
    filters: [
      [{ field: 'created_by_user_id', operator: 'eq', value: userId }, { field: 'type', operator: 'eq', value: type }],
      [{ field: 'cf_recipients', operator: 'of any', value: userId }, { field: 'type', operator: 'eq', value: type }]
    ]
  })
}

export const initialState = {
  name: '',
  type: null,
  meta: {},
  frequency: { period: 'daily', option: null },
  frequency_period: 'daily',
  cf_recipients: [],
  non_cf_recipients: [],
  send_empty_state: false
}
export const typeMapping: Record<EmailSubscriptionTypes, string> = {
  contacts: 'Prospect Report',
  segment_summary: 'ABE Segment Summary',
  abe_summary: 'ABE Daily Update',
  chat_summary: 'Daily Chat Report',
  filters: 'Filter View Report',
  admin_daily_summary: 'Admin Daily Summary',
  non_admin_daily_summary: 'Daily Summary',
  score_summary: 'Score Summary',
  client_onboarding: 'Client Onboarding'
}
export const builtinSubscriptionTypes = ['chat_summary', 'abe_summary', 'admin_daily_summary', 'non_admin_daily_summary', 'client_onboarding']
export const blockableEmptyStateTypes = ['abe_summary', 'chat_summary', 'segment_summary', 'filters', 'contacts', 'score_summary', 'admin_daily_summary', 'non_admin_daily_summary']
export type EmailSubscriptionTypes = typeof emailSubType[number]
export const emailSubType = [
  'contacts',
  'segment_summary',
  'chat_summary',
  'abe_summary',
  'filters',
  'admin_daily_summary',
  'non_admin_daily_summary',
  'score_summary',
  'client_onboarding'] as const
export type PeriodTypes = 'daily' | 'weekly' | 'monthly'
export type EmailSubscriptionFrequency = {
  period: PeriodTypes
  option: string
}

export type EmailSubscriptionList = {
  list: EmailSubscription[]
  dict: { [id: EmailSubscription['id']]: EmailSubscription }
}

export type EmailSubscriptionMetaTypes = 'segment_summary' | 'plays' | 'dashboardFilters' | 'report' | 'segment_id' | 'score_shift' | 'potential_accounts' | 'known_accounts'

export class EmailSubscription extends GenericObject {
  name: string
  type: EmailSubscriptionTypes
  meta: Record<EmailSubscriptionMetaTypes, number[] | number>
  frequency: EmailSubscriptionFrequency
  frequencyPeriod: PeriodTypes
  cfRecipients: number[]
  nonCfRecipients: string[]
  sendEmptyState: boolean

  get typeText (): string { return typeMapping[this.type] }
  get displayType (): string { return 'email' }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const emailSubscription = row?.attributes || initialState
    this.name = emailSubscription.name
    this.type = emailSubscription.type
    this.meta = emailSubscription.meta
    this.frequency = emailSubscription.frequency
    this.frequencyPeriod = emailSubscription.frequency.period
    this.cfRecipients = emailSubscription.cf_recipients
    this.nonCfRecipients = emailSubscription.non_cf_recipients
    this.sendEmptyState = emailSubscription.send_empty_state
  }

  static loadAll (props: LoadAllProps): { data: EmailSubscriptionList, isLoading: boolean } {
    // Add code_not_installed filter to searchParams, as well as all the other subscriptions that are not user-generated
    // TODO: Add the rest of the non-user generated subscritions - for Enoc
    if (props?.searchParams?.filters) {
      props.searchParams.filters.push([{ field: 'type', operator: 'ne', value: 'code_not_installed' }])
    } else {
      props.searchParams = { filters: [[{ field: 'type', operator: 'ne', value: 'code_not_installed' }]] }
    }
    return useDoQuery({
      useChatURL: true,
      path: '/email_subscription',
      objectClass: EmailSubscription,
      searchParams: props?.searchParams,
      extraHeaders: props?.searchParams?.extraHeaders
    })
  }

  static loadOne (ID: EmailSubscription['id']): { data: EmailSubscription, isLoading: boolean, isError: boolean } {
    return useDoQuery({ useChatURL: true, path: `/email_subscription/${ID}`, objectClass: EmailSubscription })
  }

  static save = ({ emailSubscription }: { emailSubscription: EmailSubscription }) => {
    const data = {
      attributes: emailSubscription
    }

    if (emailSubscription.id) {
      return doPatch({ path: `/email_subscription/${emailSubscription.id}`, data: data, useChatURL: true })
    } else {
      return doPost({ path: '/email_subscription', data: data, useChatURL: true })
    }
  }

  static unsubscribe (token: string) {
    const data = {
      attributes: { token }
    }
    return doPost({ path: '/email_subscription/unsubscribe', useChatURL: true, data: data })
  }

  static removeSub (id: number): Promise<any> {
    return doDelete({ path: `/email_subscription/${id}`, useChatURL: true })
  }

  delete (): Promise<any> {
    const path = `/email_subscription/${this.id}`
    return doDelete({ path: path, useChatURL: true })
  }
}
