import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

function createROIDaysChart ({ otherDays, chatFunnelsDays, labels, id, setVisible }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.XYChart)
  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
  categoryAxis.dataFields.category = 'source'
  categoryAxis.renderer.grid.template.location = 0

  chart.xAxes.push(new am4charts.ValueAxis())

  const series = chart.series.push(new am4charts.ColumnSeries())
  series.dataFields.valueX = 'days'
  series.dataFields.categoryY = 'source'

  const data = [
    { days: chatFunnelsDays, source: 'Signals' }
  ]

  chart.data = data

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  return chart
}

export { createROIDaysChart }
