import { Icon, Tooltip } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { ScrollBox } from 'cf-components/ScrollBox'
import { SearchParams } from 'classes/queryHelpers'
import useVisibility from 'cf-components/useVisibility'
import Loader from 'library/loading/Loader'
import TableListRow from './TableListRow'
import { Fields } from './CardContents'

const useStyles = makeStyles(theme => ({
  newRowsLoader: {
    height: '40px',
    margin: '30px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    borderBottomWidth: '1px',
    borderTopWidth: '1px',
    borderColor: '#CBCBCB',
    borderBottomStyle: 'solid',
    borderTopStyle: 'solid',
    paddingRight: 20,
    position: 'sticky',
    top: 0,
    backgroundColor: '#f8f8f8',
    zIndex: 2,
    width: 'fit-content',
    minWidth: 'calc(100% - 20px)'
  },
  headerScroll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 400px)'
  },
  headItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    paddingLeft: 20,
    paddingRight: 20
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    paddingLeft: 40,
    paddingRight: 20
  },
  firstHeaderItem: {
    display: 'flex',
    width: 400,
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    cursor: 'pointer',
    paddingTop: 20,
    paddingBottom: 20,
    borderRight: '1px solid #CBCBCB',
    position: 'sticky',
    left: 0,
    backgroundColor: '#f8f8f8',
    zIndex: 3
  }
}))

interface PlumbListProps {
  rows: any[]
  fields: Fields
  actions: any
  totalRows: number
  handleSort: (column: string) => void
  handlePageSize: (pageSize: number) => void
  searchParams: SearchParams
  selected?: string[]
  setSelected?: (value: any) => void
  maxHeight?: string
  disableSnoozeFilter?: boolean
}

export default function PlumbList<Class> ({ rows, fields, actions, totalRows, handleSort, handlePageSize, searchParams, maxHeight = '87vh' }: PlumbListProps) {
  const classes = useStyles()
  const [localRows, setLocalRows] = useState<any[]>([])
  const [localTotal, setLocalTotal] = useState(localRows.length)
  const headField = fields[0]

  useEffect(() => {
      setLocalRows(rows)
      setLocalTotal(totalRows)
  }, [rows, totalRows])

  const expandPage = () => {
    handlePageSize(localRows.length + 20)
  }

  const [visible, visibleRef] = useVisibility<HTMLDivElement>()

  useEffect(() => {
    if (visible && localTotal !== localRows.length) {
      expandPage()
    }
    // eslint-disable-next-line
  }, [visible])

  return (
    // eslint-disable-next-line
    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: maxHeight }}>
      <ScrollBox style={{ overflowX: 'auto' }}>
        <div className={classes.header}>
          <Tooltip title={headField?.tooltip || ''}>
            <div className={classes.firstHeaderItem}>
              <div
                style={{ cursor: headField?.sort === false ? 'default' : 'pointer' }}
                onClick={() => {
                  if (headField?.sort === false) return
                  handleSort(String(headField.name))
                }}
                className={classes.headItem}
              >
                {headField?.label || String(headField.name).toUpperCase()} {searchParams.sortColumn === headField.name && <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px', marginLeft: '5px' }}><Icon icon={searchParams.sortOrder === 'desc' ? 'downArrow' : 'upArrow'} size='sm' /></div>}
              </div>
            </div>
          </Tooltip>
          <div className={classes.headerScroll}>
            {fields.map((field, index) => {
              if (index === 0) return null
              if (field?.noDisplay) return null
              return (
                <Tooltip key={index} title={field?.tooltip || ''}>
                  <div
                    style={{ minWidth: field?.minWidth ? field.minWidth : 100, cursor: field?.sort === false ? 'default' : 'pointer' }}
                    onClick={() => {
                      if (field.sort === false) return
                      handleSort(String(field.name))
                    }}
                    className={classes.headerItem}
                  >
                    {field?.label || String(field.name).toUpperCase()} {searchParams.sortColumn === field.name && <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px', marginLeft: '5px' }}><Icon icon={searchParams.sortOrder === 'desc' ? 'downArrow' : 'upArrow'} size='sm' /></div>}
                  </div>
                </Tooltip>
              )
            })}
          </div>
        </div>
        {localRows?.map((item: Class, index: number) => (
          <TableListRow<Class> key={index} row={item} fields={fields} actions={actions} ignoreSelect />
        ))}
        {localRows.length > 0 && localRows.length < localTotal && (
          <div key='loader-row' className={classes.newRowsLoader} ref={visibleRef}>
            <Loader type='spinner' />
          </div>
        )}
      </ScrollBox>
    </div>
  )
}
