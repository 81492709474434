import React, { useEffect, useRef, useState } from 'react'
import { createROIChart } from './charts/CreateROIChart'
import Loader from 'library/loading/Loader'

function ROIChart (props) {
  const chartRef = useRef(null)
  const [visible, setVisible] = useState('hidden')
  const rows = props.rows
  const series = props.series

  const loaderStyles = {
    inlineSize: 'max-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 650,
    height: 360,
    iconSize: 30
  }

  useEffect(() => {
    const chart = createROIChart({
      rows,
      labels: [],
      id: 'roi_chart_div',
      setVisible,
      seriesGroupName: series
    })
    chartRef.current = chart
  }, [rows, series])

  return (
    <>
      <div
        style={{
          display: visible === 'visible' ? 'none' : 'block',
          ...loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 360, visibility: visible }}>
        <div id='roi_chart_div' style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

export default ROIChart
