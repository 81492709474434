
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import SalesforceDealFlowChart from './SalesforceDealFlowChart'
import { MoneySubheader } from './FormatHelpers'
import SalesforceROI from './SalesforceROI'
import FunnelChart from 'cf-components/FunnelChart'
import { getSalesforceOppStages } from 'api/salesforce'
import { useCallback, useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  card: {
    height: 470,
    border: '1px solid rgba(142,90,226,0.5)',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    position: 'relative'
  },
  cardContent: {
    height: 312,
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterDiv: {
    display: 'flex',
    marginBottom: 15,
    height: 47,
    justifyContent: 'space-between'
  },
  select: {
    width: 320
  },
  customDates: {
    marginLeft: 30,
    marginTop: -15
  },
  cardHeader: {
    paddingBottom: 0
  },
  dashboard: {},
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20,
    padding: '0px 20px 20px 20px'
  },
  graphContent: {
    width: '750px'
  },
  controlButtons: {
    position: 'absolute',
    top: 20,
    right: 15,
    backgroundColor: '#F1EDF7',
    borderRadius: 30,
    display: 'flex',
    cursor: 'pointer',
    fontSize: '14px',
    height: 22,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px'
    }
  },
  selected: {
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 600
  }
}))

function SalesforceROIGraph ({ rows, label, series }) {
  const classes = useStyles()
  const flowType = 'dollar'
  const [stageNames, setStageNames] = useState([])

  const getStages = useCallback(() => {
    getSalesforceOppStages()
    .then((res) => {
      setStageNames(res.data.map(stage => stage.attributes.name))
    })
  }, [])

  useEffect(() => {
    getStages()
  }, [getStages])

  const totalAmount = rows && rows.reduce((p, c) => p + c.OpportunityAmount, 0)
  let data = {}
  const graphRows = []

  if (rows?.length) {
    data = rows.reduce((res, value) => {
      if (!res[value.OpportunityStageName]) {
        res[value.OpportunityStageName] = {
          count: 0,
          percent: '-%',
          type: value.OpportunityStageName
        }
      }
      res[value.OpportunityStageName].count += value.OpportunityAmount
      return res
    }, {})
    stageNames.forEach(stage => {
      const item = data[stage]
      if (item) {
        item.percent = ((item.count / totalAmount) * 100).toFixed(2) + '%'
        graphRows.push(item)
      } else {
        graphRows.push({
          count: 0,
          percent: '-%',
          type: stage
        })
      }
    })
  }

  // graphRows.sort((a, b) => {
  //   return a.count < b.count ? 1 : -1
  // })

  document.title = 'Deal Flow | Signals'

  return (
    <Card className={classes.card}>
      <CardHeader
        title={label}
        className={classes.cardHeader}
      />
      <MoneySubheader totalAmount={flowType !== 'dollar' ? `${totalAmount} Total Deals` : totalAmount} preventFormatting={flowType !== 'dollar'} />
      {/* <div className={classes.controlButtons} onClick={() => handleSetMode()}>
        {flowTypes.map(({ typeKey, display }) => (
          <div key={typeKey} className={flowType === typeKey ? classes.selected : classes.unselected}>
            {display}
          </div>
        ))}
      </div> */}
      <CardContent className={classes.cardContent}>
        <div className={classes.graphContent}>
          {/* {rows && (
            <SalesforceDealFlowChart rows={graphRows} series={series} flowType={flowType} />
          )} */}
          {data &&
            <FunnelChart
              data={graphRows}
              dataType={flowType}
            />}
        </div>
      </CardContent>
    </Card>
  )
}

export default function SalesforceROIDealFlow () {
  return (
    <SalesforceROI
      label='Deal Flow through Signals'
      series='OpportunityStageName'
      filterFunction={row => {
        return (!row.OpportunityIsClosed || row.OpportunityIsWon)
      }}
      graph={SalesforceROIGraph}
    />
  )
}
