import { useState, useRef, useEffect, useCallback } from 'react'
import { Button, Icon, RadioButtons, RadioProps } from 'library/materialUI'
import { Picker as LibPicker } from 'library/Picker'
import Picker from 'cf-components/Pickers'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { URLSelector } from './URLSelector'
import { TextSelector } from './TextSelector'
import { capitalCase } from 'change-case'
import GeographySelector from 'cf-components/GeographySelector'
import SliderSelector from './SliderSelector'
import { makeStyles } from '@material-ui/core/styles'
import RevenueSlider from './RevenueSlider'
import BackendPicker from 'library/picker/backendPicker'
import ComparatorSelector from './ComparatorSelector'
import { Select as AtlSelect, MenuItem } from '@material-ui/core'

export const timeOptions = [
  { value: 'ever', label: 'ever' },
  { value: 7, label: 'within last 7 days' },
  { value: 30, label: 'within last 30 days' },
  { value: 90, label: 'within last 90 days' },
  { value: 180, label: 'within last 6 months' }
]

export const accountTypeOptions = [
  { value: 'prospect', label: 'Prospect' },
  { value: 'customer', label: 'Customer' },
  { value: 'customer_canceled', label: 'Customer-Canceled' },
  { value: 'competitor', label: 'Competitor' },
  { value: 'analyst', label: 'Analyst' },
  { value: 'integrator', label: 'Integrator' }
]

const isGrouping = [
  'accountType', 'oppType', 'oppStage', 'industry'
]

const boolGrouping = [
  'oppWon', 'openOpp', 'boolean'
]

const justIsGrouping = [
  'accountOwner'
]

const rangeGrouping = [
  'annualRevenue', 'numberOfEmployees'
]
const useStyles = makeStyles(theme => ({
  editHighIntentButton: {
    marginTop: 5,
    color: 'black',
    border: '1px solid #C4C4C4',
    borderRadius: '100px',
    height: '21px'
  },
  editHighIntentText: {
    font: 'normal 10px/20px Poppins',
    color: 'black'
  },
  addButton: {
    display: 'flex',
    width: '35px',
    height: '35px',
    marginLeft: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  selector: {
    width: '100%'
  }
}))

const getButtons = (type: string): { buttons: RadioProps[], cmp: string } => {
  let buttons: RadioProps[]
  let cmp = 'in'

  if (type === 'numeric') {
    buttons = [
      { value: 'gt', label: 'is greater than' },
      { value: 'lt', label: 'is less than' },
      { value: 'eq', label: 'equals' },
      { value: 'neq', label: 'not equal to' }
    ]
    cmp = 'gt'
  } else if (type === 'picker') {
    buttons = []
    cmp = 'eq'
  } else if (type === 'picklist') {
    buttons = []
    cmp = 'eq'
    buttons = [
      { value: 'eq', label: 'is' },
      { value: 'neq', label: 'is not' }
    ]
  } else if (type === 'time') {
    buttons = []
    cmp = 'within'
  } else if (type === 'url') {
    buttons = [
      { value: 'contains', label: 'contains' },
      { value: '!contains', label: 'does not contain' },
      { value: 'is', label: 'exact match' }
    ]
    cmp = 'contains'
  } else if (type === 'knownUnknown') {
    cmp = 'known'
    buttons = [
      { value: 'known', label: 'is known' },
      { value: 'unknown', label: 'is not known' }
    ]
  } else if (type === 'geography') {
    cmp = 'in'
    buttons = []
  } else if (type === 'location') {
    cmp = 'in'
    buttons = [
      { value: 'in', label: 'is' },
      { value: 'not_in', label: 'is not' }
    ]
  } else if (isGrouping.includes(type)) {
    cmp = 'in'
    buttons = [
      { value: 'in', label: 'is' },
      { value: 'not_in', label: 'is not' }
    ]
  } else if (justIsGrouping.includes(type)) {
    cmp = 'in'
    buttons = [
      { value: 'in', label: 'is' }
    ]
  } else if (rangeGrouping.includes(type)) {
    cmp = 'between'
    buttons = [
      { value: 'between', label: 'is' }
    ]
  } else if (boolGrouping.includes(type)) {
    cmp = 'eq'
    buttons = [
      { value: 'eq', label: 'is true' },
      { value: 'ne', label: 'is false' }
    ]
  } else if (type === 'technology') {
    cmp = 'has_one'
    buttons = [
      { value: 'has_one', label: 'includes one' },
      { value: 'has_all', label: 'includes all' },
      { value: 'not_has', label: 'does not include' }
    ]
  } else if (type === 'tld') {
    cmp = 'in'
    buttons = [
      { value: 'in', label: 'is one of' },
      { value: 'not_in', label: 'is not any of' }
    ]
  } else if (type === 'signalsScore') {
    cmp = 'gt'
    buttons = [
      { value: 'gt', label: 'greater than' },
      { value: 'lt', label: 'less than' },
      { value: 'between', label: 'between' }
    ]
  } else {
    buttons = [
      { value: 'gt', label: 'is greater than' }
    ]
  }
  return { buttons, cmp }
}

export type SelectorProps = {
  state: Record<string, any>
  setState: (value: any) => void
  option: Record<string, any>
  handleComplete: (updatedState: any) => void
  submissionRef: any
  pickList?: any
}

function Selector (props: SelectorProps) {
  const classes = useStyles()
  const { state, setState, option } = props
  if (option.type === 'numeric') {
    return (<TextSelector {...props} />)
  } else if (option.type === 'picklist') {
    return (
      <AtlSelect
        variant='outlined'
        margin='dense'
        className={classes.selector}
        value={state.value}
        onChange={(e) => setState({ ...state, value: e.target.value })}
      >
        {props.pickList.map((option: string) => (
          <MenuItem value={option} key={option}>{option}</MenuItem>
        ))}
      </AtlSelect>
    )
  } else if (option.type === 'picker') {
    if (option.multiple) {
      return (
        <>
          <LibPicker
            selection={state.value}
            setSelection={(value: any) => {
              const label = value.length.toString() + ' ' + option.objectType
              setState({ ...state, value: value, label })
            }}
            multiple={option.multiple}
            objectType={option.objectType}
            label={capitalCase(option.objectType)}
          />
          {option.objectType === 'highIntentCategories'
            ? (
              <Button className={classes.editHighIntentButton} onClick={() => window.open('/#/settings/high_intent_pages')}>
                <p className={classes.editHighIntentText}>Edit High-Intent Pages</p>
              </Button>)
            : <></>}
        </>
      )
    }
    return (
      <LibPicker
        selection={state.value}
        setSelection={(value: any) => {
          const label = value ? value.name : ''
          setState({ ...state, value: value.id, label })
        }}
        objectType={option.objectType}
        label={capitalCase(option.objectType)}
      />
    )
  } else if (option.type === 'time') {
    return (
      <Select
        options={timeOptions}
        value={state.value}
        onChange={(value: string) => setState({ ...state, value })}
        placeholder='Select an option below'
      />
    )
  } else if (option.type === 'url') {
    return (
      <URLSelector {...props} />
    )
  } else if (option.type === 'knownUnknown') {
    return (<></>)
  } else if (option.type === 'geography') {
    return (
      <GeographySelector
        values={state.value}
        setValues={(value) => {
          let label = ''
          if (value.length > 1) {
            label = value[0] + ' and more'
          } else if (value.length) {
            label = value[0]
          }
          // const label = value.length ? value[0] : ''
          setState({ ...state, value, label })
        }}
        fullWidth
      />
    )
  } else if (option.type === 'location') {
    return (
      <GeographySelector
        values={state.value}
        setValues={(value) => {
          setState({ ...state, value, label: 'location' })
        }}
        fullWidth
      />
    )
  } else if (option.type === 'accountType') {
    return (
      <Picker
        objectType='accountTypes'
        selection={state.value}
        setSelection={(value: any) => setState({ ...state, value })}
        multiple
      />
    )
  } else if (option.type === 'annualRevenue') {
    return (
      <RevenueSlider
        setSelection={(value: [number, number]) => setState({ ...state, value })}
        currentSelection={state.value}
      />
    )
  } else if (option.type === 'numberOfEmployees') {
    return (
      <SliderSelector
        setSelection={(value: [number, number]) => setState({ ...state, value })}
        currentSelection={state.value}
      />
    )
  } else if (option.type === 'industry') {
    return (
      <BackendPicker
        objectType='industries'
        selection={state.value}
        setSelection={(value: any) => setState({ ...state, value })}
        isSelect
      />
    )
  } else if (option.type === 'openOpp' || option.type === 'oppWon' || option.type === 'boolean') {
    return (
      <></>
    )
  } else if (option.type === 'oppStage') {
    return (
      <Picker
        objectType='opportunityStages'
        selection={state.value}
        setSelection={(value: any) => setState({ ...state, value })}
        multiple
      />
    )
  } else if (option.type === 'accountOwner') {
    return (
      <Picker
        objectType='users'
        selection={state.value}
        setSelection={(value: any) => setState({ ...state, value })}
        multiple
      />
    )
  } else if (option.type === 'oppType') {
    return (
      <Picker
        objectType='opportunityTypes'
        selection={state.value}
        setSelection={(value: any) => setState({ ...state, value })}
        multiple
      />
    )
  } else if (option.type === 'technology') {
    return (
      <BackendPicker
        objectType='technologies'
        selection={state.value}
        setSelection={(value: any) => setState({ ...state, value })}
        placeholder='Search Technologies'
      />
    )
  } else if (option.type === 'tld') {
    return (
      <BackendPicker
        objectType='tld'
        selection={state.value}
        setSelection={(value: any) => setState({ ...state, value })}
        placeholder='Search Domains'
      />
    )
  } else if (option.type === 'signalsScore') {
    return (
      <ComparatorSelector
        setValue={(value: [number] | [number, number]) => setState({ ...state, value })}
        currentValue={state.value}
        cmp={state.cmp}
        max={100}
        min={0}
      />
    )
  } else {
    return (
      <div>Selector for {option.type}</div>
    )
  }
}

const getStartingValue = (type: string) => {
  switch (type) {
    case 'url':
      return ['']
    case 'intentPages':
      return []
    case 'time':
      return null
    case 'numeric':
      return 1
    case 'geography':
    case 'location':
    case 'accountType':
    case 'oppStage':
      return []
    case 'openOpp':
      return 'eq'
    case 'picklist':
      return 'eq'
  }
}

const isButtonDisabled = (state: any) => {
  if (Array.isArray(state.value)) {
    const options = state.value.join('')
    return !(options)
  } else if (state.value === 0) {
    return false
  } else {
    return !(state.value)
  }
}

interface ValueSelctorProps {
  filter: any
  editFilter: any
  option: Record<any, any>
  handleClose: () => void
  setCreateModalOpen?: (value: boolean) => void
  pickList?: string[]
}

export function ValueSelector (props: ValueSelctorProps) {
  const classes = useStyles()
  const { filter, editFilter } = props
  const { buttons, cmp } = getButtons(props.option.type)
  const submissionRef = useRef<any>(null)
  const startingCmp = filter.cmp || cmp
  const startingValue = filter.value || getStartingValue(filter.prop)
  const [state, setState] = useState({ cmp: startingCmp, value: startingValue, label: props.option.label })
  const disabled = props.option.type !== 'knownUnknown' && isButtonDisabled(state)
  const setCreateModalOpen = props.setCreateModalOpen

  const handleChange = (value: string) => {
    setState({ ...state, cmp: value });
  };
  const handleComplete = (updatedState: any) => {
    const editState = updatedState || state
    if (!disabled) {
      editFilter(editState.cmp, editState.value, filter.prop)
    }
    return disabled
  }

  const handleDoneClick = () => {
    if (submissionRef.current) {
      submissionRef.current()
      props.handleClose()
    } else {
      const disabled = handleComplete(state)
      if (!disabled) {
        props.handleClose()
      }
    }
  }

  const handleSave = useCallback(() => {
    if (submissionRef.current) {
      submissionRef.current()
    } else {
      handleComplete(state)
    }
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    handleSave()
  }, [state, state.value, handleSave]);

  // this is done because openOpp, oppWon requires only radioBtns
  // there is a need to change value to perform query correctly
  // can be changed later, if/when we refactor how we do the where clause for segment triggers
  useEffect(() => {
    const isOpenOppOrOppWon = state.label === 'openOpp' || state.label === 'oppWon' || props.option.type === 'boolean'
    if (isOpenOppOrOppWon) {
      setState({ ...state, value: 1 })
    }
    if (props.option.type === 'boolean') {
      setState({ ...state, value: 'true' })
    }
    // eslint-disable-next-line
  }, [state.cmp, state.label])

  const padding = props.option.type !== 'knownUnknown' && buttons.length ? 10 : 0

  const width = props.option.type === 'geography' ? 400 : 300

  return (
    <div style={props.option.type === 'annualRevenue' ? { minWidth: 300 } : { width }}>
      <div style={{ padding: '15px 20px', borderBottom: '1px solid #999' }}>
        {Boolean(buttons.length) && (
          <RadioButtons
            buttons={buttons}
            handleChange={handleChange}
            value={state.cmp}
          />
        )}
        <>
          {setCreateModalOpen ? (
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: padding }}>
              <div style={{ width: '275px' }}>
                <Selector
                  option={props.option}
                  state={state}
                  setState={setState}
                  handleComplete={handleComplete}
                  submissionRef={submissionRef}
                  pickList={props.pickList}
                />
              </div>
              <div onClick={() => setCreateModalOpen(true)} className={classes.addButton}>
                <Icon icon='addBox' />
              </div>
            </div>
          ) : (
            <Selector
              option={props.option}
              state={state}
              setState={setState}
              handleComplete={handleComplete}
              submissionRef={submissionRef}
              pickList={props.pickList}
            />
          )}
        </>
      </div>
      <div>
        <Button
          onClick={handleDoneClick}
          color='primary'
          variant='text'
          fullWidth
          disabled={disabled}
        >
          Done
        </Button>
      </div>
    </div>
  )
}
