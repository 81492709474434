import makeStyles from '@material-ui/styles/makeStyles'
import { CloseButton } from '../buttons/CloseButton'
import { Button, Typography } from '../materialUI'
import { Icon, IconType } from '../materialUI/Icon'

const useStyles = makeStyles(theme => ({
  titleDiv: {
    display: 'grid',
    gridTemplateColumns: '30px 1fr'
  },
  containerDiv: {
    width: '95%',
    margin: 'auto',
    marginTop: '10px',
    backgroundColor: '#E760331A',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5
  }
}))

interface BannerProps {
  icon: IconType
  title: string
  subtitle: string
  setUpLink: string
  handleClose: () => void
}

export default function Banner ({ icon, title, subtitle, setUpLink, handleClose }: BannerProps) {
  const classes = useStyles()
  const iconComponent = <Icon icon={icon} />
  return (
    <div className={classes.containerDiv}>
      <div style={{ width: '75%' }}>
        <div className={classes.titleDiv}>
          <span>
            {iconComponent}
          </span>
          <span style={{ whiteSpace: 'nowrap' }}>
            {title}
          </span>
        </div>
        <div className={classes.titleDiv}>
          <span />
          <div>
            <Typography variant='subtitle1'>{subtitle}</Typography>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant='text'
              onClick={() => window.open(setUpLink)}
              style={{ marginRight: 20 }}
              color='default'
            >
              SET IT UP
            </Button>
            <CloseButton
              handleClose={() => handleClose()}
              variant='text'
              iconColor='black'
            />
          </span>
        </div>
      </div>
    </div>
  )
}
