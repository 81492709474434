import Dialog from '@material-ui/core/Dialog';

type CancellationModalProps = {
  open: boolean
  onClose: () => void
}
export function CancellationModal (props: CancellationModalProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div>
        Stuff
      </div>
    </Dialog>
  )
}
