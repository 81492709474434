import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from 'cf-components/Avatars'
import EmptyState from 'components/EmptyState'
import ChatIcon from '@material-ui/icons/ChatOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import { Fields } from 'cf-components/table/CardContents'
import PlumbList from 'cf-components/table/PlumbList'
import PersonIcon from '@material-ui/icons/Person';
import { DisplayLink } from 'library/table/tableHelpers'
import { Icon } from 'library/materialUI'
import BusinessIcon from '@material-ui/icons/Business'
import Persona from 'classes/personas'
import { truncateString } from 'library/helpers'
import { useContext } from 'react'
import { components, SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  url: {
    whiteSpace: 'nowrap',
    color: '#4C4C4C',
    textDecoration: 'none',
    fontSize: '14px',
    marginTop: 2
  },
  liveViewCard: {
    padding: '4px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    height: 61,
    alignItems: 'center'
  },
  visitorColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'poppins',
    marginLeft: 10
  }
}))

function VisitorDisplay ({ row }: any): JSX.Element {
  const classes = useStyles()
  const { data: persona, isLoading } = Persona.loadOne(row.person?.attributes?.persona_id || 0)
  let initials: any = <PersonIcon />
  const visitorName = row.name || 'Site Visitor'
  if (visitorName) {
    initials = visitorName.charAt(0) + (visitorName.split(' ')[1] ? `${visitorName.split(' ')[1].charAt(0)}` : '')
  }
  const titleName = row.person?.attributes?.title || 'Title Unknown'
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DisplayLink field={row.personID} link={`/contacts/${row.personID}`}>
        <Avatar variant='rounded' participantID={row.id} contact={row.person} initials={initials} size='lg' />
      </DisplayLink>
      <div className={classes.visitorColumn}>
        <DisplayLink field={row.personID} link={`/contacts/${row.personID}`}>
          <div style={{ display: 'flex' }}>
            <div style={{ fontWeight: 600 }}>{visitorName}</div>
            {!isLoading && persona ? (
              <Tooltip title={`This person is a member of your buying committee in your ${persona.name} persona.`}>
                <div style={{ marginLeft: '5px' }}>
                  <Icon icon='buyingCommittee' specificSize={24} />
                </div>
              </Tooltip>
            ) : null}
          </div>
        </DisplayLink>
        <div style={{ color: '#808080', fontSize: '14px' }}>{titleName}</div>
      </div>
    </div>
  )
}

function CompanyDisplay ({ row }: any): JSX.Element {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <BusinessIcon htmlColor='#808080' />
      <DisplayLink field={row.domain} link={`/accounts/${row.domain}`}>
        <div style={{ marginLeft: 10 }}>{row.company || row.sixSenseName || 'Unknown'}</div>
      </DisplayLink>
      {row.isICP &&
        <Tooltip title='This company matches your ICP'>
          <div style={{ marginLeft: 5 }}>
            <Icon icon='icp' />
          </div>
        </Tooltip>}
      {row.opportunityStatus === 'Open Opp' &&
        <Tooltip title='This account has an open opportunity'>
          <div style={{ marginLeft: 5 }}>
            <Icon icon='openOpp' specificSize={24} />
          </div>
        </Tooltip>}
    </div>
  )
}

function CurrentPageDisplay ({ row }: any): JSX.Element {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <a style={{ cursor: 'pointer', color: '#000000', marginRight: 5 }} href={row.currentUrl} target='_blank' rel='noreferrer'>
        {truncateString(row.currentPage, 15, true)}
      </a>
      <div style={{ display: 'flex', color: '#808080' }}>{truncateString(row.currentUrl, 20, true)}</div>
    </div>
  )
}

function handleSorting (rows: any[], sortOptions: any) {
  const sortValue: string = sortOptions.sortCriteria
  if (sortValue === 'eventType') {
    return
  }
  if (sortOptions.sortAscending) {
    return rows.sort((a, b) => {
      if (a[sortValue] === '' || a[sortValue] === null) return 1
      if (b[sortValue] === '' || b[sortValue] === null) return -1
      return a[sortValue] > b[sortValue] ? 1 : -1
    })
  } else {
    return rows.sort((a, b) => {
      if (a[sortValue] === '' || a[sortValue] === null) return 1
      if (b[sortValue] === '' || b[sortValue] === null) return -1
      return a[sortValue] < b[sortValue] ? 1 : -1
    })
  }
}

function LiveViewTable (props: any) {
  const { isOn } = useContext(SessionContext)
  const handleParticipantSelect = props.handleParticipantSelect
  const rows = props.rows
  const sortOptions = props.sortOptions
  const sortedRows = handleSorting(rows, sortOptions)
  const searchParams = { sortColumn: sortOptions.sortCriteria, sortOrder: sortOptions.sortAscending ? 'asc' : 'desc' }

  if (rows.length === 0) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <EmptyState
          message='There are currently no visitors on your site'
          sad
        />
      </div>
    )
  }

  const fields: Fields<any> = [
    { name: 'name', type: 'custom', component: VisitorDisplay, label: 'Visitor' },
    { name: 'company', type: 'custom', component: CompanyDisplay, label: 'Company', emptyState: 'Unknown', minWidth: 300 },
    { name: 'signalsScore', type: 'signalScore', label: 'Signals Score' },
    { name: 'currentPage', type: 'custom', component: CurrentPageDisplay, label: 'Current Page', minWidth: 300 },
    { name: 'location', type: 'text', label: 'Location', minWidth: 200 }
  ]

  const actions: Array<any> = []
  if (isOn(components.CHAT)) {
    actions.push({ name: 'Start Conversation', action: (row: any) => { handleParticipantSelect(row) }, icon: ChatIcon })
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <PlumbList
        rows={sortedRows || []}
        fields={fields}
        actions={actions}
        totalRows={rows.length}
        handleSort={(column: string) => {
          if (column !== sortOptions.sortCriteria) {
            sortOptions.setSortCriteria(column)
            sortOptions.setSortAscending(true)
          } else {
            sortOptions.setSortAscending(!sortOptions.sortAscending)
          }
        }}
        handlePageSize={() => undefined}
        searchParams={searchParams}
        maxHeight='100%'
      />
    </div>
  )
}

export default LiveViewTable
