import React from 'react'
import { Chip } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ButtonTextField from 'components/ButtonTextField'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'
import SettingsCard from 'cf-components/SettingsCard'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'

const useStyles = makeStyles(theme => ({
  keywords: {
    display: 'flex',
    marginTop: 8,
    flexFlow: 'wrap'
  },
  smallChip: {
    margin: 5
  },
  deleteIcon: {
    fill: 'white'
  },
  warningBanner: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    marginRight: 15,
    marginLeft: 15,
    borderRadius: 20,
    backgroundColor: 'rgba(255, 188, 3)'
  }
}))

function InstallDomains ({ state, dispatch, contentSubdomainDisabled }) {
  const classes = useStyles()
  const location = useLocation()
  const showCPS = location.search.includes('content-page-settings=1')

  if (!state) {
    return <></>
  }

  const domains = state.attributes.domains
  const excludedDomains = state.attributes.excluded_domains

  const deleteDomain = domain => () => {
    dispatch({ type: 'updateSetting', name: 'domains', value: domains.filter(i => i !== domain) })
    dispatch({ type: 'updateSetting', name: 'excluded_domains', value: excludedDomains.filter(i => i !== domain) })
  }

  const deleteExcludedDomain = excludedDomain => () => {
    dispatch({ type: 'updateSetting', name: 'excluded_domains', value: excludedDomains.filter(i => i !== excludedDomain) })
  }

  if (domains == null) {
    return (
      <></>
    )
  }

  return (
    <div>
      <SettingsCard
        title='Signals website domain approval list'
      >
        <CardContent>
          <Typography>
            Signals can work on any domain or subdomain where you can install it. To ensure that your bots and live-chat only operate on the domains you own, you can list the approved domains here.
            For example, if your website lives at https://www.example.com/index.html, you would add www.example.com to the list.
          </Typography>
          <div className={classes.keywords}>
            {
              domains.map((option, index) => {
                return (
                  <Chip
                    key={index}
                    label={option}
                    onDelete={deleteDomain(option)}
                    color='primary'
                    className={classes.smallChip}
                    classes={{ deleteIcon: classes.deleteIcon }}
                  />
                )
              })
            }
          </div>
          <ButtonTextField
            placeholder='Type new domain and hit enter...' addButton={domain => {
              dispatch({ type: 'updateSetting', name: 'domains', value: [...domains, domain] })
              dispatch({ type: 'updateSetting', name: 'excluded_domains', value: [...excludedDomains, domain] })
            }}
          />
        </CardContent>
      </SettingsCard>
      <br />
      <SettingsCard
        title='Blocked Domains'
      >
        <CardContent>
          <Typography>
            Enter domains that you wish to block from showing up in your dashboard and reporting. This could be your own organization’s
            domain or any others you wish to exclude. Example: getsignals.ai
          </Typography>
          <div className={classes.keywords}>
            {
              excludedDomains.map((option, index) => {
                return (
                  <Chip
                    key={index}
                    label={option}
                    onDelete={deleteExcludedDomain(option)}
                    color='primary'
                    className={classes.smallChip}
                    classes={{ deleteIcon: classes.deleteIcon }}
                  />
                )
              })
            }
          </div>
          <ButtonTextField
            placeholder='Type a domain and hit enter...' addButton={domain => {
              dispatch({ type: 'updateSetting', name: 'excluded_domains', value: [...excludedDomains, domain] })
            }}
          />
        </CardContent>
      </SettingsCard>
      <AccessControl requiredComponent={components.CONTENT_PAGES}>
        <SettingsCard
          title='Content Pages Settings'
          expanded={showCPS}
        >
          {contentSubdomainDisabled === false
            ? (
              <div className={classes.warningBanner}>
                <ErrorOutlineRoundedIcon style={{ marginRight: 5 }} />
                <div>
                  Warning: Once you set a subdomain, you will <i>NOT</i> be able to change it again.
                </div>
              </div>)
            : null}
          <CardContent
            classes={{ root: classes.cardRoot }}
          >
            <Typography>
              Signals Content Pages lets Signals' chat system serve PDF whitepapers, brochures,
              and other content direct to your customers. Here, you can choose a subdomain you would
              like to use to display this content. This affects the URL you share to customers.

              The subdomain value must not have any spaces or special characters.
            </Typography>
            <br />
            <TextField
              label='Content Subdomain'
              variant='outlined'
              margin='dense'
              disabled={contentSubdomainDisabled}
              value={state.attributes.content_subdomain}
              onChange={e => {
                let val = e.target.value
                if (val === '') {
                  val = null
                }
                dispatch({ type: 'updateSetting', name: 'content_subdomain', value: val })
              }}
            />
          </CardContent>
        </SettingsCard>
      </AccessControl>
    </div>
  )
}

export default InstallDomains
