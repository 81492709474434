import { getPlanLimits } from 'api/billing'
import { getFeatureFlags } from 'api/feature_flags'
import { getIntegrationFeature } from 'api/integrations'
import { Emitter, EVENT_TYPE } from 'emitter'
import { useEffect, useState, createContext } from 'react'
import { useQuery } from 'react-query'

interface ShareProviderInterface {
  crmIntegration: string,
  flags: Record<string, boolean>,
  isOn: (component: string) => boolean,
  canAdd: (component: string) => number
}

const ShareContext = createContext<ShareProviderInterface>({
  crmIntegration: '',
  flags: {},
  isOn: () => false,
  canAdd: () => 0
})

function ShareProvider ({ children }: { children?: JSX.Element | JSX.Element[] }) {
  const [crmIntegration, setCRMIntegration] = useState('')
  const [loadingCRM, setLoadingCRM] = useState(false)
  const { data: flags } = useQuery('featureFlags', async () => {
    return await getFeatureFlags()
  })

  useEffect(() => {
    if (!crmIntegration && !loadingCRM) {
      setLoadingCRM(true)
      getIntegrationFeature('crm.abe')
        .then(response => {
          // Default to none for users that don't have a crm integration
          setCRMIntegration(response?.data?.attributes?.integration?.name || 'none')
          setLoadingCRM(false)
        })
        .catch(_ => {
          setCRMIntegration('none')
          setLoadingCRM(false)
        })
    }
  }, [crmIntegration, loadingCRM])

  Emitter.on(EVENT_TYPE.DATA_CHANGED, () => {
    refetchComponents()
  })

  const { data: planComponents, isLoading, isError, refetch: refetchComponents } = useQuery('componentStates', async () => {
    const response = await getPlanLimits()
    const componentsData: any = {}
    for (const component of response.data) {
      componentsData[component.attributes.name] = component.attributes
    }
    return componentsData
  })

  function isOn (component: string) {
    return !isLoading && !isError && planComponents[component] && planComponents[component].on
  }

  function canAdd (component: string) {
    if (isLoading || isError || !planComponents[component]) {
      return 0
    }
    return Math.max(planComponents[component].max - planComponents[component].qty, 0)
  }

  const providerValues: ShareProviderInterface = {
    crmIntegration,
    flags: flags || {},
    isOn,
    canAdd
  }

  return (
    <ShareContext.Provider value={providerValues}>
      {children}
    </ShareContext.Provider>
  )
}

export { ShareContext, ShareProvider }
