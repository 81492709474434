import React, { useState, useEffect, useCallback } from 'react'
import { getRawROIReport } from 'api/reports'
import { FilterBar } from 'cf-components/FilterBar/FilterBar'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDebounce } from 'use-debounce'
import { downloadCSV } from 'components/downloadCSV'
import dateFormat from 'dateformat'
import SalesforceROIOppList from './SalesforceROIOppList'
import ROIChart from './SalesforceROIChart'
import AppPage from 'cf-components/AppPage'
import { subDays, parseISO } from 'date-fns'
import { MoneySubheader } from './FormatHelpers'

const useStyles = makeStyles(theme => ({
  card: {
    height: 470,
    border: '1px solid rgba(142,90,226,0.5)',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20
  },
  cardContent: {
    height: 312,
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterDiv: {
    display: 'flex',
    marginBottom: 15,
    height: 47,
    justifyContent: 'space-between'
  },
  select: {
    width: 320
  },
  customDates: {
    marginLeft: 30,
    marginTop: -15
  },
  cardHeader: {
    paddingBottom: 0
  },
  dashboard: {},
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20,
    padding: '0px 20px 20px 20px'
  },
  graphContent: {
    width: '100%'
  }
}))

const sortValues = [
  { label: 'Opportunity Name', id: 'OpportunityName' },
  { label: 'Account', id: 'AccountName' },
  { label: 'Type', id: 'OpportunityType' },
  { label: 'Amount', id: 'OpportunityAmount' },
  { label: 'Created Date', id: 'OpportunityCreatedDate' },
  { label: 'Close Date', id: 'OpportunityCloseDate' },
  { label: 'Stage', id: 'OpportunityStageName' }
]

export const startingDateRange2 = {
  startDate: subDays(new Date(), 365),
  endDate: subDays(new Date(), 1),
  key: 'selection'
}

const SalesforceROIReport = props => {
  const filterFunction = props.filterFunction
  const label = props.label || 'Pipeline influenced by Signals'
  const [search, setSearch] = useState('')
  const [dateRange, setDateRange] = useState(startingDateRange2)
  const [reportResponse, setReportResponse] = useState(null)
  const [debouncedSearch] = useDebounce(search, 500)
  const [sortCriteria, setSortCriteria] = useState('OpportunityCreatedDate')
  const [sortAscending, setSortAscending] = useState(false)
  const series = props.series
  const Graph = props.graph

  const loadReport = useCallback(() => {
    getRawROIReport()
      .then(response => {
        const flat = response.data.map(i => {
          const data = i.attributes
          data.id = i.id
          return data
        })
        setReportResponse(flat)
      })
  }, [])

  // TODO: make this into a memo maybe?
  let filteredReportResponse = null
  if (filterFunction && reportResponse) {
    filteredReportResponse = reportResponse.filter(filterFunction)
  } else {
    filteredReportResponse = reportResponse
  }

  if (filteredReportResponse?.length && dateRange?.startDate && dateRange?.endDate) {
    filteredReportResponse = filteredReportResponse.filter((row) => {
      const oppDate = parseISO(row.OpportunityCreatedDate)
      if (oppDate < dateRange.startDate) {
        return false
      } else if (oppDate > dateRange.endDate) {
        return false
      }
      return true
    })
  }

  if (debouncedSearch && filteredReportResponse?.length) {
    const lowerDebounced = debouncedSearch.toLowerCase()
    filteredReportResponse = filteredReportResponse.filter((row) => {
      if (row.OpportunityName.toLowerCase().includes(lowerDebounced)) {
        return true
      } else if (row.AccountName.toLowerCase().includes(lowerDebounced)) {
        return true
      } else if (('' + row.OpportunityAmount).toLowerCase().includes(lowerDebounced)) {
        return true
      } else if (row.OpportunityStageName.toLowerCase().includes(lowerDebounced)) {
        return true
      } else if (row.OpportunityType.toLowerCase().includes(lowerDebounced)) {
        return true
      } else if (row.OpportunityCreatedDate.toLowerCase().includes(lowerDebounced)) {
        return true
      } else if (row.OpportunityCloseDate.toLowerCase().includes(lowerDebounced)) {
        return true
      } else {
        return false
      }
    })
  }

  useEffect(() => {
    loadReport()
  }, [loadReport])

  const rows = filteredReportResponse

  const sortOptions = {
    sortValues: sortValues,
    sortCriteria: sortCriteria,
    setSortCriteria: setSortCriteria,
    sortAscending: sortAscending,
    setSortAscending: setSortAscending
  }

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange
  }

  function exportReport () {
    const date = new Date().toString()
    const filename = label + dateFormat(date, 'isoDate') + '.csv'
    const headers = [
      'Opportunity',
      'Account',
      'AccountId',
      'Type',
      'Amount',
      'Stage',
      'Created',
      'Closed'
    ]

    let csvContent = headers.join(',') + '\n'
    csvContent += filteredReportResponse.map(row =>
      [
        row.OpportunityName,
        row.AccountName,
        row.AccountId,
        row.OpportunityType,
        row.OpportunityAmount,
        row.OpportunityStageName,
        row.OpportunityCreatedDate,
        row.OpportunityCloseDate
      ]).map(row => row.join(',')).join('\n')
    downloadCSV(filename, csvContent)
  }

  return (
    <AppPage
      title='ROI Pipeline'
      padding={0}
    >
      <div>
        <FilterBar
          search={search}
          setSearch={setSearch}
          sortOptions={sortOptions}
          filterOptions={filterOptions}
          handleExport={exportReport}
        />
        {Graph ? (
          <Graph rows={rows} label={label} series={series} />
        ) : (
          <SalesforceROIGraph rows={rows} label={label} series={series} />
        )}

        <SalesforceROIOppList
          rows={rows}
          sortOptions={sortOptions}
        />
      </div>
    </AppPage>
  )
}

function SalesforceROIGraph ({ rows, label, series }) {
  const classes = useStyles()
  const totalAmount = rows && rows.reduce((p, c) => p + c.OpportunityAmount, 0)

  rows && rows.forEach(row => {
    if (!row.OpportunityType) {
      row.OpportunityType = 'Not Specified'
    }
  })

  return (
    <Card className={classes.card}>
      <CardHeader
        title={label}
        className={classes.cardHeader}
      />
      <MoneySubheader totalAmount={totalAmount} />
      <CardContent className={classes.cardContent}>
        <div className={classes.graphContent}>
          {rows && (
            <ROIChart rows={rows} series={series} />
          )}

        </div>
      </CardContent>
    </Card>
  )
}

export default SalesforceROIReport
