import React from 'react'
import SalesforceROI from './SalesforceROI'

export default function SalesforceROIPipelineInfluenced () {
  document.title = 'Pipeline Influenced | Signals'
  return (
    <SalesforceROI
      label='Pipeline influenced by Signals'
      series='OpportunityType'
      filterFunction={row => {
        return row.Influenced
      }}
    />
  )
}
