import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

function createROIChart ({ otherAmount, chatFunnelsAmount, labels, id, setVisible }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.PieChart)

  const pieSeries = chart.series.push(new am4charts.PieSeries())
  pieSeries.dataFields.value = 'amount'
  pieSeries.dataFields.category = 'source'
  pieSeries.slices.template.stroke = am4core.color('#fff')
  pieSeries.slices.template.strokeWidth = 2
  pieSeries.slices.template.strokeOpacity = 1
  pieSeries.labels.template.maxWidth = 130
  pieSeries.labels.template.wrap = true
  // eslint-disable-next-line
  pieSeries.slices.template.tooltipHTML = '<span style="color: #ffffff;">{category}: {value.percent.formatNumber(\'#.0\')}% (${value})</span>'

  const data = [
    { amount: otherAmount, source: 'Other' },
    { amount: chatFunnelsAmount, source: 'Signals' }
  ]

  chart.data = data

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  return chart
}

export { createROIChart }
