import React, { useState } from 'react'
import { salesforce } from 'pages/people/ContactPage/integrations/integration-helpers'
import { makeStyles } from '@material-ui/core/styles'
import { ListItemCard, CardList } from 'cf-components/CardList'
import ActionsMenu from 'cf-components/ActionsMenu'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { currency } from './FormatHelpers'
import { parseISO, format } from 'date-fns'
import { useQuery } from 'react-query'

const useStyles = makeStyles(theme => ({
  oppCard: {
    padding: '12px 4px 12px 20px',
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 2fr 2fr 2fr 2fr 2fr 40px',
    gridGap: 10,
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  stackedData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  columnHeader: {
    padding: '5px 4px 5px 20px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '2fr 2fr 2fr 2fr 2fr 2fr 2fr 40px',
    gridGap: 10
  }
}))

function OppCard (props) {
  const classes = useStyles()
  const row = props.row

  const actions = props.instanceURL ? [
    {
      name: 'View in Salesforce',
      action: (e) => {
        window.open(props.instanceURL + '/' + row.OpportunityId)
      },
      icon: OpenInNewIcon
    }
  ] : []

  return (
    <ListItemCard>
      <div className={classes.oppCard}>
        <div>{row.OpportunityName}</div>
        <div>{row.AccountName}</div>
        <div>{row.OpportunityType}</div>
        <div>{currency(row.OpportunityAmount, 2)}</div>
        <div>{row.OpportunityStageName}</div>
        <div>{row.OpportunityCreatedDate && (format(parseISO(row.OpportunityCreatedDate), 'MM/dd/yyyy'))}</div>
        <div>{row.OpportunityCloseDate && (format(parseISO(row.OpportunityCloseDate), 'MM/dd/yyyy'))}</div>
        <div>
          <ActionsMenu
            actions={actions}
          />
        </div>
      </div>
    </ListItemCard>
  )
}

function fetchInstanceURL () {
  return salesforce.getInstanceUrl().then(url => {
    return {
      data: {
        attributes: {
          instance_url: url
        }
      }
    }
  })
}

function SalesforceROIOppList (props) {
  const classes = useStyles()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  const { data: instanceURLData } = useQuery(['salesforce', 'instanceURL'], () => fetchInstanceURL())

  const instanceURL = instanceURLData?.data?.attributes?.instance_url

  return (
    <>
      <CardList
        header
        headerProps={{
          sortAscending: props.sortOptions.sortAscending,
          sortCriteria: props.sortOptions.sortCriteria,
          setSortAscending: (e) => {
            props.sortOptions.setSortAscending(e)
          },
          setSortCriteria: (e) => {
            if (props.sortOptions.sortCriteria === e) {
              props.sortOptions.setSortAscending(!props.sortOptions.sortAscending)
            } else {
              props.sortOptions.setSortCriteria(e)
            }
          },
          columns: [
            { label: 'Opportunity', id: 'OpportunityName' },
            { label: 'Account', id: 'AccountName' },
            { label: 'Type', id: 'OpportunityType' },
            { label: 'Amount', id: 'OpportunityAmount' },
            { label: 'Stage', id: 'OpportunityStageName' },
            { label: 'Created', id: 'OpportunityCreatedDate' },
            { label: 'Closed', id: 'OpportunityCloseDate' }

          ],
          headerClass: classes.columnHeader
        }}
        rows={props.rows || null}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        disableNext
        card={OppCard}
        cardProps={{ instanceURL: instanceURL }}
        sortOptions={props.sortOptions}
      />
    </>
  )
}

export default SalesforceROIOppList
