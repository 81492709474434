import { useEffect, useReducer, useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useQueryClient } from 'react-query'
import Persona from 'classes/personas'
import { Modal } from 'library/Modal'
import { Button, Divider, Icon, TextField, Tooltip, Typography } from 'library/materialUI'
import JobTitleSelector from './JobTitleSelector'
import DepartmentSelector from 'pages/accounts/AccountICP/DepartmentSelector'
import SenioritySelector from 'pages/accounts/AccountICP/SenioritySelector'
import TitlePreview from './TitlePreview'
import PersonaCSVUploadModal from './CSVUploadModal'

export type NewPersona = Pick<Persona, 'name' | 'departments' | 'titles' | 'seniorities' | 'excludedTitles'> & { id?: number, defaultOpen?: boolean }

// const useStyles = makeStyles(theme => ({
//   contentCtn: {
//     color: '#383838',
//     fontSize: 14
//   },
//   radioBtn: {
//     width: 'max-content'
//   },
//   loadingCtn: {
//     display: 'flex',
//     justifyContent: 'center',
//     margin: '24px 0'
//   },
//   personaCtn: {
//     border: `1px solid ${theme.palette.primary.main}`,
//     borderRadius: 10,
//     padding: '18px 30px 4px'
//   },
//   dividerLine: {
//     borderBottom: `1px solid ${theme.palette.primary.light}`,
//     margin: '24px 12px'
//   },
//   addBtn: {
//     marginTop: 12
//   }
// }))

const personaReducer = (state: Persona | NewPersona, changes: Partial<Persona>): any => {
  return { ...state, ...changes }
}

export type PreviewStates = 'start' | 'refresh' | 'empty' | 'not_found' | 'out_of_requests' | 'run' | 'loading'

export default function BuyingCommitteeModal ({ onHide: propsOnHide, ...props }: {
  open: boolean
  onHide: () => void
  persona: Persona | NewPersona
  title?: string
  dontSave?: boolean // needed because ai personas are saved in a different way
  saveState?: any
}): JSX.Element {
  const queryClient = useQueryClient()
  const [personaState, dispatch] = useReducer(personaReducer, props.persona)
  const [previewState, setPreviewState] = useState<PreviewStates>('start')
  const [stateChange, setStateChange] = useState<boolean>(false)
  const [csvModalOpen, setCSVModalOpen] = useState<boolean>(false)
  const [nameInputTouched, setNameInputTouched] = useState<boolean>(false)

  const excludeTitle = (title: string) => {
    dispatch({ ...personaState, excludedTitles: personaState.excludedTitles.concat(title) })
  }

  useEffect(() => {
    if (personaState.titles.length === 0 && personaState.excludedTitles.length === 0 && personaState.departments.length === 0 && personaState.seniorities.length === 0) {
      setPreviewState('empty')
    } else if (stateChange) {
      if (previewState === 'run') {
        setPreviewState('refresh')
      } else if (previewState !== 'refresh') {
        setPreviewState('start')
      }
    }
  }, [personaState, previewState, stateChange])

  return (
    <>
      {!csvModalOpen && (
        <Modal
          title={props.title ? props.title : 'Create Persona'}
          helplink='https://help.getsignals.ai/article/lw4paje3sm-buying-committees#buying_committees'
          open={props.open && !csvModalOpen}
          onHide={() => {
            dispatch({ ...props.persona })
            setPreviewState('start')
            propsOnHide()
          }}
          size='md'
          saveDisabled={!personaState.name || previewState === 'empty'}
          saveDisabledTooltip='You must give your persona a name and select at least one title, department, or seniority'
          saveIcon='save'
          helplinkLabel='Learn more about buying committee personas'
          handleSave={() => {
            setNameInputTouched(false)
            dispatch({ ...personaState })
            setPreviewState('start')
            propsOnHide()
            props.saveState && props.saveState(personaState)
            !props.dontSave && (
              Promise.all<any>([
                Persona.save(personaState)
              ]).then(() => queryClient.invalidateQueries(['personas']))
            )
          }}
          flexContent
        >
          <div style={{ display: 'flex', maxWidth: 750 }}>
            <div style={{ width: '50%' }}>
              <div style={{ height: '100%', maxHeight: 550, paddingTop: 5, backgroundColor: '#F5F5F5' }}>
                <div style={{ marginRight: 30 }}>
                  <TextField
                    variant='outlined'
                    label='Persona Name'
                    value={personaState.name}
                    error={!personaState.name && nameInputTouched}
                    onBlur={() => setNameInputTouched(true)}
                    helperText={!personaState.name && nameInputTouched ? 'You must give your persona a name' : ''}
                    onChange={(e) => {
                      dispatch({ name: e.target.value })
                    }}
                  />
                  <Typography
                    style={{ marginTop: 25 }}
                    variant='body1'
                  >
                    Select titles, departments, and seniorities to include in your persona
                  </Typography>
                  <Tooltip title='For an exact match, type your title in quotes. Example: "Marketing Manager"' placement='right'>
                    <div>
                      <JobTitleSelector
                        values={personaState.titles}
                        setValues={(changes) => {
                          // strip all double quotes from the titles
                          // this can happen if a user puts in a quoted string (e.g. "Chair of Surgery"),
                          // but we don't want them quoted when we query the API.
                          const updated = changes.map(v => v.replace(/"/g, ''))
                          dispatch({ titles: updated })
                          setStateChange(true)
                        }}
                        title='Titles'
                        placeholder='Search Titles...'
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title='For an exact match, type your title in quotes. Example: "Marketing Manager"' placement='right'>
                    <div>
                      <JobTitleSelector
                        values={personaState.excludedTitles}
                        setValues={(changes) => {
                          dispatch({ excludedTitles: changes })
                          setStateChange(true)
                        }}
                        title='Excluded Titles'
                        placeholder='Enter titles to exclude'
                        isExcluded
                      />
                    </div>
                  </Tooltip>
                  <Button
                    variant='outlined'
                    onClick={() => setCSVModalOpen(true)}
                  >
                    <Icon icon='upload' /><div style={{ marginLeft: 5 }}>UPLOAD CSV OF TITLES</div>
                  </Button>
                  <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                  <DepartmentSelector
                    values={personaState.departments}
                    setValues={(changes) => {
                      dispatch({ departments: changes })
                      setStateChange(true)
                    }}
                  />
                  <SenioritySelector
                    values={personaState.seniorities}
                    setValues={(changes) => {
                      dispatch({ seniorities: changes })
                      setStateChange(true)
                    }}
                  />
                </div>
              </div>
            </div>
            <Divider orientation='vertical' />
            <div style={{ width: '50%' }}>
              <Typography
                variant='body2'
                style={{ display: 'flex', marginLeft: 15, fontSize: 20, fontWeight: 600 }}
              >
                Title Preview
                <Tooltip title='This is only a sample of possible titles, not an exhaustive list'>
                  <div style={{ display: 'flex', marginLeft: 5, alignItems: 'center' }}>
                    <Icon icon='infoIcon' specificSize={20} />
                  </div>
                </Tooltip>
              </Typography>
              <div style={{ height: '90%', maxHeight: 552, backgroundColor: '#F5F5F5' }}>
                <TitlePreview
                  persona={personaState}
                  previewState={previewState}
                  setPreviewState={(state: PreviewStates) => { setStateChange(false); setPreviewState(state) }}
                  excludeTitle={(title: string) => excludeTitle(title)}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {csvModalOpen && (
        <PersonaCSVUploadModal
          open={csvModalOpen}
          handleGoBack={() => setCSVModalOpen(false)}
          closeCSVModal={() => setCSVModalOpen(false)}
          setTitles={(changes) => {
            dispatch({
              ...personaState,
              titles: personaState.titles.concat(changes.titles),
              excludedTitles: personaState.excludedTitles.concat(changes.excludedTitles)
            })
            setStateChange(true)
          }}
        />
      )}
    </>
  )
}
