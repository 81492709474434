import { makeStyles } from '@material-ui/core/styles'
import { triggerSupportBot } from 'pages/bot/InAppBot/triggerSupportBot'
import { THelpCenterCard, HelpCenterCard } from './HelpCenterCard'
import { Icon } from 'library/materialUI'
import { useContext } from 'react'
import { HelpCenterContext } from './HelpCenterContext'

const useStyles = makeStyles(theme => ({
  headerText: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000000BF'
  },
  subHeader: {
    fontSize: '18px',
    color: '#000000BF',
    marginTop: '15px',
    marginBottom: '10px',
    fontWeight: 'bold'
  },
  buttonList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px'
  },
  button: {
    width: '156px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    borderRadius: '10px',
    border: '1px solid #E2E2E2',
    backgroundColor: '#FFFFFF',
    paddingLeft: '10px',
    cursor: 'pointer'
  }
}))

export function HelpCenterHome () {
  const classes = useStyles()
  const { navigateForward } = useContext(HelpCenterContext)

  const cards: THelpCenterCard[] = [
    { title: 'Chat with Support', subtitle: 'Speak to a product expert now', icon: 'questionIcon', action: () => { triggerSupportBot() } },
    { title: 'Feedback', subtitle: 'Report a problem, request a feature, or view the status of past feedback', icon: 'noticeIcon', link: 'feedback' },
    { title: 'Go to Helpdocs', subtitle: 'Looking for something more?', icon: 'helpDocs', actionIcon: 'exit', action: () => window.open('https://help.getsignals.ai', '_blank') }
  ]

  return (
    <div>
      <div className={classes.headerText}>
        How can we help?
      </div>
      <div>
        {cards.map((card: THelpCenterCard, index: number) => (
          <HelpCenterCard key={index} card={card} />
        ))}
      </div>
      <div>
        <div className={classes.subHeader}>
          Quick help articles
        </div>
        <div className={classes.buttonList}>
          <div onClick={() => navigateForward('plays')} className={classes.button}>
            <Icon icon='play' color='black' />
            <div style={{ marginLeft: '10px' }}>
              Building Plays
            </div>
          </div>
          <div onClick={() => navigateForward('bots')} className={classes.button}>
            <Icon icon='cursor' color='black' />
            <div style={{ marginLeft: '10px' }}>
              Building Bots
            </div>
          </div>
          <div onClick={() => navigateForward('settings')} className={classes.button}>
            <Icon icon='settings' color='black' />
            <div style={{ marginLeft: '10px' }}>
              Settings
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
