import { makeStyles } from '@material-ui/core/styles'
import { ApiProvider } from 'api-context'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { PostageSocketProvider } from 'components/PostageSocket'
import MobileDetect from 'mobile-detect'
import { NotificationProvider } from 'notification-context'
import MobileApp from 'pages/MobileApp'
import { NotificationsDrawer } from 'pages/NotificationsDrawer'
import { HelpCenterDrawer } from 'pages/helpCenter/HelpCenter'
import { MainAppRouting } from 'pages/navigation/MainAppRouting'
import { NavigationSidebar } from 'pages/navigation/NavigationSidebar'
import { useContext, useEffect, useState } from 'react'
import { components, SessionContext } from 'session-context'
import { SetupModal } from './onboarding/SetupModal'
import { NPSPrompt } from './onboarding/nps/NPSPrompt'
import NonActiveAccountModal from 'components/NonActiveAccountModal/NonActiveAccountModal'
import SalesloftIFrameWindow from 'library/salesloft/SalesloftIFrameWindow'
import { LiveViewDrawer } from './chat/live-view/LiveViewDrawer'
import { useLocation } from 'react-router-dom'
import { triggerSalesBot, triggerSupportBot } from './bot/InAppBot/triggerSupportBot'
import { EVENT_TYPE, Emitter } from 'emitter'
import { CRMProvider } from 'crm-context'
import { ShareProvider } from 'share-context'
import AccessControl from 'components/AccessControl'

const useStyles = makeStyles(theme => ({
  inAppWarningIcon: {
    position: 'relative',
    display: 'inline-block',
    top: '5px'
  },
  inAppWarningBtn: {
    color: 'gray'
  },
  inAppWarning: {
    padding: '10px',
    width: '100%',
    color: 'gray',
    textAlign: 'center',
    backgroundColor: 'lightgoldenrodyellow'
  },
  root: {
    display: 'flex',
    fontFamily: theme.typography.fontFamily
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    fontFamily: theme.typography.fontFamily
  }
}))

function MainApp () {
  const classes = useStyles()

  const { user, subscriptionStatus, snackState, setSnackState } = useContext(SessionContext)
  const inactiveAccount = ['expired', 'on_hold', 'canceled', 'not_found'].includes(subscriptionStatus)
  const md = new MobileDetect(window.navigator.userAgent)
  const phone = !!md.phone()
  const chatServiceUrl = user.links.chat_service
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [participant, setParticipant] = useState(null)
  const { search } = useLocation()

  function handleQuickChat (participant: any) {
    setParticipant(participant)
    setDrawerOpen(true)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    if (searchParams.get('sales')) {
      triggerSalesBot()
    } else if (searchParams.get('support')) {
      triggerSupportBot()
    }
  }, [search])

  if (!user) {
    return <></>
  }

  if (window.location.href.includes('iframe/salesloft')) {
    return (
      <SalesloftIFrameWindow />
    )
  }

  if (phone) {
    return (
      <MobileApp />
    )
  }

  window.onpopstate = () => {
    Emitter.emit(EVENT_TYPE.BROWSER_BACK, {})
  }

  return (
    <PostageSocketProvider>
      <NotificationProvider>
        <ApiProvider>
          <CRMProvider>
            <ShareProvider>
              <div className={classes.root}>
                <NPSPrompt />
                <AccessControl requiredComponent={components.PLAYMAKER}>
                  <SetupModal />
                </AccessControl>
                <NavigationSidebar />
                <HelpCenterDrawer />
                <NotificationsDrawer handleQuickChat={handleQuickChat} />
                <LiveViewDrawer
                  open={drawerOpen}
                  participant={participant}
                  chatServiceUrl={chatServiceUrl}
                  close={() => setDrawerOpen(false)}
                />
                <main className={classes.content} id='main-content'>
                  {(inactiveAccount) && <NonActiveAccountModal inactiveType={subscriptionStatus} />}
                  <MainAppRouting />
                </main>
              </div>
              <CustomizedSnackbar state={snackState} handler={setSnackState} />
            </ShareProvider>
          </CRMProvider>
        </ApiProvider>
      </NotificationProvider>
    </PostageSocketProvider>
  )
}

export default MainApp
