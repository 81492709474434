/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect, SetStateAction, Dispatch } from 'react'
import DeleteModal from 'components/DeleteModal'
import { defaultSegment } from 'pages/segments/segmentHelpers'
import SegmentList from './SegmentList'
import { Segment } from 'classes/segments'
import SegmentModal from './SegmentModal'
import Loader from 'library/loading/Loader'
import { getSettings, saveSettings } from 'api/user_display_settings'
import { ICP } from 'classes/icp'
import ICPBanner from 'library/banners/IcpBanner'

export interface SegmentListPageProps {
  permission: boolean
  objectType: Segment['objectType']
  refreshSegmentPage: number
  segmentModalOpen: boolean
  setSegmentModalOpen: Dispatch<SetStateAction<boolean>>
}

export default function SegmentListPage ({ permission, objectType, refreshSegmentPage, segmentModalOpen, setSegmentModalOpen }: SegmentListPageProps): JSX.Element {
  const { data: segments, isLoading } = Segment.loadAll({
    searchParams: {
      filters: [[
        { field: 'object_type', operator: 'eq', value: 'domains' }
      ]],
      customSort: 'name = \'ICP\' desc, created_timestamp desc'
    }
  })
  const [segment, setSegment] = useState<Segment>(defaultSegment)
  const [deleteSegmentID, setDeleteSegmentID] = useState<number | undefined>(undefined)
  const [showBanner, setShowBanner] = useState<boolean | null>(null)
  HandleSegmentSelection({ refreshSegmentPage, setSegment })
  HandleICPSetupBanner({ setShowBanner, showBanner })

  if (isLoading) {
    return (
      <div style={{ width: '100%', height: 1000 }}>
        <Loader type='spinner' size='lg' />
      </div>
    )
  }

  const removeSegment = (segmentID?: Segment['id']): void => {
    if (!segmentID) {
      return
    }
    const segment = new Segment({ row: { id: segmentID, attributes: {} } })
    segment.delete().then(() => {
      setDeleteSegmentID(undefined)
    })
  }

  return (
    <>
      {showBanner && <ICPBanner />}
      <SegmentList
        rows={segments.list}
        requiredPerm={permission}
        handleDelete={setDeleteSegmentID}
      />
      <SegmentModal
        segment={segment}
        currentSegments={segments.list}
        objectType={objectType}
        open={segmentModalOpen}
        setSegmentModalOpen={setSegmentModalOpen}
      />
      <DeleteModal
        open={Boolean(deleteSegmentID)}
        onHide={() => setDeleteSegmentID(undefined)}
        deleteObject={() => removeSegment(deleteSegmentID)}
        message='Are you sure you want to delete this segment?'
        title='Delete Segment'
      />
    </>
  )
}

function HandleSegmentSelection ({ refreshSegmentPage, setSegment }:{ refreshSegmentPage: number, setSegment: Dispatch<SetStateAction<Segment>> }) {
  useEffect(() => {
    if (refreshSegmentPage > 0) setSegment(defaultSegment)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSegmentPage])
}

function HandleICPSetupBanner ({ setShowBanner, showBanner }: { setShowBanner: Dispatch<boolean>, showBanner: null | boolean }) {
  const { data: icp, isLoading, error } = ICP.get()

  if (error && !error.message.includes('Not Found')) {
    console.log({ error })
  }

  if (!isLoading && showBanner === null) {
    getSettings({ kind: 'icp_setup', sid: undefined })
    .then((value) => {
      const attributes = value.data.attributes
      const isCreated = attributes.created_timestamp !== null
      const hasICP = !!icp
      if (!isCreated) {
        saveSettings({ kind: 'icp_setup', settings: { segment_page_banner: { complete: hasICP } }, post: true })
        setShowBanner(!hasICP)
      } else {
        const settings = attributes.settings
        const pageBanner = settings.segment_page_banner
        const showBan = pageBanner.complete || hasICP
        setShowBanner(!showBan)
      }
    })
  }
}
