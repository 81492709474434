import { makeStyles } from '@material-ui/core/styles'
import { FilterBar } from 'cf-components/FilterBar/FilterBar'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import { Card, CardContent, Select, Tooltip, Typography } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { Button, Icon, IconButton, Popover } from 'library/materialUI'
import MuteMenu from 'cf-components/MuteMenu'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  liveViewFilter: {
    display: 'flex',
    width: '100%'
  },
  onlineDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    width: 250,
    fontFamily: 'Poppins, sans serif',
    paddingLeft: 20
  },
  iconText: {
    display: 'flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    gap: 10
  },
  visitorCount: {
    justifySelf: 'flex-end',
    alignSelf: 'center',
    marginLeft: 'auto',
    fontWeight: 600
  },
  messageChip: {
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  chipTail: {
    position: 'relative',
    top: 38,
    left: 4,
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '10px solid white',
    transform: 'rotate(200deg)'
  },
  barIcon: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  barIconSelected: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  }
}))

const VisibilityToggle = ({ type, icon, title, visitorCount, hidden, setHidden, setAllHidden, setRefreshed }) => {
  const classes = useStyles()
  return (
    <div className={classes.iconText}>
      <Button
        style={{ textTransform: 'none' }}
        variant='text'
        onClick={() => {
          setAllHidden(type)
          setHidden(false)
          setRefreshed(r => !r)
        }}
        startIcon={icon}
      >
        <Typography>
          {title}
        </Typography>
      </Button>
      <Typography className={classes.visitorCount}>
        {visitorCount}
      </Typography>
      <IconButton
        style={{ height: 'fit-content' }}
        onClick={() => {
          setHidden(!hidden)
          setRefreshed(r => !r)
        }}
        size='small'
      >
        {hidden ? <Icon size='sm' icon='hidePassword' color='#c4c4c4' /> : <Icon size='sm' icon='showPassword' color='#808080' />}
      </IconButton>
    </div>
  )
}

const TrafficFilterCard = (props) => {
  const state = props.state
  const dispatch = props.dispatch
  const setAllHidden = (value) => dispatch({ type: 'setAll', value: value })

  let openOpps = 0
  let highScores = 0
  let personaMatches = 0
  let icpMatches = 0
  let otherTraffic = 0
  props.rows.forEach((row) => {
    let other = 1
    if (row.fromBuyingCommittee) {
      other = 0
      personaMatches++
    }
    if (row.signalsScore >= 70) {
      other = 0
      highScores++
    }
    if (row.isICP) {
      other = 0
      icpMatches++
    }
    if (row.opportunityStatus === 'Open Opp') {
      other = 0
      openOpps++
    }
    otherTraffic += other
  })

  return (
    <Card>
      <CardContent>
        <VisibilityToggle
          type='openOpp'
          icon={<Icon icon='openOpp' specificSize={20} />}
          title='Open Opportunity'
          visitorCount={openOpps}
          hidden={state.openOpp}
          setHidden={(value) => dispatch({ type: 'openOpp', value: value })}
          setAllHidden={setAllHidden}
          setRefreshed={props.setRefreshed}
        />
        <VisibilityToggle
          type='highSignalsScore'
          icon={<Icon icon='highSignalsScore' />}
          title='High Signals Score'
          visitorCount={highScores}
          hidden={state.highSignalsScore}
          setHidden={(value) => dispatch({ type: 'highSignalsScore', value: value })}
          setAllHidden={setAllHidden}
          setRefreshed={props.setRefreshed}
        />
        <VisibilityToggle
          type='buyingCommittee'
          icon={<Icon icon='buyingCommitteeBubble' />}
          title='Buying Committee'
          visitorCount={personaMatches}
          hidden={state.buyingCommittee}
          setHidden={(value) => dispatch({ type: 'buyingCommittee', value: value })}
          setAllHidden={setAllHidden}
          setRefreshed={props.setRefreshed}
        />
        <VisibilityToggle
          type='matchesICP'
          icon={<Icon icon='icpCircle' />}
          title='Matches ICP'
          visitorCount={icpMatches}
          hidden={state.matchesICP}
          setHidden={(value) => dispatch({ type: 'matchesICP', value: value })}
          setAllHidden={setAllHidden}
          setRefreshed={props.setRefreshed}
        />
        <VisibilityToggle
          type='otherTraffic'
          icon={<DotIcon fontSize='small' style={{ fill: '#505050' }} />}
          title='Other traffic'
          visitorCount={otherTraffic}
          hidden={state.otherTraffic}
          setHidden={(value) => dispatch({ type: 'otherTraffic', value: value })}
          setAllHidden={setAllHidden}
          setRefreshed={props.setRefreshed}
        />
      </CardContent>
    </Card>
  )
}

function LiveViewFilterBar (props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const mapMode = props.mode === 'map'

  let onlineMessage = props.onlineCount + ' Visitors Online'
  let fill = '#33dd55'
  if (props.onlineCount === 0) {
    onlineMessage = 'No Visitors Online'
    fill = 'rgb(240, 112, 111)'
  } else if (props.onlineCount === 1) {
    onlineMessage = '1 Visitor Online'
  }

  useEffect(() => {
    if (mapMode) {
      setOpen(false)
    }
  }, [mapMode])

  return (
    <div className={classes.liveViewFilter}>
      <div
        id='signals-online-message'
        className={classes.onlineDiv}
        style={{ cursor: mapMode ? 'default' : 'pointer', paddingBottom: mapMode ? 10 : 0 }}
        onClick={mapMode ? () => {} : () => setOpen(true)}
      >
        <DotIcon
          fontSize='small'
          style={{ marginRight: 5, fill: fill }}
        />
        {onlineMessage}
        {!mapMode && <Icon icon='expand' />}
      </div>
      {props.showHidden && (
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          {props.displayRegion === 'globe' && mapMode ? (
            <Tooltip title={props.autoRotateGlobe ? 'Rotation on' : 'Rotation off'}>
              <div
                className={classes.barIcon}
                style={{ marginRight: 10 }}
                onClick={() => {
                  props.setAutoRotateGlobe(!props.autoRotateGlobe)
                  props.setRefreshed(r => !r)
                }}
              >
                <Icon icon='threeSixty' color={props.autoRotateGlobe ? 'primary' : 'currentColor'} />
              </div>
            </Tooltip>
          ) : null}
          {mapMode &&
            <div className={props.autoFocus ? classes.barIconSelected : classes.barIcon} style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
              <Tooltip title={props.autoFocus ? 'Event auto-focus on' : 'Event auto-focus off'}>
                <FilterCenterFocusIcon
                  onClick={() => props.setAutoFocus(!props.autoFocus)}
                />
              </Tooltip>
            </div>}
          {mapMode && (
            <div style={{ marginRight: 10 }}>
              <MuteMenu />
            </div>)}
          <Select
            value={props.displayRegion}
            onChange={(event) => props.setDisplayRegion(event.target.value)}
          >
            <MenuItem value='world'>Worldwide</MenuItem>
            <MenuItem value='africa'>Africa</MenuItem>
            <MenuItem value='asia'>Asia</MenuItem>
            <MenuItem value='europe'>Europe</MenuItem>
            <MenuItem value='northAmerica'>North America</MenuItem>
            <MenuItem value='unitedStates'>United States</MenuItem>
            <MenuItem value='southAmerica'>South America</MenuItem>
            <MenuItem value='oceania'>Oceania</MenuItem>
            <MenuItem value='globe'>Globe</MenuItem>
          </Select>
          <FilterBar
            search={props.search}
            setSearch={props.setSearch}
            sortOptions={props.sortOptions}
            filterOptions={props.filterOptions}
          />
        </div>
      )}
      <div style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
        {mapMode ? <TrafficFilterCard rows={props.rows} state={props.trafficFilterState} dispatch={props.trafficFilterDispatch} setRefreshed={props.setRefreshed} /> : null}
      </div>
      <Popover
        open={open}
        anchorEl={document.getElementById('signals-online-message')}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <TrafficFilterCard rows={props.rows} state={props.trafficFilterState} dispatch={props.trafficFilterDispatch} setRefreshed={props.setRefreshed} />
      </Popover>
    </div>
  )
}

export default LiveViewFilterBar
