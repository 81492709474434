import { RouteType } from 'library/navigation/Route'
import LandingDashboard from 'pages/dashboard/LandingDashboard'
import ContactManagementPage from 'pages/people/ContactManagementPage'
import ContactPage from 'pages/people/ContactPage/ContactPage'
// import ContactSegmentPage from 'pages/people/SegmentsPage/SegmentPage'
import AccountSegmentPage from 'pages/accounts/AccountSegments/SegmentPage'
import AccountPage from 'pages/accounts/AccountDashboard/AccountPage'
import AccountManagementPage from 'pages/accounts/AccountManagement/AccountManagementPage'

export const AccountRoutes: RouteType[] = [
  { path: '/dashboard', component: LandingDashboard },
  { path: '/contact_management', component: ContactManagementPage, exact: false },
  { path: '/contacts/:contact_id', component: ContactPage },
  // { path: '/contact_segments/:segment_id', component: ContactSegmentPage },
  { path: '/account_segments/:segment_id', component: AccountSegmentPage },
  { path: '/accounts/:account_id', component: AccountPage },
  { path: '/account_management', component: AccountManagementPage, exact: false }
]
