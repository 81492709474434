
import React from 'react'

import SalesforceROI from './SalesforceROI'

export default function SalesforceROIPipelineSourced () {
  document.title = 'Pipeline Sourced | Signals'
  return (
    <SalesforceROI
      label='Pipeline sourced by Signals'
      series='OpportunityType'
      filterFunction={row => {
        return row.Sourced
      }}
    />
  )
}
