import { useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, ModalSection } from 'library/Modal'
import { TextField } from '@material-ui/core'
import { createBot } from 'api/bots'
import Picker from 'cf-components/Pickers'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Bot } from 'classes/classes'

const useStyles = makeStyles(theme => ({
  instructions: {
    paddingBottom: 15
  },
  valueDiv: {
    width: '60%'
  }
}))

const initialState = {
  name: '',
  description: '',
  channelIDs: []
}

const reducer = (state, changes) => {
  return { ...state, ...changes }
}

export default function NewBotModal (props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const queryClient = useQueryClient()
  const history = useHistory()
  const botDetails = props.botDetails

  const saveBot = () => {
    createBot({
      name: state.name,
      description: state.description,
      channelIDs: state.channelIDs,
      kind: botDetails.kind,
      template: botDetails.template
    }).then(response => {
      const bot = new Bot({ row: response.data })
      queryClient.setQueryData(['bots', bot.id], bot)
      history.push(`/bots/${bot.id}`)
    })
  }

  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      mode='plain'
      title='New Bot'
      saveBtnText='Create Bot'
      saveIcon='add'
      handleSave={saveBot}
      saveDisabled={!state.name}
    >
      <div>
        <ModalSection
          title='Name'
          subtitle='Give your bot a name. This is for internal use only and will not be seen by web visitors.'
        >
          <div className={classes.valueDiv}>
            <TextField
              margin='dense'
              variant='outlined'
              fullWidth
              onChange={(e) => dispatch({ name: e.target.value })}
              placeholder='Chatbot name'
            />
          </div>
        </ModalSection>
        <ModalSection title='Description'>
          <TextField
            margin='dense'
            multiline
            rows={2}
            variant='outlined'
            fullWidth
            onChange={(e) => dispatch({ description: e.target.value })}
            placeholder='Type a short description here'
          />
        </ModalSection>
        <ModalSection title='Channels'>
          <div style={{ paddingTop: 8, paddingBottom: 5 }}>
            <Picker
              selection={state.channelIDs}
              setSelection={(value) => dispatch({ channelIDs: value })}
              objectType='channels'
              placeholder='Select a channel'
              multiple
            />
          </div>
        </ModalSection>
      </div>
    </Modal>
  )
}
