import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import { AccountOwnerField, CRMRecordType, Channel, User, Bot, Team, Email, Segment, Persona, Play, MeetingType, KeywordGroups, Tag, HighIntentCategory, Cadence } from 'classes/classes'
import './custom-styles.css'
import { ContactList } from 'classes/contactList'
import Loader from './loading/Loader'

declare global {
  interface Window { autocompleteOpen: boolean }
}

const useStyles = makeStyles(theme => ({
  renderOption: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: 10
  },
  inputRoot: {
    minWidth: 0
  }
}))

function RenderInput (props: any): JSX.Element {
  return (
    <TextField
      {...props}
      variant='outlined'
      inputProps={props.inputProps}
      label={props.label}
      placeholder={props.InputProps.startAdornment ? undefined : props.placeholder}
    />
  )
}

function AvatarDiv (props: any): JSX.Element {
  const classes = useStyles()
  const option = props.option
  if (option.avatarUrl) {
    return (
      <Avatar
        src={option.avatarUrl}
        className={classes.avatar}
      />
    )
  }
  return (
    <></>
  )
}

function RenderOption (props: any): JSX.Element {
  const classes = useStyles()
  const option = props.option

  if (props.multiple) {
    return (
      <div className={classes.renderOption}>
        <Checkbox
          color='primary'
          checked={props.checked}
        />
        <AvatarDiv option={option} />
        {option.name}
      </div>
    )
  }
  return (
    <div className={classes.renderOption}>
      <AvatarDiv option={option} />
      {option.name}
    </div>
  )
}

const mapping = {
  teams: Team,
  users: User,
  channels: Channel,
  bots: Bot,
  emails: Email,
  segments: Segment,
  personas: Persona,
  plays: Play,
  meetingTypes: MeetingType,
  tags: Tag,
  keywordGroups: KeywordGroups,
  highIntentCategories: HighIntentCategory,
  contactList: ContactList,
  accountOwnerFields: AccountOwnerField,
  crmRecordTypes: CRMRecordType,
  cadence: Cadence
}

interface Attributes {
  email?: string,
}

export type PickerObjectType = keyof typeof mapping
export type PickerKeyType = keyof Attributes

interface PickerProps {
  selection: any
  setSelection: (value: any) => void
  multiple?: false
  objectType: PickerObjectType
  label?: string
  keySelect?: PickerKeyType
  placeholder?: string
}

interface MultiPickerProps {
  selection: any[]
  setSelection: (value: any[]) => void
  multiple: true
  objectType: PickerObjectType
  label?: string
  keySelect?: PickerKeyType
  placeholder?: string
}

interface PartialObject extends Attributes {
  id: number
  name: string
}

export function Picker (props: PickerProps | MultiPickerProps): JSX.Element {
  const setSelection = props.setSelection
  const object = mapping[props.objectType]
  const keySelect = props.keySelect
  const { data, isLoading } = object.loadAll('')
  const items: PartialObject[] | [] = data?.list
  let renderOption = (option: any): JSX.Element => (<RenderOption option={option} />)
  let onChange = (event: any, obj: any): void => setSelection(obj)
  let value = null

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Loader type='dropdown' />
      </div>
    )
  }

  if (props.multiple) {
    renderOption = (option) => (
      <RenderOption
        option={option}
        checked={keySelect ? props.selection.includes(option[keySelect]) : props.selection.includes(option.id)}
        multiple
      />)
    if (keySelect) {
      value = items.filter(u => props.selection.includes(u[keySelect]))
    } else {
      value = items.filter(u => props.selection.includes(u.id))
    }
    onChange = (event, obj) => {
      if (keySelect) {
        const currentAttributes = obj.map((o: any) => o[keySelect])
        setSelection(currentAttributes)
      } else {
        const currentIDs = obj.map((o: any) => o.id)
        setSelection(currentIDs)
      }
    }
  } else if (props.selection) {
    value = items.filter(i => i.id === props.selection)[0]
  }

  return (
    <div className='autocomplete'>
      <Autocomplete
        options={items}
        fullWidth
        value={value}
        multiple={props.multiple}
        autoHighlight
        disableCloseOnSelect={props.multiple}
        limitTags={2}
        onChange={onChange}
        getOptionLabel={(option) => option.name}
        renderOption={renderOption}
        renderInput={(params) => <RenderInput {...params} label={props.label} placeholder={props.placeholder} />}
        size='small'
        onOpen={() => { window.autocompleteOpen = true }}
        onClose={() => { setTimeout(() => { window.autocompleteOpen = false }, 100) }}
      />
    </div>
  )
}

export default Picker
