import { useMemo } from 'react'
import dateFormat from 'dateformat'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { Link } from 'react-router-dom'
import { Segment } from 'classes/segments'
import ActionsMenu from 'cf-components/ActionsMenu'
import { ListItemCard } from 'cf-components/CardList'
import Table from 'cf-components/Table'
import { AvatarWithInfo, getInitials } from 'cf-components/Avatars'
import { capitalizeFirstLetter, getSegmentColor } from './segmentHelpers'
import Label from 'library/typography/Label'

const useStyles = makeStyles((theme) => ({
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 50px',
    padding: '5px 15px',
    alignItems: 'center'
  },
  segmentCard: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 50px',
    padding: 15,
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none',
    color: '#444444',
    width: '100%',
    height: '100%',
    fontWeight: 600,
    cursor: 'pointer'
  },
  icpCard: {
    backgroundColor: '#E760331A'
  }
}))

const sortValues = [
  { label: 'Name', id: 'sortName', header: true },
  { label: 'Type', id: 'segmentType', header: true },
  { label: 'Date Created', id: 'createdTimestamp', header: true }
]

export default function SegmentsList ({
  rows: propRows,
  requiredPerm,
  handleDelete
}: {
  rows: Segment[] | undefined | null
  requiredPerm: boolean
  handleDelete: (SegmentID: Segment['id']) => void
}): JSX.Element {
  const classes = useStyles()
  const rows = useMemo(() => !propRows ? null : propRows.map(row => {
    const initials = getInitials(row.name, 'M')
    const segmentType = capitalizeFirstLetter(row.segmentType || '---')
    return {
      id: row.id,
      name: row.name,
      sortName: row.name?.toLowerCase(),
      searchField: row.name,
      segmentType: segmentType,
      createdTimestamp: row.createdTimestamp,
      count: row.objectCount,
      initials: initials,
      link: `/${row.segObjectType.slice(0, -1) === 'domain' ? 'account' : row.segObjectType.slice(0, -1)}_segments/${row.id}`,
      objectType: capitalizeFirstLetter(row.segObjectType),
      objectCount: row.objectCount || '--'
    }
    // This Non-null assertion below isn't pretty but we do want null at times to
    // indicate loading. The reason we use this is so Jake doesn't have to write the
    // type for rows and can instead use `typeof rows` later on.
  }), [propRows])!

  const AccountSegmentCard = (props: {
    row: (typeof rows)[number]
    index: number
  }): JSX.Element => {
    const classes = useStyles()
    const row = props.row
    const index = props.index
    const color = getSegmentColor(index)
    const actions = [
      { name: 'Delete Segment', action: () => handleDelete(row.id), icon: DeleteIcon }
    ]
    const isICP = row.name === 'ICP'
    const cardStyle = isICP ? `${classes.segmentCard} ${classes.icpCard}` : `${classes.segmentCard}`
    const title = isICP ? <Label endIcon='autoAwesome' label={new Segment({}).ICPTitle} /> : row.name
    const initials = isICP ? 'ICP' : row.initials
    const avatarColor = isICP ? '#E76033' : color
    return (
      <ListItemCard>
        <div className={cardStyle}>
          <Link to={row.link} className={classes.link}>
            <AvatarWithInfo
              title={title}
              initials={initials}
              avatarColor={avatarColor}
              subtitle={`Companies: ${row.objectCount}`}
              variant='rounded'
            />
          </Link>
          <div>
            {row.segmentType}
          </div>
          <div>
            {dateFormat(row.createdTimestamp || '')}
          </div>
          {requiredPerm && !isICP &&
            <ActionsMenu
              actions={actions}
            />}
        </div>
      </ListItemCard>
    )
  }

  return (
    <Table
      rows={rows}
      card={AccountSegmentCard}
      sortValues={sortValues}
      header
      headerClass={classes.headerClass}
      sortAscending={false}
    />
  )
}
