import { makeStyles } from '@material-ui/core/styles'
import { Route, Redirect } from 'react-router-dom'
import { RouteType, Router } from 'library/navigation/Route'
import { UserRoutes } from './UserRouting'
import { EngagementRoutes } from './EngagementRouting'
import { AccountRoutes } from './AccountRouting'
import OverviewPage from 'pages/reports/OverviewPage'
import OAuthSetup from 'pages/oauth/OAuthSetup'
import ChangeEmailConfirmation from 'pages/settings/EmailManagement/ChangeEmailConfirmation'
import LiveViewPage from 'pages/chat/live-view/LiveViewPage'
import ChatPage from 'pages/chat/agent-chat/AgentChat'
import SettingsPage from 'pages/settings/SettingsRouter'
import AdminPortalRouter from 'pages/admin_portal/AdminPortalRouter'
import { FreeTrialBanner } from 'pages/FreeTrialBanner'

const useStyles = makeStyles(theme => ({
  pages: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    fontFamily: theme.typography.fontFamily
  }
}))

const OtherRoutes: RouteType[] = [
  { path: '/reports', component: OverviewPage, exact: false },
  { path: '/integrations/oauth/setup', component: OAuthSetup },
  { path: '/change-email-confirmation', component: ChangeEmailConfirmation, exact: false },
  { path: '/live_view', component: LiveViewPage, exact: false },
  { path: '/chat', component: ChatPage, exact: false },
  { path: '/settings', component: SettingsPage, exact: false },
  { path: '/admin_portal', component: AdminPortalRouter, exact: false }
]

export function MainAppRouting () {
  const classes = useStyles()
  const href = window.location.href
  if (href.includes('calendar_oauth')) {
    window.sessionStorage.setItem('onboardingConfirmation', 'calendar')
  } else if (href.includes('linkedin_oauth')) {
    window.sessionStorage.setItem('onboardingStep', 'photo')
  }
  return (
    <div className={classes.pages} id='main-pages'>
      <FreeTrialBanner />
      <Route exact path='/'>
        <Redirect to='/dashboard' />
      </Route>
      <Route exact path='/calendar_oauth'>
        <Redirect to='/settings/my_settings/my_calendar' />
      </Route>
      <Route exact path='/linkedin_oauth'>
        <Redirect to='/dashboard' />
      </Route>
      <Route path='/quick-chat'>
        <Redirect to='/dashboard' />
      </Route>
      <Router routes={UserRoutes} />
      <Router routes={EngagementRoutes} />
      <Router routes={AccountRoutes} />
      <Router routes={OtherRoutes} />
    </div>
  )
}
