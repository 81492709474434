/* eslint-disable @typescript-eslint/naming-convention */
import { Sequence } from 'classes/sequences'
// import { BotDashboard } from 'library/dashboard/BotDashboard'
// import { EmailDashboard } from './EmailDashboard'
import { ListPage } from 'library/listPage/ListPage'
import { useContext, useState } from 'react'
import { NewPlayModal } from './NewPlayModal'
import { PlayDashboard } from './PlayDashboard'
import { RouterPage, ActionDiv } from './RouterPage'
import { FilterComp } from 'classes/queryHelpers'
import { Bot, Email } from 'classes/classes'
import { BotSequence } from 'classes/botSequences'
import BotPerformancePage from 'pages/bot/BotPerformance/BotPerformancePage'
import FormPerformancePage from 'pages/forms/FormPerformance/FormPerformancePage'
import EmailPerformancePage from 'pages/bot/email/EmailPerformance/EmailPerformancePage'
import Form from 'classes/forms'
import { NewFormModal } from './NewFormModal'
import { filterOptions } from './BotFilters'
import { ShareContext } from 'share-context'
import { components, SessionContext } from 'session-context'
import AccessControl from 'components/AccessControl'

function BotListPage (props: any) {
  const history = props.history
  const action = {
    label: 'New Bot',
    action: () => history.push('/bots/new')
  }

  const filters = [[
    { field: 'kind', operator: 'ne' as FilterComp, value: 'form' }
  ]]

  return (
    <AccessControl requiredComponent={components.CHAT}>
      <>
        <ActionDiv action={action} />
        <ListPage
          objectClass={Bot}
          filters={filters}
          title='Chatbots'
          pageType='chatbots'
          history={props.history}
          warning='Are you sure you want to delete this bot?'
          filterOptions={filterOptions}
        />
      </>
    </AccessControl>
  )
}

function BotSequencePage (props: any) {
  const history = props.history
  const action = {
    label: 'New Sequence',
    action: () => history.push('/sequences/new/builder')
  }

  return (
    <AccessControl requiredComponent={components.CHAT}>
      <>
        <ActionDiv action={action} />
        <ListPage
          objectClass={BotSequence}
          title='Sequences'
          pageType='sequences'
          history={props.history}
          warning='WARNING: This action could impact any bots that are using this sequence. Are you sure you want to delete this sequence?'
        />
      </>
    </AccessControl>
  )
}

function PlayListPage (props: any) {
  const [modalOpen, setModalOpen] = useState(false)

  const filters = [[
    { field: 'object_type', operator: 'eq' as FilterComp, value: 'plays' }
  ]]

  const action = {
    label: 'New Play',
    action: () => setModalOpen(true)
  }

  return (
    <AccessControl requiredComponent={components.PLAYMAKER}>
      <div style={{ height: '100%' }}>
        <ActionDiv action={action} />
        <ListPage
          objectClass={Sequence}
          filters={filters}
          title='Plays'
          pageType='playrunner'
          history={props.history}
          warning='Are you sure you want to delete this play? Make sure it is not being used in PlayMaker.'
        />
        <NewPlayModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          history={props.history}
        />
      </div>
    </AccessControl>
  )
}

const EmailListPage = (props: any) => {
  const history = props.history
  const { isOn, canAdd } = useContext(SessionContext)
  const emailsOn = isOn(components.EMAILS)
  const canAddEmail = canAdd(components.EMAILS)

  const action = {
    label: 'Add New Email',
    action: () => history.push('/emails/new')
  }

  return (
    <AccessControl requiredComponent={components.EMAILS}>
      <div style={{ height: '100%' }}>
        <ActionDiv
          action={action}
          disabled={!emailsOn || !canAddEmail}
          tooltip={!canAddEmail ? (
            'You\'re at the maximum emails for your plan. Upgrade your plan or delete some emails to create more'
          ) : !emailsOn ? (
            'Emails are not enabled in your plan. Upgrade your plan to enable emails'
          ) : (
            undefined
          )}
        />
        <ListPage
          objectClass={Email}
          title='Emails'
          pageType='emails'
          history={props.history}
          warning='Are you sure you want to delete this email?'
        />
      </div>
    </AccessControl>
  )
}

const FormListPage = (props: any) => {
  const history = props.history
  const [modalOpen, setModalOpen] = useState(false)

  const action = {
    label: 'Create New Form',
    action: () => setModalOpen(true)
  }

  return (
    <AccessControl requiredComponent={components.PLAYMAKER}>
      <div style={{ height: '100%' }}>
        <ActionDiv action={action} />
        <ListPage
          objectClass={Form}
          title='Forms'
          pageType='forms'
          history={props.history}
          warning='Are you sure you want to delete this form?'
        />
        <NewFormModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          history={history}
        />
      </div>
    </AccessControl>
  )
}

export function EngagementManager ({ history }: any) {
  const { flags } = useContext(ShareContext)
  const { isOn } = useContext(SessionContext)
  const emailsOn = isOn(components.EMAILS)
  const chatOn = isOn(components.CHAT)
  const playmakerOn = isOn(components.PLAYMAKER)

  const pages = {}
  if (playmakerOn) {
    pages.playmaker = {
      icon: 'play',
      tabs: {
        dashboard: { component: <PlayDashboard /> },
        plays: { component: <PlayListPage history={history} /> }
      }
    }
  }
  if (chatOn) {
    pages.chatbots = {
      icon: 'chat',
      tabs: {
        chatbots: { component: <BotListPage history={history} /> },
        sequences: { component: <BotSequencePage history={history} /> },
        performance: { component: <BotPerformancePage /> }
        // dashboard: { component: <BotDashboard /> }
      }
    }
  }
  if (emailsOn) {
    pages.emails = {
      icon: 'email',
      tabs: {
        emails: { component: <EmailListPage history={history} /> },
        performance: { component: <EmailPerformancePage /> }
        // dashboard: { component: <EmailDashboard /> }
      }
    }
  }
  if (flags?.forms && playmakerOn) {
    pages.forms = {
      icon: 'factCheck',
      tabs: {
        forms: { component: <FormListPage history={history} /> },
        performance: { component: <FormPerformancePage /> }
      }
    }
  }

  return (
    <div>
      <RouterPage
        pages={pages}
      />
    </div>
  )
}
