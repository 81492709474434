import { useState, useReducer, useContext, useEffect } from 'react'
import AppPage from 'cf-components/AppPage'
import ChatAvailability from './ChatAvailability'
import Appearance from './Appearance'
import { components, SessionContext } from 'session-context'
import { getSettings, saveSettings } from 'api/settings'
import { defaultSettings, getReducer } from './settingsHelpers'
import InstallDomains from './InstallDomains'
import { queryClient } from 'App'

function ChatSettingsPage (props) {
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(false)
  const [state, dispatch] = useReducer(getReducer({ availabiltyTypeColumnName: 'chat_setting', setDisabled }), null)
  const [contentSubdomainDisabled, setContentSubdomainDisabled] = useState(true)
  const { user, isOn } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service

  useEffect(() => {
    getSettings().then(settingsResponse => {
      if ('data' in settingsResponse) {
        dispatch({ type: 'initialize', data: settingsResponse.data })
        if (!settingsResponse.data.attributes.content_subdomain) {
          setContentSubdomainDisabled(false)
        }
      } else {
        dispatch({ type: 'initialize', data: defaultSettings })
        setContentSubdomainDisabled(false)
      }
    })
  }, [])

  function save () {
    setError(false)
    if (state.attributes.chat_setting === 'scheduled' && state.attributes.availabilities < 1) {
      state.attributes.attempt_save = true
      setError(true)
      return false
    } else if (state.attributes.prompt_visibility === 'available' && !state.attributes.in_office_greeting_bot_id) {
      state.attributes.attempt_save = true
      setError(true)
      return false
    } else if (state.attributes.prompt_visibility === 'always' && (!state.attributes.in_office_greeting_bot_id || !state.attributes.out_office_greeting_bot_id)) {
      state.attributes.attempt_save = true
      setError(true)
      return false
    } else {
      saveSettings({ state })
        .then(settingsResponse => {
          if ('data' in settingsResponse) {
            if (settingsResponse.data.attributes.content_subdomain) {
              setContentSubdomainDisabled(true)
            }
            setDisabled(true)
          }
        })
      queryClient.invalidateQueries(['companies'])
      return true
    }
  }

  const saveProps = {
    disabled: disabled,
    save: save,
    saveText: 'Save Settings',
    validPaths: 'chat_settings',
    error: error
  }

  const botParticipantID = state?.relationships.bot_participant.data.id

  document.title = 'Chat Settings | Signals'
  const tabs = []
  if (isOn(components.CHAT)) {
    tabs.push({ name: 'Company Availability', url: '/settings/chat_settings/chat_availability' })
    tabs.push({ name: 'Appearance', url: '/settings/chat_settings/appearance' })
  }
  tabs.push({ name: 'Domains', url: '/settings/chat_settings/domains' })
  const title = isOn(components.CHAT) ? 'Chat Settings' : 'Domain Settings'

  return (
    <AppPage
      title={title}
      tabs={tabs}
      saveProps={saveProps}
      padding={20}
    >
      {isOn(components.CHAT) && (
        <ChatAvailability state={state} dispatch={dispatch} error={error} />
      )}
      {isOn(components.CHAT) && (
        <Appearance state={state} dispatch={dispatch} botParticipantID={botParticipantID} chatServiceUrl={chatServiceUrl} />
      )}
      <InstallDomains state={state} dispatch={dispatch} contentSubdomainDisabled={contentSubdomainDisabled} />
    </AppPage>
  )
}

export default ChatSettingsPage
