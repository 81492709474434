/* eslint-disable @typescript-eslint/naming-convention */
import * as am4core from '@amcharts/amcharts4/core'
import { downloadCSV } from 'helpers/csv'
import { getScoreHistory } from 'api/scores'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import SnoozeIcon from '@material-ui/icons/Snooze'
import { updateAccount } from 'api/account_management'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { makeStyles } from '@material-ui/core/styles'
import { useContext, useEffect, useReducer, useState } from 'react'
import { SessionContext } from 'session-context'
import { SummaryCompanies } from 'classes/summaryCompanies'
import { AppendFilters, parameterizeFilters, SearchParams } from 'classes/queryHelpers'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import FilterView from 'classes/filterView'
import DashboardTabHeader from './DashboardTabHeader'
// import FilterViewModal from './FilterViewModal'
import FilterViewMenu from './FilterViewMenu'
import { ActionsMenuAction } from 'cf-components/ActionsMenu'
import { EmailSubscription, initialState } from 'classes/emailSubscriptions'
import EmailSubscriptionModal from 'library/modals/emailSubscriptionModal/EmailSubscriptionModal'
import { Modal } from 'cf-components/Modal'
import FilterViewModal from './FilterViewModal'
import { Icon } from 'library/materialUI'
import { CompanyCallSummary } from 'classes/companyCallSummary'
import ConnectAndSellLogo from 'img/ConnectAndSellLogo.png'
import { SignalsScoreHistory } from 'cf-components/SignalsScoreHistory'
import { Emitter, EVENT_TYPE } from 'emitter'
import { CSVDownload } from 'classes/csvdownload'
import DateFormat from 'components/DateFormat'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'

const useStyles = makeStyles(theme => ({
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  editButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '5px',
    color: theme.palette.primary.main
  },
  fadeLeft: {
    position: 'absolute',
    height: '100%',
    paddingTop: 13,
    paddingRight: 20,
    background: 'linear-gradient(to left, #f5f5f500 , #f5f5f5ff 60%)',
    cursor: 'pointer'
  },
  fadeRight: {
    position: 'absolute',
    height: '100%',
    right: 0,
    paddingTop: 13,
    paddingLeft: 20,
    background: 'linear-gradient(to right, #ffffff00, #f5f5f5ff 60%)',
    cursor: 'pointer'
  }
}))

interface TabAction {
  type: string
  id: number
  value: FilterView
}

export const CONNECT_AND_SELL_TAB = -7

const tabReducer = (state: Record<number, FilterView>, action: TabAction) => {
  switch (action.type) {
    case 'add':
      return { ...state, [action.id]: action.value }
    case 'remove':
      delete state[action.id]
      return state
    default:
      return state
  }
}
interface DashBoardTabCardProps {
  setPotentialAccountModalOpen: (value: boolean) => void
  setModalDomain: (value: string) => void
  accountAdded: string[]
}
am4core.addLicense('CH213476655')
am4core.useTheme(am4themes_animated)
function AddChart (domain: string, chartID: string): Promise<any> {
  let sparkColor = '#EA422A'
  const div = document.getElementById(chartID)
  if (!div) return Promise.resolve()
  return getScoreHistory(domain, 30)
    .then(sparkData => {
      if (!sparkData || sparkData.length === 0) {
        div.innerHTML = '---'
      }
      if (sparkData.length > 0) {
        if (sparkData[0].score < sparkData[sparkData.length - 1].score) {
          sparkColor = '#08C20B'
        } else if (sparkData[0].score === sparkData[sparkData.length - 1].score) {
          sparkColor = '#808080'
        }
        const chart = am4core.create(chartID, am4charts.XYChart)
        chart.padding(0, 0, 0, 0)
        chart.data = sparkData || []

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
        dateAxis.renderer.grid.template.disabled = true
        dateAxis.renderer.labels.template.disabled = true
        dateAxis.cursorTooltipEnabled = false

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.grid.template.disabled = true
        valueAxis.renderer.baseGrid.disabled = true
        valueAxis.renderer.labels.template.disabled = true

        // Create series
        const series = chart.series.push(new am4charts.LineSeries())
        series.dataFields.valueY = 'score'
        series.dataFields.dateX = 'date'
        series.strokeWidth = 2
        series.tensionX = 0.8
        series.tooltipText = '{valueY}'
        series.stroke = am4core.color(sparkColor || '#FF0000')

        const bullet = series.bullets.push(new am4charts.CircleBullet())
        bullet.circle.opacity = 0
        bullet.circle.propertyFields.opacity = 'opacity'
        bullet.circle.radius = 3
        return chart
      }
    })
}
/**
 * Dashboard tab card handles the display of the tabs on the card by querying
 * the filter views and passing the search params to the GenericTab component.
 * Dragging, dropping, pinning, and unpinning tabs will update the users dashboard
 * tab settings.
 */
const mywindow = window as any
const exportCSV = async (searchParams: SearchParams, exportClass: string) => {
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true })
  const resp = await CSVDownload.download({ type: exportClass, searchParams })
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false })
  const date = new Date().toString()
  const csvType = exportClass.toLowerCase() + 's'
  const filename = 'signals_' + csvType + '_' + DateFormat({ dateToFormat: date, format: 'isoDate' }) + '.csv'
  downloadCSV(filename, resp)
}
const DashboardSkeleton = (classes: any): JSX.Element => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
        <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
        <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
        <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
      </div>
      <SkeletonTableLoader
        rowCount={7}
        types={['squareAvatar&Label', 'name&Subtitle', 'number', 'smLabel', 'number', 'smLabel', 'number', 'avatar']}
        columns='2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px'
        justifyCenter
      />
    </>
  )
}

export default function DashboardTabCard ({ setPotentialAccountModalOpen, setModalDomain }: DashBoardTabCardProps) {
  const classes = useStyles()
  const { userSettings, user, updateSettings } = useContext(SessionContext)
  const dashboardTabSettings = userSettings?.dashboard_tab?.settings || {}
  const [completed, setCompleted] = useState(false)
  const [tabOrder, setTabOrder] = useState(dashboardTabSettings?.tab_order || [-1, -2, -3, -4])
  const [emailSub, setEmailSub] = useState<EmailSubscription | null>(null)
  const [currScore, setCurrScore] = useState(0)
  const [currDomain, setCurrDomain] = useState('')
  const [scoreAnchorEl, setScoreAnchorEl] = useState<HTMLElement | null>(null)
  const [historyOpen, setHistoryOpen] = useState(true)
  const [snoozeModalOpen, setSnoozeModalOpen] = useState<string>('')
  const handleSnooze = (domain: string) => {
    const snoozeForNinetyDays = new Date()
    snoozeForNinetyDays.setDate(snoozeForNinetyDays.getDate() + 90)
    // eslint-disable-next-line
    updateAccount({ account: { id: domain, attributes: { snoozed_until_timestamp: snoozeForNinetyDays } } }).then(() => {
      if (mywindow.snoozeRow) {
        console.log('swapping row', mywindow.snoozeRow)
        mywindow.htmx.swap(
          mywindow.snoozeRow,
          '',
          {
            swapDelay: 1000,
            swapStyle: 'outerHTML'
          })
        mywindow.snoozeRow = null
      }
    })
  }

  // Drag and Drop
  function onDragEnd (result: any) {
    const { source, destination } = result
    const sInd = +source.index
    let destIndex = -1
    if (destination) {
      destIndex = +destination.index
    }
    const dInd = destIndex
    const newOrder = [...tabOrder]
    newOrder.splice(dInd, 0, newOrder.splice(sInd, 1)[0])
    updateSettings({ kind: 'dashboard_tab', type: 'tab_order', settings: newOrder })
    setTabOrder(newOrder)
  }

  // Filter Views
  const { data, isLoading: loadingFilterViews } = FilterView.loadAll()
  const [viewModal, setViewModal] = useState(false)
  const [editView, setEditView] = useState<FilterView | null>(null)
  const [viewMenuAnchorEl, setViewMenuAnchorEl] = useState<HTMLElement | null>(null)

  // Tab Handling
  const [currentTab, setCurrentTab] = useState(dashboardTabSettings?.tab !== undefined ? dashboardTabSettings.tab : tabOrder[0])
  const [tabs, dispatch] = useReducer(tabReducer, {})

  const handleClick = (id: number) => {
    updateSettings({ kind: 'dashboard_tab', type: 'tab', settings: id })
    updateSettings({ kind: 'dashboard_tab', type: 'tab_order', settings: tabOrder })
    setCurrentTab(id)
  }

  const removeLastTab = (view: FilterView) => {
    const newOrder = [...tabOrder]
    newOrder.pop()
    newOrder.push(view.id as number)
    updateSettings({ kind: 'dashboard_tab', type: 'tab_order', settings: newOrder })
    updateSettings({ kind: 'dashboard_tab', type: 'tab', settings: view.id as number })
    dispatch({ type: 'add', id: view.id as number, value: view })
    setTabOrder(newOrder)
    setCurrentTab(view.id as number)
  }

  // Scroll Handling
  const [showLeftScroll, setShowLeftScroll] = useState(false)
  const [showRightScroll, setShowRightScroll] = useState(true)

  function updateScrollButtonVisibility () {
    const container = document.getElementById('dashboard-tabs')
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth - 25
      setShowLeftScroll(container.scrollLeft > 0)
      setShowRightScroll(container.scrollLeft < maxScrollLeft)
    } else {
      setShowLeftScroll(false)
      setShowRightScroll(false)
    }
  }

  const handleScroll = (direction: 'left' | 'right') => {
    const container = document.getElementById('dashboard-tabs')
    if (!container) return

    const scrollAmount = 800
    const currentScroll = container.scrollLeft

    const newScrollPosition = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount

    container.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    })
  }

  const handleTabOrderChange = (tab: number) => {
    let newOrder = [...tabOrder]
    let currTab = tab
    if (tabOrder.includes(tab)) {
      if (tabOrder.length === 1) return
      newOrder = newOrder.filter((t: number) => t !== tab)
      if (tab === currentTab) {
        currTab = newOrder[0]
      } else {
        currTab = currentTab
      }
    } else {
      newOrder.push(tab)
    }
    updateSettings({ kind: 'dashboard_tab', type: 'tab_order', settings: newOrder })
    updateSettings({ kind: 'dashboard_tab', type: 'tab', settings: currTab })
    setTabOrder(newOrder)
    setCurrentTab(currTab)
  }

  const handleAdd = (view: FilterView) => {
    dispatch({ type: 'add', id: view.id as number, value: view })
    handleTabOrderChange(view.id as number)
  }

  const createEmailSub = ({ type, viewID, viewName }: { type: string, viewID: number, viewName: string }) => {
    const newInitialState = Object.assign(initialState, { type, name: viewName, meta: { dashboardFilters: viewID }, cf_recipients: [user.id] })
    setEmailSub(new EmailSubscription(newInitialState))
  }

  useEffect(() => {
    updateScrollButtonVisibility()
  }, [tabs, tabOrder])

  useEffect(() => {
    window.addEventListener('resize', updateScrollButtonVisibility)
    return () => {
      window.removeEventListener('resize', updateScrollButtonVisibility)
    }
  }, [])

  useEffect(() => {
    if (!loadingFilterViews && !completed) {
      for (let i = 0; i < data.list.length; i++) {
        const view = data.list[i]
        dispatch({
          type: 'add',
          id: (view.id as number),
          value: view
        })
      }
      setCompleted(true)
    }
  }, [loadingFilterViews, data.list, tabOrder, completed])

  useEffect(() => {
    if (!tabOrder.includes(currentTab)) {
      setCurrentTab(tabOrder[0])
    }
  }, [tabOrder, currentTab])
  useEffect(() => {
    if (loadingFilterViews || Object.keys(tabs).length === 0) {
      return
    }
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data);
        }
      }
      */
      for (const i of tabOrder) {
        const id = `htmx-table-${i}`
        const d = document.getElementById(id)
        if (!d) {
          console.log('No element found for id:', id)
        } else {
          mywindow.htmx.process(d)
          mywindow._hyperscript.processNode(d)
        }
      }
      mywindow.addEventListener('tableEvent', tableEventListener);
      mywindow.addEventListener('rowEvent', rowEventListener);
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [loadingFilterViews, tabs, currentTab])
  const tableEventListener = (e: any) => {
    console.log('tableEvent', e)
    const tableID = e.detail.tableID
    const tabID = tableID.split('companies-body-').pop()
    const searchParams = tabs[tabID]?.searchParams || {}
    const action = e.detail.action
    const ids: Array<string> = []
    if (tableID.includes('companies-body')) {
      switch (action) {
        case 'filter':
          break
        case 'email':
          createEmailSub({ type: 'filters', viewID: tabID, viewName: tabs[tabID].name })
          break
        case 'export':
          Array.from(mywindow.htmx.findAll(
            mywindow.htmx.find('#' + tableID),
            '.rowcheckbox'
          )).forEach((checkbox: any) => {
            if (checkbox.checked) {
              console.log(checkbox.value)
              ids.push(checkbox.value)
            }
          })
          if (ids.length === 0) {
            exportCSV(searchParams, e.detail.exportType)
          } else {
            const exportParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'cs.domain', value: ids, operator: 'in' }]]) }
            exportCSV(exportParams, e.detail.exportType)
          }
          break
        default:
          console.warn('Unknown table action', action)
      }
    } else {
      console.log('different table event: ', tableID)
    }
  }
  const rowEventListener = (e: any) => {
    console.log('rowEvent', e)
    const tableID = e.detail.tableID
    const rowID = e.detail.rowID
    const rowAction = e.detail.action
    const anchorDiv = document.getElementById(e.detail.anchor)
    if (tableID.includes('companies-body')) {
      switch (rowAction) {
        case 'scoreHoverCancel':
          setHistoryOpen(false)
          break
        case 'scoreHover':
          if (anchorDiv) {
            setScoreAnchorEl(anchorDiv)
            setCurrDomain(rowID)
            setHistoryOpen(true)
            setCurrScore(e.detail.score)
          }
          break
        case 'snooze':
          mywindow.snoozeRow = e.detail.tr
          setSnoozeModalOpen(rowID)
          break
        case 'add':
          setModalDomain(rowID)
          setPotentialAccountModalOpen(true)
          break
        case 'scoreSpark':
          AddChart(rowID, e.detail.chartID)
          break
        default:
          console.warn('Unknown row action', rowAction)
      }
    } else {
      console.log('different table event: ', tableID)
    }
  }
  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data);
        }
      }
      */
      const ids = ['htmx-table']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
        mywindow._hyperscript.processNode(d)
      }
      mywindow.addEventListener('tableEvent', tableEventListener);
      mywindow.addEventListener('rowEvent', rowEventListener);
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [currDomain])

  if (loadingFilterViews || Object.keys(tabs).length === 0) {
    return (
      <>
        <div style={{ display: 'flex', padding: 10 }}>
          <div className={classes.tabPlaceholder}> <SkeletonLabel size='full' /> </div>
          <div className={classes.tabPlaceholder}> <SkeletonLabel size='full' /> </div>
          <div className={classes.tabPlaceholder}> <SkeletonLabel size='full' /> </div>
          <div className={classes.tabPlaceholder}> <SkeletonLabel size='full' /> </div>
        </div>
        <div style={{ height: 645, border: '1px solid #e0e0e0', borderRadius: 6, backgroundColor: 'white' }}>
          {DashboardSkeleton(classes)}
        </div>
      </>
    )
  }

  const csTabActions: ActionsMenuAction[] = [
    {
      name: 'Unpin View',
      action: (viewID: number) => handleTabOrderChange(viewID),
      icon: 'pin',
      color: '#9933FF',
      disabled: tabOrder.length === 1,
      tooltip: tabOrder.length === 1 ? 'You must have at least one view pinned' : ''
    }
  ]
  const tabActions: ActionsMenuAction[] = [
    {
      name: 'Edit View',
      action: (viewID: number) => {
        setEditView(tabs[viewID])
        setViewModal(true)
      },
      icon: 'edit',
      color: '#9933FF'
    },
    {
      name: 'Create Email Update',
      action: (viewID: number) => createEmailSub({ type: 'filters', viewID, viewName: tabs[viewID].name }),
      icon: 'createEmailUpdate',
      color: '#9933FF'
    },
    {
      name: 'Unpin View',
      action: (viewID: number) => handleTabOrderChange(viewID),
      icon: 'pin',
      color: '#9933FF',
      disabled: tabOrder.length === 1,
      tooltip: tabOrder.length === 1 ? 'You must have at least one view pinned' : ''
    }
  ]

  const defaultTabActions: ActionsMenuAction[] = [
    {
      name: 'Duplicate View',
      action: (viewID: number) => {
        setEditView(tabs[viewID].copy())
        setViewModal(true)
      },
      icon: 'clone',
      color: '#9933FF'
    },
    {
      name: 'Create Email Update',
      action: (viewID: number) => createEmailSub({ type: 'filters', viewID, viewName: tabs[viewID].name }),
      icon: 'createEmailUpdate',
      color: '#9933FF'
    },
    {
      name: 'Unpin View',
      action: (viewID: number) => handleTabOrderChange(viewID),
      icon: 'pin',
      color: '#9933FF',
      disabled: tabOrder.length === 1,
      tooltip: tabOrder.length === 1 ? 'You must have at least one view pinned' : ''
    }
  ]

  return (
    <div style={{ maxHeight: 736 }}>
      {/* Header: */}
      <div className={classes.headerContainer}>
        {showLeftScroll && (
          <div className={classes.fadeLeft}>
            <Icon icon='leftArrow' color='inherit' onClick={() => handleScroll('left')} />
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='1' direction='horizontal'>
            {(dropProvided) => (
              <div
                id='dashboard-tabs'
                className={classes.tabsContainer}
                ref={dropProvided.innerRef}
                {...dropProvided.droppableProps}
                onScroll={() => {
                  updateScrollButtonVisibility()
                }}
              >
                {tabOrder.filter((tid: number) => tabs[tid]).map((tabID: number, index: number) => {
                  return (
                    <Draggable
                      key={tabID}
                      draggableId={tabID.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <DashboardTabHeader
                          viewID={tabID}
                          title={tabID === CONNECT_AND_SELL_TAB ? <img src={ConnectAndSellLogo} alt='ConnectAndSell' style={{ height: 20 }} /> : tabs[tabID].name}
                          tooltipText={tabs[tabID].description}
                          index={index}
                          filters={tabs[tabID].searchParams.filters || []}
                          currentTab={currentTab}
                          handleClick={handleClick}
                          provided={provided}
                          snapshot={snapshot}
                          objectClass={tabID === CONNECT_AND_SELL_TAB ? CompanyCallSummary : SummaryCompanies}
                          actions={tabID !== CONNECT_AND_SELL_TAB ? (tabID > 0 ? tabActions : defaultTabActions) : csTabActions}
                        />
                      )}
                    </Draggable>
                  )
                })}
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {showRightScroll && (
          <div style={{ position: 'relative', marginRight: 10 }}>
            <div className={classes.fadeRight}>
              <Icon icon='rightArrow' color='inherit' onClick={() => handleScroll('right')} />
            </div>
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <div
            id='add_view_button'
            className={classes.editButton}
            onClick={() => {
              setEditView(null)
              setViewModal(true)
            }}
          >
            <Icon icon='add' color='inherit' />
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              Create View
            </div>
          </div>
          <div
            id='edit_views_button'
            className={classes.editButton}
            style={{ marginLeft: 30 }}
            onClick={(event) => setViewMenuAnchorEl(event.currentTarget)}
          >
            <Icon icon='edit' size='sm' color='inherit' />
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              Edit views
            </div>
          </div>
        </div>
      </div>
      {/* Body:  */}
      {tabOrder.map((tabID: number) => {
        const tableID = `htmx-table-${tabID}`
        const innerID = `summaries-${tabID}`
        const curSearchParams = tabs[tabID]?.searchParams || {}
        const url = parameterizeFilters({ path: `/api/core/web/companies/summaries/${tabID}`, searchParams: curSearchParams })
        return (
          <div
            key={tableID}
            id={tableID}
            style={{
              backgroundColor: 'white',
              display: tabID === currentTab ? 'block' : 'none',
              visibility: tabID === currentTab ? 'visible' : 'hidden',
              minHeight: 674,
              border: '1px solid #e0e0e0',
              borderRadius: 10,
              borderTopLeftRadius: 0,
              paddingBottom: 10,
              paddingTop: 10
            }}
          >
            <div
              id={innerID}
              hx-get={url}
              hx-trigger='revealed'
            >
              <div style={{ height: 645 }}>
                {DashboardSkeleton(classes)}
              </div>
            </div>
          </div>
        )
      })}
      {currDomain && (
        <SignalsScoreHistory
          score={currScore}
          placement='left'
          domain={currDomain}
          anchorEl={scoreAnchorEl}
          open={historyOpen}
        />
      )}
      <FilterViewMenu
        anchorEl={viewMenuAnchorEl}
        setAnchorEl={setViewMenuAnchorEl}
        filterViews={data.list}
        tabOrder={tabOrder}
        handleTabOrder={handleTabOrderChange}
        createEmailSub={createEmailSub}
        editView={(view: FilterView) => {
          setEditView(view)
          setViewModal(true)
        }}
      />
      {emailSub &&
        <EmailSubscriptionModal
          emailSubscription={emailSub}
          onClose={() => setEmailSub(null)}
        />}
      <Modal
        title='Confirm Snoozing Domain'
        open={snoozeModalOpen !== ''}
        onHide={() => setSnoozeModalOpen('')}
        rightButtonProps={{
          text: 'Snooze',
          action: () => {
            handleSnooze(snoozeModalOpen)
            setSnoozeModalOpen('')
          },
          icon: SnoozeIcon
        }}
        leftButtonProps={{
          text: 'Cancel',
          action: () => setSnoozeModalOpen('')
        }}
      >
        Are you sure you would like to snooze <b>{snoozeModalOpen}</b> for 90 days?
      </Modal>
      <FilterViewModal
        openModal={viewModal}
        setOpenModal={() => {
          setEditView(null)
          setViewModal(false)
        }}
        currentView={editView}
        updateViews={(view: FilterView) => {
          if (tabOrder.length < 5 && !tabOrder.includes(view.id as number)) {
            handleAdd(view)
          } else if (tabOrder.length >= 5 && !tabOrder.includes(view.id as number)) {
            removeLastTab(view)
          }
        }}
      />
    </div>
  )
}
