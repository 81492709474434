import { useReducer } from 'react'
import { Modal, ModalSection } from 'library/Modal'
import { TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import Picker from 'cf-components/Pickers'
import { Channel } from 'classes/channels'

const reducer = (state: Channel, changes: any): Channel => {
  return { ...state, ...changes }
}

export function ChannelModal (props: any): JSX.Element {
  const [state, dispatch] = useReducer(reducer, props.channel)

  const saveChannel = (): void => {
    Channel.save(state).then(() => props.onHide())
  }

  return (
    <Modal
      open
      title='Create Channel'
      onHide={props.onHide}
      size='sm'
      handleSave={saveChannel}
      saveIcon='add'
      saveDisabled={!state.name}
      saveBtnText='Create Channel'
    >
      <ModalSection
        title='Channel Details'
        subtitle='Enter a name and description for your channel.'
      >
        <div>
          <TextBox
            value={state.name}
            onChange={(value: string) => dispatch({ name: value })}
            label='Name'
          />
          <div style={{ marginTop: 15 }}>
            <TextBox
              value={state.description}
              onChange={(value: string) => dispatch({ description: value })}
              rows={3}
              label='Description'
            />
          </div>
        </div>
      </ModalSection>
      <ModalSection
        title='Bots'
        subtitle='Select the bots that will be added to this channel.'
      >
        <div>
          <Picker
            selection={state.botIDs}
            setSelection={(value: any) => dispatch({ botIDs: value })}
            placeholder='Select bots'
            objectType='bots'
            multiple
          />
        </div>
      </ModalSection>
      <ModalSection
        title='Teams and Agents'
        subtitle='Select the teams and agents that can view this channel.'
      >
        <div>
          <Picker
            selection={state.teamIDs}
            setSelection={(value: any) => dispatch({ teamIDs: value })}
            objectType='teams'
            placeholder='Select teams'
            multiple
          />
          <div style={{ marginTop: 20 }}>
            <Picker
              selection={state.userIDs}
              setSelection={(value: any) => dispatch({ userIDs: value })}
              objectType='users'
              placeholder='Select agents'
              multiple
            />
          </div>
        </div>
      </ModalSection>
    </Modal>
  )
}
