import React, { useEffect, useRef, useState } from 'react'
import { createROIDaysChart } from './charts/CreateROIDaysChart'
import Loader from 'library/loading/Loader'

function ROICloseDaysChart ({ chatFunnelsDays, otherDays, div_id }) {
  const chartRef = useRef(null)
  const [visible, setVisible] = useState('hidden')

  const loaderStyles = {
    inlineSize: 'max-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 650,
    height: 360,
    iconSize: 30
  }

  useEffect(() => {
    const chart = createROIDaysChart({
      chatFunnelsDays,
      otherDays,
      labels: [],
      id: div_id || 'roi_chart_simple_div',
      setVisible
    })
    chartRef.current = chart
  }, [chatFunnelsDays, div_id, otherDays])

  return (
    <>
      <div
        style={{
          display: visible === 'visible' ? 'none' : 'block',
          ...loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 312, visibility: visible }}>
        <div id={div_id || 'roi_chart_simple_div'} style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

export default ROICloseDaysChart
