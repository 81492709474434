import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { format, getDay, subDays, parseISO } from 'date-fns'

function getWeekString (dateString) {
  const date = parseISO(dateString)
  const day = getDay(date)
  const weekStartDate = subDays(date, day)
  return format(weekStartDate, 'MM/dd/yy')
}

function createROIChart ({ rows, labels, id, setVisible, seriesGroupName }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.XYChart)

  chart.legend = new am4charts.Legend()
  chart.legend.position = 'top'
  chart.legend.paddingBottom = 20
  chart.legend.labels.template.maxWidth = 160

  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  xAxis.dataFields.category = 'date'
  xAxis.renderer.cellStartLocation = 0.1
  xAxis.renderer.cellEndLocation = 0.9
  xAxis.renderer.grid.template.location = 0

  const yAxis = chart.yAxes.push(new am4charts.ValueAxis())
  yAxis.numberFormatter.numberFormat = '$#,##0'
  yAxis.min = 0

  function createSeries (value, name) {
    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'date'
    series.name = name
    series.stacked = true
    series.columns.template.tooltipHTML = '<span style="color: #ffffff;">{valueY}</span>'
    return series
  }

  if (!seriesGroupName) {
    seriesGroupName = 'OpportunityStageName'
  }

  const oppSeries = Object.keys(rows.reduce((accumulator, row) => {
    accumulator[row[seriesGroupName]] = true
    return accumulator
  }, {}))

  for (let i = 0; i < oppSeries.length; i++) {
    createSeries(oppSeries[i], oppSeries[i])
  }

  // group the rows by their "week"

  // sum the totals for each category

  const sortedRows = rows.sort((a, b) => {
    return parseISO(a.OpportunityCreatedDate) > parseISO(b.OpportunityCreatedDate)
  })

  const weekMap = {}
  const weekGroups = {}
  const weeks = []

  sortedRows.forEach(i => {
    const weekString = getWeekString(i.OpportunityCreatedDate)
    if (!weekMap[weekString]) {
      weekMap[weekString] = true
      const r = {
        date: weekString
      }
      for (let i = 0; i < oppSeries.length; i++) {
        r[oppSeries[i]] = 0
      }
      weekGroups[weekString] = r
      weeks.push(weekString)
    }
    // add the amount into the appropriate group for a summary count
    weekGroups[weekString][i[seriesGroupName]] += i.OpportunityAmount
  })

  const data = weeks.map(weekString => {
    const weekGroup = weekGroups[weekString]
    const newWeekGroup = {}
    oppSeries.forEach(i => {
      const j = weekGroup[i]
      if (j) {
        newWeekGroup[i] = j
      }
      newWeekGroup.date = weekGroup.date
    })
    return newWeekGroup
  })

  chart.data = data

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  return chart
}

export { createROIChart }
