import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  typographyMoney: {
    marginLeft: 10,
    marginTop: 4
  }
}))

/**
 * Create a dollar value subeader
 * @param {{totalAmount: number | string; preventFormatting?: boolean;}} props
 */
export function MoneySubheader (props) {
  const classes = useStyles()
  const preventFormatting = props.preventFormatting || (typeof props.totalAmount === 'string')
  const totalAmount = preventFormatting ? props.totalAmount : currency(props.totalAmount)

  if (!totalAmount) {
    return <></>
  }

  return (
    <Typography variant='h4' className={classes.typographyMoney}>
      {totalAmount}
    </Typography>
  )
}

/** Format currency into a $123,456.78 with specified fraction digits */
export function currency (number, maximumFractionDigits = 0) {
  if (!number) {
    return ''
  }
  const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits }).format(number)
  return formatted
}
