import React, { useState, useEffect, useCallback } from 'react'
import { getRawROIReport, getROIOppSummaryReport } from 'api/reports'
import { FilterBar, convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ROIChartSimple from './SalesforceROIChartSimple'
import ROICloseDaysChart from './ROICloseDaysChart'
import AppPage from 'cf-components/AppPage'
import { ListItemCard } from 'cf-components/CardList'
import Typography from '@material-ui/core/Typography'
import {
  subDays,
  parseISO,
  differenceInDays
} from 'date-fns'
import { currency, MoneySubheader } from './FormatHelpers'

const useStyles = makeStyles(theme => ({
  card: {
    height: 430,
    border: '1px solid rgba(142,90,226,0.5)',
    position: 'relative'
  },
  cardContent: {
    height: 312,
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'center'
  },
  filterDiv: {
    display: 'flex',
    marginBottom: 15,
    height: 47,
    justifyContent: 'space-between'
  },
  select: {
    width: 320
  },
  customDates: {
    marginLeft: 30,
    marginTop: -15
  },
  cardHeader: {
    paddingBottom: 0
  },
  dashboard: {},
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20,
    padding: '0px 20px 20px 20px'
  },
  graphContentAbsoluteBottom: {
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  graphContent: {
    width: '100%'
  },
  typographyMoney: {
    marginLeft: 10,
    marginTop: 4
  },
  oppCard: {
    padding: '12px 20px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto',
    gridGap: 10
  },
  graphBox: {
    flexGrow: 1,
    minWidth: '300px',
    maxWidth: '800px'
  },
  percentAbsoluteText: {
    position: 'absolute',
    right: 20,
    top: 20,
    width: 100,
    textAlign: 'right',
    color: '#000000'
  }
}))

export const startingDateRange2 = {
  startDate: subDays(new Date(), 365),
  endDate: subDays(new Date(), 1),
  key: 'selection'
}

const SalesforceROIOverview = props => {
  const filterFunction = props.filterFunction
  const label = props.label || 'Pipeline influenced by Signals'
  const [dateRange, setDateRange] = useState(startingDateRange2)
  const [reportResponse, setReportResponse] = useState(null)
  const [oppSummaryResponse, setOppSummaryResponse] = useState(null)
  const convertedRange = convertDateRange(dateRange)
  const start = convertedRange?.start_date
  const end = convertedRange?.end_date

  const classes = useStyles()

  const loadReport = useCallback(() => {
    getRawROIReport()
      .then(response => {
        const flat = response.data.map(i => {
          const data = i.attributes
          return data
        })
        setReportResponse(flat)
      })

    getROIOppSummaryReport({ start, end })
      .then(response => {
        setOppSummaryResponse(response.data.attributes.opp_summary)
      })
  }, [end, start])

  // TODO: make this into a memo maybe?
  let filteredReportResponse = null
  if (filterFunction && reportResponse) {
    filteredReportResponse = reportResponse.filter(filterFunction)
  } else {
    filteredReportResponse = reportResponse
  }

  if (filteredReportResponse?.length && dateRange?.startDate && dateRange?.endDate) {
    filteredReportResponse = filteredReportResponse.filter((row) => {
      const oppDate = parseISO(row.OpportunityCreatedDate)
      if (oppDate < dateRange.startDate) {
        return false
      } else if (oppDate > dateRange.endDate) {
        return false
      }
      return true
    })
  }

  useEffect(() => {
    loadReport()
  }, [loadReport])

  const rows = filteredReportResponse

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange
  }

  return (
    <AppPage
      title='ROI Overview'
      padding={0}
    >
      <div>
        <FilterBar
          filterOptions={filterOptions}
        />

        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: 20,
          margin: 20
        }}
        >
          <div className={classes.graphBox}>
            <SalesforceROIGraphSimple
              rows={rows}
              label={label}
              oppSummary={oppSummaryResponse}
            />
          </div>
          <div className={classes.graphBox}>
            <TopOpps rows={rows} />
          </div>
          <div className={classes.graphBox}>
            <SalesCycle rows={rows} oppSummary={oppSummaryResponse} />
          </div>
          <div className={classes.graphBox}>
            <ClosedWon rows={rows} oppSummary={oppSummaryResponse} />
          </div>
          <div className={classes.graphBox}>
            <TopDeals rows={rows} />
          </div>
        </div>
      </div>
    </AppPage>
  )
}

function TopOppCard ({ opp }) {
  const classes = useStyles()

  return (
    <ListItemCard>
      <div className={classes.oppCard}>
        <div>{opp.OpportunityName}</div>
        <div>{opp.OpportunityType}</div>
        <div>{currency(opp.OpportunityAmount, 2)}</div>
      </div>
    </ListItemCard>
  )
}

const arrAvg = arr => {
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

function SalesCycle ({ rows }) {
  const classes = useStyles()
  if (!rows) {
    return (<></>)
  }
  let rowsCopy = [...rows]
  rowsCopy = rowsCopy.filter(i => i.OpportunityIsWon && i.OpportunityIsClosed)

  const avgDays = +arrAvg(rowsCopy.map(i => {
    const closeDate = parseISO(i.OpportunityCloseDate)
    const createdDate = parseISO(i.OpportunityCreatedDate)
    const days = differenceInDays(closeDate, createdDate)
    return days
  })).toFixed(2)

  return (
    <Card className={classes.card}>
      <CardHeader
        title='Sales Cycle'
        subheader='How fast are deals influenced by Signals closing?'
        className={classes.cardHeader}
      />
      <Typography variant='h4' className={classes.typographyMoney}>{avgDays ? avgDays + ' Days' : ''}</Typography>
      <div className={classes.cardContent} style={{ paddingTop: 0 }}>
        <div style={{ width: '500px' }}>
          <div className={classes.graphContent}>
            <ROICloseDaysChart
              chatFunnelsDays={avgDays}
              otherDays={0}
              div_id='sf_roi_close_days_avg_chart'
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

function ClosedWon ({ rows, label, oppSummary }) {
  const classes = useStyles()

  if (!rows) {
    return (
      <></>
    )
  }
  const rowsCopy = rows.filter(i => i.OpportunityIsWon)

  const totalAmount = rowsCopy && rowsCopy.reduce((p, c) => p + c.OpportunityAmount, 0)
  let allOppWonSum = 0

  if (!oppSummary) {
    return (<></>)
  }

  const oppWonSummary = oppSummary.filter(i => i.StageName === 'Closed Won')

  allOppWonSum = oppWonSummary && oppWonSummary.reduce((p, c) => p + c.Amount, 0)

  let percentOfAllOpps
  let otherAmount = 0
  if (allOppWonSum) {
    percentOfAllOpps = (totalAmount / allOppWonSum) * 100
    otherAmount = allOppWonSum - totalAmount
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title={'Closed Won: ' + (rowsCopy ? rowsCopy.length : '0') + ' Opportunities'}
        subheader='How much closed won has Signals influenced?'
        className={classes.cardHeader}
      />
      <MoneySubheader totalAmount={totalAmount} />
      <CardContent className={classes.cardContent}>
        <div className={classes.graphContentAbsoluteBottom}>
          {rowsCopy && (
            <ROIChartSimple otherAmount={otherAmount} chatFunnelsAmount={currency(totalAmount, 2)} div_id='roi_overview_closed_one_pie' />
          )}
        </div>
        {percentOfAllOpps ? (
          <div className={classes.cardContent}>
            <div className={classes.percentAbsoluteText}>
              <div>{Math.floor(percentOfAllOpps)}% of total won</div>
            </div>
          </div>
        ) : <></>}
      </CardContent>

    </Card>
  )
}

function TopOpps ({ rows }) {
  const classes = useStyles()
  if (!rows) {
    return (<></>)
  }
  let rowsCopy = [...rows]
  rowsCopy.sort((a, b) => {
    return a.OpportunityAmount > b.OpportunityAmount
  })
  rowsCopy = rowsCopy.filter(i => !i.OpportunityIsClosed)
  const topOpps = rowsCopy.slice(0, 4)
  return (
    <Card className={classes.card}>
      <CardHeader
        title='Top Opportunities'
        subheader='Which of your Opportunities has Signals influenced?'
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.graphContent}>
          {topOpps && (
            topOpps.map(i => {
              return <TopOppCard key={i.OpportunityId} opp={i} />
            })
          )}

        </div>
      </CardContent>
    </Card>
  )
}

function TopDeals ({ rows }) {
  const classes = useStyles()
  if (!rows) {
    return (<></>)
  }
  let rowsCopy = [...rows]
  rowsCopy.sort((a, b) => {
    return a.OpportunityAmount > b.OpportunityAmount
  })
  rowsCopy = rowsCopy.filter(i => i.OpportunityIsClosed)
  const topOpps = rowsCopy.slice(0, 4)
  return (
    <Card className={classes.card}>
      <CardHeader
        title='Top Deals'
        subheader='Which of your top deals has Signals influenced?'
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.graphContent}>
          {topOpps && (
            topOpps.map(i => {
              return <TopOppCard key={i.OpportunityId} opp={i} />
            })
          )}

        </div>
      </CardContent>
    </Card>
  )
}

function SalesforceROIGraphSimple ({ rows, label, oppSummary }) {
  const classes = useStyles()
  const totalAmount = rows && rows.reduce((p, c) => p + c.OpportunityAmount, 0)
  let allOppSum = 0

  if (!oppSummary) {
    return (<></>)
  }

  allOppSum = oppSummary.reduce((previous, current) => {
    return previous + current.Amount
  }, 0)

  let percentOfAllOpps = 0
  let otherAmount = 0
  if (allOppSum) {
    percentOfAllOpps = (totalAmount / allOppSum) * 100
    otherAmount = allOppSum - totalAmount
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title={'Pipeline: ' + (rows ? rows.length : '0') + ' Opportunities'}
        subheader='How much pipeline has Signals influenced?'
        className={classes.cardHeader}
      />
      <MoneySubheader totalAmount={totalAmount} />
      <CardContent className={classes.cardContent}>
        <div className={classes.graphContentAbsoluteBottom}>
          {rows && (
            <ROIChartSimple otherAmount={otherAmount} chatFunnelsAmount={totalAmount} />
          )}
        </div>
        {percentOfAllOpps ? (
          <div className={classes.percentAbsoluteText}>
            <div>{Math.floor(percentOfAllOpps)}% of total pipeline</div>
          </div>
        ) : <></>}
      </CardContent>

    </Card>
  )
}

export default SalesforceROIOverview
