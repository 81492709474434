import React, { useState, useRef, useContext, useEffect } from 'react'
import { SessionContext } from 'session-context'
import { makeStyles } from '@material-ui/core/styles'
import AppPage from 'cf-components/AppPage'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import {
  createSequenceFlow,
  getSequenceTemplate,
  getSequenceFlow,
  saveSequenceTemplate,
  getSequenceOverview
} from 'api/sequences'
import { getBots } from 'api/bots'
import SequenceEditor from '../flow-builder/sequences/SequenceEditor'
import { TopMenuBar } from '../flow-builder/sequences/MenuBar'
import { VersionHistoryModal } from '../flow-builder/surface/TopMenuBar'
import SequenceAdvancedPage from './SequenceAdvancedPage'
import SequenceBotList from './SequenceBotList'
import { useHistory } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  itemActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#81AF20',
    fontWeight: 0,
    fontSize: '12px',
    marginLeft: 10,
    backgroundColor: '#E6EFD2',
    borderRadius: 5,
    padding: 5,
    cursor: 'default'
  },
  itemInactive: {
    display: 'flex',
    alignItems: 'center',
    color: '#868686',
    fontWeight: 0,
    marginLeft: 10,
    fontSize: '12px',
    backgroundColor: '#E5E5E5',
    borderRadius: 5,
    padding: 5,
    cursor: 'default'
  }
}))

const defaultSequence = {
  type: 'sequences',
  attributes: {
    description: '',
    name: 'New Sequence',
    ports: []
  }
}

const defaultFlow = {
  data: {
    edges: [],
    nodes: [{
      id: 'start',
      ports: [
        { id: 'default' }
      ],
      type: 'start'
    },
    {
      id: 'output',
      nextOutputID: 2,
      type: 'sequence_output',
      ports: [
        { id: 'exit1', label: 'Exit to Bot' }
      ]
    }
    ]
  }
}

const defaultOverview = {
  attributes: {
    last_fired: null,
    most_frequent_bot_id: null,
    sequence_fires_all_time: 0,
    sequence_fires_this_week: 0
  }
}

function SequenceEditPage (props) {
  const classes = useStyles()
  const dataRef = useRef(null)
  const toolkitInstanceRef = useRef(null)
  const restoreVersionRef = useRef(null)
  const saveRef = useRef(null)
  const [sequenceID, setSequenceID] = useState(props.match.params.sequence_id === 'new' ? null : parseInt(props.match.params.sequence_id))
  const [sequenceFlow, setSequenceFlow] = useState(null)
  const [sequence, setSequence] = useState(null)
  const [bots, setBots] = useState(null)
  const [canSave, setCanSave] = useState(false)
  const [versionModalOpen, setVersionModalOpen] = useState(false)
  const [overview, setOverview] = useState(null)
  const [botStats, setBotStats] = useState(null)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your sequence has been saved'
  })
  const { user } = useContext(SessionContext)
  const history = useHistory()
  const chatServiceUrl = user.links.chat_service
  const baseURL = sequenceID ? `/sequences/${sequenceID}/` : '/sequences/new/'
  window.sequenceID = sequenceID
  window.chatServiceUrl = chatServiceUrl
  window.sequenceNode = null

  useEffect(() => {
    if (sequenceID) {
      getSequenceOverview({ sequenceID })
        .then(response => {
          setOverview(response.data)
          let dict = {}
          response.data.attributes.bot_stats.map(
            bot => (dict = { ...dict, [bot.id]: { ...bot } })
          )
          setBotStats(dict)
        })
    } else {
      setOverview(defaultOverview)
      setBotStats({})
    }
  }, [sequenceID])

  useEffect(() => {
    if (sequenceID) {
      getSequenceTemplate({ sequenceID })
        .then(response => {
          setSequence(response.data)
          const sequenceFlowID = response.data.attributes.sequence_flow_id
          getSequenceFlow({ sequenceFlowID })
            .then(response => {
              setSequenceFlow(response.attributes.flow)
            })
        })
      getBots({ sequenceID })
        .then(response => {
          setBots(response.data)
        })
    } else if (!sequenceID) {
      setSequence(defaultSequence)
      setSequenceFlow(defaultFlow)
      setBots([])
    }
  }, [sequenceID])

  function restoreFlow (flowID) {
    getSequenceFlow({ sequenceFlowID: flowID })
      .then(response => {
        setSequenceFlow(response.attributes.flow)
        const nodes = response.attributes.flow.data.nodes
        const output = nodes.filter(n => n.id === 'output')[0]
        const ports = output.ports.map(p => ({ id: p.id, label: p.label }))
        const sequenceAttributes = sequence?.attributes
        saveSequenceTemplate({
          flowID,
          ports,
          title: sequenceAttributes.name,
          description: sequenceAttributes.description,
          sequenceID
        }).then((response) => {
          setSequence(response.data)
          restoreVersionRef.current()
          setCanSave(false)
        })
      })
  }

  function restoreVersion (flowID) {
    setVersionModalOpen(false)
    restoreFlow(flowID)
  }

  function saveFlow () {
    const flow = {
      data: {
        edges: dataRef.current.data.edges,
        nodes: dataRef.current.data.nodes
      }
    }
    const ports = dataRef.current.data.nodes.filter(node => node.id === 'output')[0].ports.map(p => ({ id: p.id, label: p.label }))
    createSequenceFlow({
      flow,
      sequenceID,
      track: true,
      title: sequence.attributes.name,
      description: sequence.attributes.description,
      ports
    })
      .then(response => {
        const newSequenceID = response.data.relationships.belongs_to.data.id
        if (!sequenceID) {
          const currLocation = window.location.hash
          const redirectLocation = currLocation.replace('#', '').replace('new', newSequenceID)
          history.push({
            pathname: redirectLocation
          })
        }
        setSequenceID(newSequenceID)
        setCanSave(false)
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your sequence has been saved'
        })
      })
  }

  function saveName (title) {
    if (sequenceID) {
      saveSequenceTemplate({ title, sequenceID })
        .then(response => {
          setSequence(response.data)
        })
    } else {
      setSequence(prevState => ({
        ...prevState,
        attributes: {
          ...prevState.attributes,
          name: title
        }
      }))
    }
  }

  function saveDescription (description) {
    if (sequenceID) {
      saveSequenceTemplate({ description, sequenceID })
        .then(response => {
          setSequence(response.data)
        })
    } else {
      setSequence(prevState => ({
        ...prevState,
        attributes: {
          ...prevState.attributes,
          description: description
        }
      }))
    }
  }

  const breadcrumbs = [
    { link: '/engagement/chatbots/sequences', text: 'Sequence Roster' },
    { link: null, text: 'Sequence Configuration' }
  ]
  let activeDiv = <></>
  if (!sequence) {
    activeDiv = <></>
  } else if (sequence?.attributes?.is_active) {
    activeDiv = <Tooltip title='This sequence is being used in a bot. If you want to deactivate it, remove it from all active bots'><div className={classes.itemActive}>Active</div></Tooltip>
  } else if (!sequence?.attributes?.is_active) {
    activeDiv = <Tooltip title='This sequence is not being used in a bot. To activate it, create a new bot and add the sequence to it or add the sequence to an existing bot'><div className={classes.itemInactive}>Inactive</div></Tooltip>
  }

  return (
    <>
      <AppPage
        title={sequence?.attributes.name || ''}
        saveName={saveName}
        breadcrumbs={breadcrumbs}
        editableTitle={sequence !== null}
        tabs={[
          { name: 'Builder', url: baseURL + 'builder' },
          { name: 'Details', url: baseURL + 'details' },
          { name: 'Bots', url: baseURL + 'bots' }
        ]}
        alwaysLoaded={[0]}
        extraTitleInfo={activeDiv}
      >
        <div style={{ height: 'calc(100vh - 119px)', position: 'relative' }}>
          <div style={{ height: '100%' }}>
            <TopMenuBar
              initiateSave={saveRef.current}
              saveDisabled={!canSave}
              showVersionHistory={() => setVersionModalOpen(true)}
            />
            <div style={{ height: 'calc(100% - 38px)' }}>
              <SequenceEditor
                sequenceFlow={sequenceFlow}
                setCanSave={setCanSave}
                dataRef={dataRef}
                restoreVersionRef={restoreVersionRef}
                toolkitInstanceRef={toolkitInstanceRef}
                save={saveFlow}
                saveRef={saveRef}
              />
            </div>
          </div>
        </div>
        <SequenceAdvancedPage
          bots={bots}
          sequenceID={sequenceID}
          sequence={sequence}
          saveDescription={saveDescription}
          overview={overview}
        />
        <SequenceBotList
          bots={bots}
          botStats={botStats}
        />
      </AppPage>
      <VersionHistoryModal
        open={versionModalOpen}
        onHide={() => setVersionModalOpen(false)}
        sequenceID={sequenceID}
        loadFlow={restoreVersion}
        currentFlowID={sequence?.attributes.sequence_flow_id}
      />
      <CustomizedSnackbar
        state={snackState}
        handler={setSnackState}
      />
    </>
  )
}

export default SequenceEditPage
