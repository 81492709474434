/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useContext, useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, FormControl, Select, MenuItem, InputLabel, FormControlLabel, SelectProps, Typography } from '@material-ui/core'
import { Formik, Field } from 'formik'
import Picker from 'cf-components/Pickers'
import * as yup from 'yup'
import Alert from 'classes/alerts'
import { Modal, ModalSection } from 'library/Modal'
import { SessionContext } from 'session-context'
import {
  FilterTrueOrFalse, FilterNumericValue,
  FilterPropSelect,
  FilterCompSelect,
  FilterAccountSegments
} from '../../../bot/criteria/FilterProperties'
import UserAndTeamPicker from './UserAndTeamPicker'
import { Segment } from 'classes/segments'
import { Button, Checkbox, TextBox } from 'library/materialUI'
import EditIcon from '@material-ui/icons/Edit'
import MergeFieldsButton from 'cf-components/rich-text/MergeFieldsButton'
import { getMessagingService } from 'api/messaging_service'

const useStyles = makeStyles(theme => ({
  fields: {
    gridGap: '20px',
    display: 'flex',
    flexFlow: 'row wrap',
    flex: '0 1',
    flexBasis: 'auto',
    '& > div': {
      flex: '1'
    },
    '& > div:nth-child(3)': {
      flex: '1 0'
    }
  },
  container: {
    display: 'flex',
    gridGap: 10,
    flexFlow: 'column nowrap'
  },
  radio: {
    padding: '4px 9px'
  },
  modal: {
    fontSize: '0.9rem'
  },
  notificationCtn: {
    display: 'flex',
    flexDirection: 'column'
  },
  mergeFieldBtn: {
    width: '20%',
    margin: 10
  },
  guideModal1: {
    left: 600,
    backgroundColor: 'white',
    height: 200
  },
  editHighIntentIcon: {
    color: theme.palette.primary.main
  },
  lightText: {
    color: '#808080'
  }
}))

const FilterGroups = [
  { value: 'visitor_behavior', name: 'Visitor Behavior' }
]

const FilterProps = [
  // { value: 'url_path', name: 'URL Path Visited', group: 'visitor_behavior' },
  { value: 'website_visits', name: 'Website visits', group: 'visitor_behavior' },
  { value: 'total_page_views', name: 'Total page views', group: 'visitor_behavior' },
  { value: 'meeting_booked', name: 'Visitor Books a meeting', group: 'visitor_behavior' },
  { value: 'high_intent_page_visit', name: 'High Intent Page Visit', group: 'visitor_behavior' },
  { value: 'conversation_starts', name: 'Visitor starts a conversation', group: 'visitor_behavior' }
]

const FilterComps = {
  // url_path: [
  //   { value: 'contains', name: 'contains' },
  //   { value: 'is', name: 'is' },
  //   { value: 'is_any', name: 'is any' },
  //   { value: 'does_not_contain', name: 'does not contain' },
  //   { value: 'is_not', name: 'is not' },
  //   { value: 'ends_with', name: 'ends with' },
  //   { value: 'starts_with', name: 'starts with' }
  // ],
  website_visits: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  total_page_views: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  meeting_booked: [
    { value: 'is', name: 'is' }
  ],
  conversation_starts: [
    { value: 'is', name: 'is' }
  ],
  account_segment: [
    { value: 'is', name: 'is' }
  ]
}

const NoCompValues = ['meeting_booked', 'high_intent_page_visit', 'conversation_starts']

const PageSelect = ['high_intent_page_visit']

const FilterValues = {
  // url_path: FilterTextValue,
  website_visits: FilterNumericValue,
  total_page_views: FilterNumericValue,
  meeting_booked: FilterTrueOrFalse,
  conversation_starts: FilterTrueOrFalse,
  account_segment: FilterAccountSegments
}

const descriptionDefault = '{{ visitor.first_name or "A visitor" }} from {{ visitor.company_name or "a company" }} is on your site!'

export const DEFAULT_ALERT: Partial<Alert> = {
  name: '',
  description: descriptionDefault,
  enabled: true,
  isGlobal: false,
  targetIDs: {},
  domain: null,
  trigger: { prop: '', cmp: '', value: '' },
  filters: [],
  notificationSettings: { app: true, email: true, sms: false, external: {} },
  duration: 1
}

const validationSchema = yup.object().shape({
  name: yup.string().required().trim().min(1),
  trigger: yup.object().shape({
    prop: yup.string().required().min(1),
    cmp: yup.string().when('prop', {
      is: (prop = '') => prop.length && !NoCompValues.includes(prop),
      then: yup.string().required().min(1)
    }),
    value: yup.mixed().when(['prop', 'cmp'], {
      is: (prop = '', cmp = '') => {
        return prop.length && !NoCompValues.includes(prop) && Boolean(cmp.length) && cmp !== 'is_any'
      },
      then: yup.lazy(value => {
        if (Array.isArray(value)) {
          return yup.array().required().min(1)
        }
        if (typeof value === 'string') {
          return yup.string().required().min(1)
        }
        return yup.number().required()
      })
    })
  })
})

interface BaseAlertModalProps {
  open: boolean
  onHide: () => void
  isGlobal?: boolean
  domain?: string
}

export interface EditAlertModalProps extends BaseAlertModalProps {
  alert: Alert
}

export interface NewAlertModalProps extends BaseAlertModalProps {
  isGlobal: boolean
}

export default function EditAlertModal (props: EditAlertModalProps | NewAlertModalProps): JSX.Element {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const domain = 'alert' in props ? props.alert?.domain : props.domain
  const chatServiceUrl = user.links.chat_service
  const [selectionStart, setSelectionStart] = useState<number>(0)
  const inputRef = useRef<Record<any, any>>()
  // const initAlert = 'alert' in props && props.alert ? props.alert : DEFAULT_ALERT
  // const [alertPreview, dispatch] = useReducer(reducer, initAlert)
  const { data: segments } = Segment.loadAll({
    searchParams: {
      filters: [[{ field: 'object_type', operator: 'eq', value: 'domains' }]],
      customSort: 'name = \'ICP\' desc, created_timestamp desc'
    }
  })

  const handleSelect = () => { if (inputRef.current) { setSelectionStart(inputRef.current.selectionStart) } }
  const handleChange = (setFieldValue: Function, value: string, attributeName: string, currentText?: string) => {
    // insert at mergefield at cursor location
    if (currentText) {
      value = currentText.substring(0, selectionStart) + value + currentText.substring(selectionStart)
    }
    setFieldValue(attributeName, value)
    // const changes : Record<any, any> = {}
    // changes[attributeName] = value
    // dispatch(changes)
  }

  // const modalSubtitle = useMemo(() => ('isGlobal' in props ? props.isGlobal : props.alert?.isGlobal) ? 'Configure which types of triggers will alert you, and/or your teammates.' : 'Configure which types of triggers from your owned accounts will alert you.', [props])

  const defaultAlert = useMemo<Partial<Alert>>(() => ({ ...DEFAULT_ALERT, isGlobal: props.isGlobal || false, domain: props.domain }), [props.isGlobal, props.domain])
  const [messagingIntegrationName, setMessagingIntegrationName] = useState('external')

  useEffect(() => {
    // Get Messaging Integration
    // Give us the name of the integration, if any
    getMessagingService()
      .then((response) => {
        if (response.ok) {
          const name = response.data?.attributes?.integration_name || ''
          if (name) {
            setMessagingIntegrationName(name.charAt(0).toUpperCase() + name.slice(1))
          }
        }
      })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Formik
        initialValues={'alert' in props ? props.alert || defaultAlert : defaultAlert}
        enableReinitialize
        isInitialValid={false}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          Alert.save(values)
          props.onHide()
          resetForm()
        }}
      >
        {({ values, isValid, submitForm, setFieldValue, resetForm, isSubmitting, touched, errors }) => {
          const handleSelectSegment: SelectProps['onChange'] = (event) => {
            const segmentID = event.target.value as '' | number
            if (segmentID) {
              setFieldValue('filters', [{ prop: 'account_segment', cmp: 'is', value: segmentID }])
            } else {
              setFieldValue('filters', [])
            }
          }
          const selectedSegmentID = values.filters?.length ? values.filters[0].value : ''

          return (
            <Modal
              title={('alert' in props ? 'Edit' : 'Create') + ' Alert'}
              size='md'
              open={props.open}
              onHide={() => {
                props.onHide()
                resetForm()
              }}
              saveIcon='add'
              handleSave={() => submitForm()}
              saveBtnText='Create Alert'
              saveDisabled={isSubmitting || !isValid}
            >
              <>
                <ModalSection
                  title='Alert Name'
                >
                  <div>
                    <Typography variant='body2' className={classes.lightText}>
                      This name will be used in the body of the notifications you’ll receive once an alert is triggered.
                    </Typography>
                  </div>
                  <div>
                    <TextField
                      value={values.name || ''}
                      onChange={(value:any) => handleChange(setFieldValue, value.target.value, 'name')}
                      margin='dense'
                      variant='outlined'
                      error={touched.name && !!errors?.name}
                    />
                  </div>
                </ModalSection>
                <ModalSection
                  title='Trigger'
                >
                  <div style={{ fontWeight: 500, marginBottom: 5 }} className={classes.lightText}>
                    Trigger an alert when...
                  </div>
                  <div className={classes.container}>
                    <div className={classes.fields}>
                      <Field
                        component={FilterPropSelect}
                        styles={{ backgroundColor: 'unset' }}
                        name='trigger.prop'
                        id='trigger.prop'
                        menuItems={FilterProps}
                        propPrefix='trigger'
                        customFilterGroups={FilterGroups}
                      />
                      {values.trigger?.prop && !NoCompValues.includes(values.trigger.prop) && (
                        <>
                          <Field
                            component={FilterCompSelect}
                            styles={{ backgroundColor: 'unset' }}
                            name='trigger.cmp'
                            id='trigger.cmp'
                            menuItems={FilterComps[values.trigger!.prop as keyof typeof FilterComps]}
                            propPrefix='trigger'
                          />
                          {values.trigger?.cmp && values.trigger.cmp !== 'is_any' && (
                            <Field
                              component={FilterValues[values.trigger!.prop as keyof typeof FilterValues]}
                              styles={{ backgroundColor: 'unset' }}
                              name='trigger.value'
                              id='trigger.value'
                              chatServiceUrl={chatServiceUrl}
                              propPrefix='trigger'
                            />
                          )}
                        </>
                      )}
                      {values.trigger?.prop && PageSelect.includes(values.trigger.prop) && (
                        <FormControl margin='dense'>
                          <Field
                            component={Picker}
                            styles={{ backgroundColor: 'unset' }}
                            name='trigger.value'
                            id='trigger.value'
                            selection={values.trigger.value}
                            setSelection={(value: any) => setFieldValue('trigger.value', value)}
                            multiple
                            objectType='highIntentCategories'
                            propPrefix='trigger'
                          />
                        </FormControl>
                      )}
                    </div>
                    {values.trigger?.prop && PageSelect.includes(values.trigger.prop) && user?.attributes?.perms?.semi_admin && (
                      <div>
                        <Button
                          variant='text'
                          onClick={() => window.open('/#/settings/high_intent_pages')}
                          startIcon={<EditIcon className={classes.editHighIntentIcon} />}
                          style={{ textTransform: 'none' }}
                        >
                          Edit High-Intent Pages
                        </Button>
                      </div>
                    )}
                    {!domain && segments && (
                      <>
                        <div style={{ fontWeight: 500, marginTop: 15 }} className={classes.lightText}>
                          Additionally, filter on an account segment (Optional):
                        </div>
                        <FormControl variant='outlined' margin='dense'>
                          <InputLabel><em>Select a Segment</em></InputLabel>
                          <Select
                            label='Select a Segment'
                            variant='outlined'
                            value={selectedSegmentID}
                            onChange={handleSelectSegment}
                            margin='dense'
                          >
                            <MenuItem value=''><em>-- None --</em></MenuItem>
                            {segments.list.map(segment => (
                              <MenuItem key={segment.id} value={segment.id}>
                                {segment.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </div>
                </ModalSection>
                <ModalSection
                  title='Alert Message'
                >
                  <MergeFieldsButton
                    title='{...} Insert Merge Field'
                    action={(value: any) => handleChange(setFieldValue, value, 'description', values.description)}
                    button
                    alerts
                  />
                  <TextBox
                    inputRef={inputRef}
                    onSelect={handleSelect}
                    error={touched.description && !!errors.description}
                    onChange={(value: any) => handleChange(setFieldValue, value, 'description')}
                    value={values.description || descriptionDefault}
                    placeholder={descriptionDefault}
                    rows={5}
                  />
                </ModalSection>
                <ModalSection title='Notifications'>
                  <div>
                    {('isGlobal' in props ? props.isGlobal : values.isGlobal) &&
                      <div>
                        <div className={classes.lightText}>
                          Who would you like to send the alerts to?
                        </div>
                        <div>
                          <UserAndTeamPicker
                            selectedTargets={values.targetIDs || {}}
                            setSelectedTargets={(mappedTargetIds: Alert['targetIDs']) => setFieldValue('targetIDs', mappedTargetIds)}
                          />
                        </div>
                      </div>}
                    <div style={{ marginTop: '20px' }}>
                      <div className={classes.lightText}>
                        How would you like to send the alerts?
                      </div>
                      <div>
                        <div>
                          <Checkbox
                            checked={Boolean(values.notificationSettings?.app)}
                            onChange={(value: any) => setFieldValue('notificationSettings.app', value)}
                            label={<>In-App</>}
                          />
                        </div>
                        <div>
                          <Checkbox
                            checked={Boolean(values.notificationSettings?.email)}
                            onChange={(value: any) => setFieldValue('notificationSettings.email', value)}
                            label={<>Email</>}
                          />
                        </div>
                        <div>
                          <Checkbox
                            checked={Boolean(values.notificationSettings?.sms)}
                            onChange={(value: any) => setFieldValue('notificationSettings.sms', value)}
                            label={<>Text message</>}
                          />
                        </div>
                        {messagingIntegrationName === 'Slack' && (
                          <div>
                            <FormControlLabel
                              label='Slack'
                              control={
                                <Checkbox
                                  checked={Boolean(values.notificationSettings?.external?.slack)}
                                  onChange={(value: any) => setFieldValue('notificationSettings.external', { slack: value })}
                                />
                              }
                            />
                          </div>
                        )}
                        {messagingIntegrationName === 'Teams' && (
                          <div>
                            <FormControlLabel
                              label='Teams'
                              control={
                                <Checkbox
                                  checked={Boolean(values.notificationSettings?.external?.teams)}
                                  onChange={(value: any) => setFieldValue('notificationSettings.external', { teams: value })}
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </ModalSection>
              </>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}
