import React, { useEffect, useRef, useState } from 'react'
import { createROIChart } from './charts/CreateROIChartSimple'
import Loader from 'library/loading/Loader'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function ROIChartSimple ({ chatFunnelsAmount, otherAmount, div_id }) {
  const chartRef = useRef(null)
  const [visible, setVisible] = useState('hidden')
  const smallChart = useMediaQuery('(max-width: 1500px)')

  const loaderStyles = {
    inlineSize: 'max-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 650,
    height: 325,
    iconSize: 30
  }

  useEffect(() => {
    const chart = createROIChart({
      chatFunnelsAmount,
      otherAmount,
      labels: [],
      id: div_id || 'roi_chart_simple_div',
      setVisible
    })
    chartRef.current = chart
  }, [chatFunnelsAmount, div_id, otherAmount])

  return (
    <>
      <div
        style={{
          display: visible === 'visible' ? 'none' : 'block',
          ...loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: smallChart ? 250 : 325, visibility: visible }}>
        <div id={div_id || 'roi_chart_simple_div'} style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

export default ROIChartSimple
