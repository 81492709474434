import { SlicedChart } from '@amcharts/amcharts4/charts';
import { createFunnelChart } from 'pages/reports/charts/CreateFunnelChart';
import { useEffect, useRef, useState } from 'react';

interface FunnelChartProps {
  data: Record<string, any>[]
  dataType: 'number' | 'dollar'
}

export default function FunnelChart ({ data, dataType }: FunnelChartProps): JSX.Element {
  const chartRef = useRef<SlicedChart | undefined>(undefined)
  const [visibility, setVisibility] = useState<'hidden'|'visible'>('hidden')

  useEffect(() => {
    if (data?.length) {
      if (chartRef.current) {
        chartRef.current.data = data
        setVisibility('visible')
      } else {
        const id = 'deal-flow-funnel-chart'
        const type = dataType
        const chart = createFunnelChart({
          data,
          id,
          setVisibility,
          type
        })
        chartRef.current = chart
      }
    }
  }, [data, dataType])

  return (
    <>
      <div style={{ height: 360, visibility: visibility }}>
        <div id='deal-flow-funnel-chart' style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}
