import { Typography } from '@material-ui/core'
import Loader from 'library/loading/Loader'
import { BillingContext } from 'billing-context'
import { getComponentOptions } from 'api/billing'
import { Select, SelectOption } from 'cf-components/material-wrappers/MaterialComponents'
import { ContentRadio } from 'library/ContentRadioGroup'
import { ModalSection } from 'library/Modal'
import { useContext, useEffect, useState } from 'react'
import UpdatePlanModal from './UpdatePlanModal'

type UpdateContactCreditModalProps = {
  open: boolean
  onClose: () => void
  onSave: (newQty: number, lookupsPlus: boolean) => void
  planPrice: number
  allocated: number
}

export default function UpdateContactCreditModal ({ open, onClose, onSave, planPrice, allocated }: UpdateContactCreditModalProps): JSX.Element {
  const [totalPrice, setTotalPrice] = useState(planPrice)
  const [noPlusOptions, setNoPlusOptions] = useState<SelectOption[]>([])
  const [plusOptions, setPlusOptions] = useState<SelectOption[]>([])
  const { billingSettings } = useContext(BillingContext)
  const [lookupsPlus, setLookupsPlus] = useState(billingSettings?.settings?.lookups_plus || false)
  const [loadingOptions, setLoadingOptions] = useState(true)
  const [loadingPlusOptions, setLoadingPlusOptions] = useState(true)
  const [currentValue, setCurrentValue] = useState(allocated)
  const [priceMap, setPriceMap] = useState<Record<number, number>>({})
  const [plusPriceMap, setPlusPriceMap] = useState<Record<number, number>>({})
  const [startingValue, setStartingValue] = useState(0)
  const [startingPlusValue, setStartingPlusValue] = useState(0)
  const planLP = billingSettings?.settings?.lookups_plus || false
  const plusChanged = lookupsPlus !== planLP
  useEffect(() => {
    getComponentOptions('contact-lookups-plus').then(
      (response: Record<string, any>) => {
        if (response.data) {
          const offset = billingSettings?.settings?.lookups_plus ? allocated : 0
          setStartingPlusValue(response.data[0].attributes.total_quantity)
          setPlusOptions(response.data.map((option: Record<string, any>) => ({
            label: `${option.attributes.quantity + offset} Credits (+$${(option.attributes.cost_in_cents / 100)}/month)`,
            value: offset + option.attributes.quantity
          })))
          setLoadingPlusOptions(false)
          setPlusPriceMap(response.data.reduce((acc: Record<number, number>, option: Record<string, any>) => {
            acc[offset + option.attributes.quantity] = option.attributes.cost_in_cents / 100
            return acc
          }, {}))
        }
      }
    )
  }, [allocated, billingSettings])
  useEffect(() => {
    getComponentOptions('contact-lookups').then(
      (response: Record<string, any>) => {
        if (response.data) {
          setStartingValue(response.data[0].attributes.total_quantity)
          setNoPlusOptions(response.data.map((option: Record<string, any>) => ({
            label: `${option.attributes.quantity + allocated} Credits (+$${(option.attributes.cost_in_cents / 100)}/month)`,
            value: allocated + option.attributes.quantity
          })))
          setLoadingOptions(false)
          setPriceMap(response.data.reduce((acc: Record<number, number>, option: Record<string, any>) => {
            acc[allocated + option.attributes.quantity] = option.attributes.cost_in_cents / 100
            return acc
          }, {}))
        }
      }
    )
  }, [allocated])

  useEffect(() => {
    const pm = lookupsPlus ? plusPriceMap : priceMap
    if (pm[currentValue] !== undefined) {
      setTotalPrice(planPrice + pm[currentValue])
    }
  }, [currentValue, priceMap, planPrice, lookupsPlus, plusPriceMap])
  const options = lookupsPlus ? plusOptions : noPlusOptions
  const pm = lookupsPlus ? plusPriceMap : priceMap

  return (
    <UpdatePlanModal
      saveDisabled={loadingOptions || loadingPlusOptions}
      open={open}
      onClose={onClose}
      planPrice={totalPrice}
      onSave={() => {
        onSave(currentValue, lookupsPlus)
        setLoadingOptions(true)
      }}
      duePrice={pm[currentValue]}
    >
      {loadingOptions ? (<div style={{ height: 291 }}><Loader size='md' /></div>) : (
        <ModalSection
          title='Contact Credits'
          subtitle='Select your contact tier and contact limit'
        >
          <ContentRadio
            label='Contact Lookup'
            value='contactLookup'
            checked={!lookupsPlus}
            handleChange={(value: string) => {
              const lp = value === 'contactLookupPlus'
              setCurrentValue(lp ? startingPlusValue : startingValue)
              setLookupsPlus(lp)
            }}
            alwaysShow
          >
            <Typography variant='body2'>Gather email addresses only</Typography>
          </ContentRadio>
          <ContentRadio
            label='Contact Lookup+'
            value='contactLookupPlus'
            checked={lookupsPlus}
            handleChange={(value: string) => {
              const lp = value === 'contactLookupPlus'
              setCurrentValue(lp ? startingPlusValue : startingValue)
              setLookupsPlus(lp)
            }}
            alwaysShow
          >
            <Typography variant='body2'>Gather phone numbers and email addresses</Typography>
          </ContentRadio>
          <Select
            disabled={plusChanged}
            options={options}
            value={currentValue}
            onChange={(value: number) => setCurrentValue(value)}
            disableClear
          />
          {plusChanged && (
            <div style={{ marginLeft: 20, marginTop: 7, fontStyle: 'italic' }}>
              <Typography variant='body2'>If you wish to adjust the quantity as well, please update the plan first and then come back and adjust as needed.</Typography>
            </div>
          )}
        </ModalSection>
      )}
    </UpdatePlanModal>
  )
}
