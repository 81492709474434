import Loader from 'library/loading/Loader';
import { useEffect } from 'react';

const mywindow = window as any

export default function TermsAndConditionsTable () {
  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data);
        }
      }
      */
      const ids = ['terms-and-conditions-page']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
        mywindow._hyperscript.processNode(d)
      }
      mywindow.addEventListener('rowEvent', function (e: any) {
        console.log('rowEvent', e)
        const tableID = e.detail.tableID
        const rowID = e.detail.rowID
        const rowAction = e.detail.action
        if (tableID === 'email-updates-body') {
          switch (rowAction) {
            case 'delete':
              console.log('delete terms and conditions ', rowID)
              break
            case 'edit':
              console.log('edit terms and condtions ', rowID)
              break
          }
        }
      });
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <div
      id='terms-and-conditions-page'
      hx-get='/api/core/web/admin_portal/terms_and_conditions'
      hx-trigger='load'
      hx-indicator='#terms-and-conditions-loader'
    >
      <Loader />
    </div>
  )
  // return (
  //   <div>
  //     <div id='htmx-table' style={{ backgroundColor: 'white' }}>
  //       <div
  //         id='terms-and-conditions-table'
  //         hx-get='/api/core/web/admin_portal/terms_and_conditions'
  //         hx-trigger='revealed'
  //         hx-indicator='#terms-and-conditions-loader'
  //       >
  //         <Loader />
  //       </div>
  //     </div>
  //   </div>
  // )
}
