import AccessControl from 'components/AccessControl'
import { Fader } from 'components/Fader'
import { ListItemIcon, Icon, ListItem, Tooltip } from 'library/materialUI'
import { IconType } from 'library/materialUI/Icon'
import { Link } from 'react-router-dom'
import { Perms } from 'session-context'

export type ListItemType = {
  link?: string
  title: string
  icon: IconType
  requiredPermission?: keyof Perms
  onClick?: () => void
  isShowing: boolean
  isSelected?: boolean
  navType?: keyof typeof linkMap
}

export type navLinks = {
  dashboard: string[],
  chat: string[],
  botManagement: string[],
  playmaker: string[],
  botTests: string[],
  reports: string[],
  contactManagement: string[],
  accounts: string[],
  adminPortal: string[],
  users: string[],
  settings: string[],
  liveView: string[]
}

type NavigationItemProps = {
  item: ListItemType
  color?: string
}

type ConditionalLinkProps = {
  link?: string
  children: React.ReactNode
}

const linkMap: navLinks = {
  dashboard: ['/#/dashboard'],
  chat: ['/#/chat'],
  botManagement: ['/#/bot_management', '/#/bots'],
  playmaker: ['/#/engagement', '/#/playmaker'],
  botTests: ['/#/bot_tests'],
  reports: ['/#/reports'],
  contactManagement: ['/#/contact_management', '/#/contacts'],
  accounts: ['/#/account_management', '/#/accounts'],
  adminPortal: ['/#/admin_portal'],
  users: ['/#/user_management', '/#/users'],
  settings: ['/#/settings'],
  liveView: ['/#/live_view']
}

function showNavLocation (navType: keyof typeof linkMap) {
  let isValid = false
  linkMap[navType].forEach((link: string) => {
    if (window.location.href.includes(link)) {
      isValid = true
    }
  })
  return isValid
}

function ConditionalLink (props: ConditionalLinkProps) {
  if (props.link) {
    return (
      <Link to={props.link} style={{ textDecoration: 'none' }}>
        {props.children}
      </Link>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

export function NavigationListItem ({ item, color }: NavigationItemProps) {
  return (
    <div>
      <div style={{ position: 'fixed', left: 0, marginTop: '9px', width: '5px', height: '27px', borderRadius: '0px 5px 5px 0px', backgroundColor: (item.navType ? showNavLocation(item.navType) ? (color || 'black') : 'white' : item.isSelected ? (color || 'black') : 'white') }}>
        &nbsp;
      </div>
      <AccessControl requiredPermission={item.requiredPermission}>
        <ConditionalLink link={item.link}>
          <Tooltip title={item.isShowing ? '' : item.title} placement='right'>
            <ListItem button style={{ height: 48, borderRadius: 6, padding: 0 }} onClick={item.onClick}>
              <ListItemIcon style={{ minWidth: 0, padding: 12 }}>
                <Icon
                  icon={item.icon === 'playMakerGray' ? (
                    ((item.navType && showNavLocation(item.navType)) || item.isSelected) ? 'playMakerBlack' : item.icon
                  ) : (
                    item.icon === 'travelExplore' ? (
                      ((item.navType && showNavLocation(item.navType)) || item.isSelected) ? 'travelExploreBlack' : item.icon
                    ) : (
                      item.icon
                    )
                  )} color={color || (item.navType ? showNavLocation(item.navType) ? 'black' : '#808080' : item.isSelected ? 'black' : '#808080')}
                />
              </ListItemIcon>
              <div style={{ marginLeft: 10 }}>
                <Fader
                  onScreen={item.isShowing}
                  text={item.title}
                  color={item.navType ? showNavLocation(item.navType) ? '#343434' : '#808080' : item.isSelected ? '#343434' : '#808080'}
                  fontSize='14px'
                  fontStyle='normal'
                />
              </div>
            </ListItem>
          </Tooltip>
        </ConditionalLink>
      </AccessControl>
    </div>
  )
}
