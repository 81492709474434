import { useState } from 'react'
import AppPage from 'cf-components/AppPage'
import { ChannelModal } from './ChannelModal'
import EditIcon from '@material-ui/icons/Edit'
import { Table, Fields } from 'cf-components/table/Table'
import { Channel, Bot, Team, User } from 'classes/classes'

const breadcrumbs = [
  {
    link: '/user_management/channels',
    text: 'Channels'
  },
  {
    link: null,
    text: 'Channel Page'
  }
]

const userFields: Fields<User> = [
  { name: 'name', type: 'avatar' },
  { name: 'role', type: 'text' }
]

const botFields: Fields<Bot> = [
  { name: 'name', type: 'avatar' },
  { name: 'createdTimestamp', type: 'createdAndEdited' }
]

const teamFields: Fields<Team> = [
  { name: 'name', type: 'avatar' },
  { name: 'teamMemberIDs', type: 'avatarGroup', objectType: User, label: 'Team Members', sort: false }
]

export default function ChannelPage ({ match }: { match: any }): JSX.Element {
  const [editChannel, setEditChannel] = useState<Channel | null>(null)
  const channelID = parseInt(match.params.channel_id)

  const { data: channel } = Channel.loadOne(channelID)
  const { data: users, isLoading: userLoading } = User.loadAll()
  const { data: teams, isLoading: teamLoading } = Team.loadAll()
  const { data: bots } = Bot.loadAll()

  const userIDs = channel ? channel.userIDs : []
  const teamIDs = channel ? channel.teamIDs : []
  const botIDs = channel ? channel.botIDs : []
  const agents = users.list.filter(u => userIDs.includes(u.id))
  const botRows = bots.list.filter(b => botIDs.includes(b.id))
  const teamRows = teams.list.filter(t => teamIDs.includes(t.id))

  return (
    <>
      <AppPage
        title={channel?.name || ''}
        breadcrumbs={breadcrumbs}
        tabs={[
          { name: 'Teams', url: `/channels/${channelID}/teams` },
          { name: 'Agents', url: `/channels/${channelID}/agents` },
          { name: 'Bots', url: `/channels/${channelID}/bots` }
        ]}
        action={() => setEditChannel(channel)}
        actionText='Edit Channel'
        icon={<EditIcon />}
      >
        <div>
          <Table
            rows={teamRows}
            fields={teamFields}
            columnStyles='1fr 1fr'
            isLoading={teamLoading}
          />
        </div>
        <div>
          <Table
            rows={agents}
            fields={userFields}
            columnStyles='1fr 1fr'
            isLoading={userLoading}
          />
        </div>
        <div>
          <Table
            rows={botRows}
            fields={botFields}
            columnStyles='1fr 1fr'
          />
        </div>
      </AppPage>
      {editChannel &&
        <ChannelModal
          channel={editChannel}
          onHide={() => setEditChannel(null)}
        />}
    </>
  )
}
