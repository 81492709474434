import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { SectionProps } from '../HelpCenter'
import { HelpCenterCard, THelpCenterCard } from '../HelpCenterCard'
import { HelpCenterContext } from '../HelpCenterContext'
import { SectionCenter } from '../SectionCenter'
import { SettingsOptionsCenter } from './SettingsOptionsCenter'
import { NotificationsHome } from '../notifications/NotificationsHome'

const useStyles = makeStyles(theme => ({
  headerText: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000000BF'
  }
}))

const SettingsHome = (): JSX.Element => {
  const classes = useStyles()
  const { navigateBack } = useContext(HelpCenterContext)

  const cards: THelpCenterCard[] = [
    { title: 'Meeting Settings', icon: 'calendar', link: 'options' },
    { title: 'Notification Settings', icon: 'notification', link: 'notifications' }
  ]

  return (
    <div>
      <div className={classes.headerText} onClick={navigateBack}>
        My Settings
      </div>
      <div>
        {cards.map((card: THelpCenterCard, index: number) => (
          <HelpCenterCard key={index} card={card} />
        ))}
      </div>
    </div>
  )
}

export const sectionMapping = {
  options: SettingsOptionsCenter,
  notifications: NotificationsHome
}

export const SettingsCenter = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<SettingsHome />}
      sectionMapping={sectionMapping}
      links={props.links}
    />
  )
}
