import { Cronofy } from 'classes/cronofy';
import { DisplaySettings } from 'classes/displaySettings';
import { Tenant } from 'classes/tenants';
import User from 'classes/users';
import { useState } from 'react';
import { AdminSetupWizard } from './adminSetup/AdminSetupWizard';
import { IntroWizardModal } from './IntroWizardModal';
import { PersonalSetupWizard } from './personalSetup/PersonalSetupWizard';
import { UserContextProvider } from './personalSetup/UserContext';

type StateType = '' | 'intro' | 'personal' | 'admin'

type ModalProps = {
  showAdmin: boolean
  startState: StateType
  tenant: Tenant
}

function ModalContents (props: ModalProps) {
  const [state, setState] = useState(props.startState)

  const introModalComplete = () => {
    DisplaySettings.update({ page: 'onboarding', type: 'intro_modal', settings: { complete: true } })
    setState('personal')
  }

  const personalModalComplete = () => {
    DisplaySettings.update({ page: 'onboarding', type: 'personal', settings: { complete: true } })
    setState(props.showAdmin ? 'admin' : '')
  }

  const adminModalComplete = () => {
    // const updates: Partial<Tenant> = {
    //   onboardingCompleteTimestamp: new Date()
    // }
    // Tenant.update(props.tenant.id, updates)
    setState('')
  }

  if (state === 'intro') {
    return (
      <IntroWizardModal
        open={state === 'intro'}
        onClose={introModalComplete}
      />
    )
  } else if (state === 'personal') {
    return (
      <PersonalSetupWizard
        open
        handleFinish={personalModalComplete}
      />
    )
  } else if (state === 'admin') {
    return (
      <AdminSetupWizard
        open
        handleFinish={adminModalComplete}
      />
    )
  } else {
    return (
      <></>
    )
  }
}

const defaultOnboardingSettings = {
  // eslint-disable-next-line
  intro_modal: { complete: false },
  personal: { complete: false }
}

export function SetupModal () {
  const { settings, isLoading } = DisplaySettings.load()
  const { data: user, isLoading: userLoading } = User.loadMe()
  const { data: tenant, isLoading: tenantLoading } = Tenant.load()
  Cronofy.load()

  if (isLoading || tenantLoading || userLoading) {
    return (<></>)
  }

  const tenantComplete = tenant.onboardingCompleteTimestamp

  const showAdmin = user.role === 'Admin' && !tenantComplete

  const onboarding = settings.onboarding?.settings || defaultOnboardingSettings

  let startState: StateType = ''
  if (!onboarding.intro_modal.complete) {
    startState = 'intro'
  } else if (!onboarding.personal.complete) {
    startState = 'personal'
  } else if (!tenantComplete) {
    startState = 'admin'
  }

  return (
    <UserContextProvider userID={user.id}>
      <ModalContents
        startState={startState}
        showAdmin={showAdmin}
        tenant={tenant}
      />
    </UserContextProvider>
  )
}
