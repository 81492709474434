/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles';
import { BillingContext } from 'billing-context';
import InformationIcon from 'library/InformationIcon';
import { Typography, Divider, Checkbox, Icon, Tooltip, Button, Chip } from 'library/materialUI';
import { useContext, useState } from 'react';
import ContactCreditUsageHistoryModal from './ContactCreditUsageHistoryModal';
import ProgressBar from 'library/charts/ProgressBar';
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: 5,
    width: '90%'
  },
  circularProgressbarDiv: {
    width: 46,
    height: 46
  },
  headerDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20
  },
  titleDiv: {
    width: '81%'
  },
  dividerDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  checkboxContainerDiv: {
    display: 'flex',
    width: '100%'
  },
  infoText: {
    width: 'fit-content',
    display: 'inline'
  },
  sliderDiv: {
    marginTop: 5,
    marginLeft: 13
  },
  textSummaryDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  darkGreenCircle: {
    width: 17,
    height: 17,
    backgroundColor: '#688C17',
    borderRadius: 8.5,
    marginRight: 15
  },
  greenCircle: {
    width: 17,
    height: 17,
    backgroundColor: '#81AF20',
    borderRadius: 8.5,
    marginRight: 15
  },
  prospectLookUpDiv: {
    display: 'flex',
    padding: '5px 0px'
  },
  contactEnrichmentDiv: {
    display: 'flex',
    padding: '5px 0px'
  },
  usedTypography: {
    marginLeft: 'auto'
  },
  subtitle: {
    font: '12px/18px Poppins'
  },
  checkbox: {
    padding: '9px 9px 9px 0px',
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  },
  linkText: {
    color: '#0072CE',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: 5,
    marginLeft: 5
  }
}))

type ContactCreditUsage = {
  total_limit: number,
  prospect_used: number,
  enrich_enabled: boolean,
  enrich_used: number,
  max: number,
  allocated: number
}

type ContactCreditComponentProps = {
  action: () => void
}

export default function ConctactCreditComponent ({ action }: ContactCreditComponentProps): JSX.Element {
  const classes = useStyles()
  const { billingSettings } = useContext(BillingContext)
  const [openModal, setOpenModal] = useState(false)
  const { contactCredits, usageLoading, handleSetEnrichEnabled } = useContext(BillingContext)
  const typedContactCredits = contactCredits as ContactCreditUsage
  const enrichEnabled = typedContactCredits.enrich_enabled
  const totalUsed = typedContactCredits.enrich_used + typedContactCredits.prospect_used
  const max = typedContactCredits.max
  const enrichUsed = typedContactCredits.enrich_used
  const prospectUsed = typedContactCredits.prospect_used
  const allocated = typedContactCredits.allocated
  const atPlanLimit = max && allocated >= max
  const title = enrichEnabled ? 'CONTACT CREDITS' : 'PROSPECT LOOK-UPS'

  if (usageLoading) {
    return (
      <div style={{ minHeight: 270 }}>
        <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
          <Icon icon='restore' color='grey' />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 220, minWidth: 240 }}>
          <div>
            <div className={classes.titleDiv}>{title}</div>
            <SkeletonLabel size='full' />
            <SkeletonLabel size='full' />
          </div>
        </div>
      </div>
    )
  }
  console.log('ContactCreditComponent', contactCredits, typedContactCredits)

  return (
    <div>
      {openModal &&
        <ContactCreditUsageHistoryModal
          closeModal={() => setOpenModal(false)}
        />}
      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Icon color='#343434' specificSize={30} icon='contactLookup' />
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Tooltip title='Apollo Usage History'>
            <div style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => setOpenModal(true)}>
              <Icon icon='restore' color='grey' />
            </div>
          </Tooltip>
          <Tooltip title={atPlanLimit ? `Your current plan is limited to ${max} credits.  If you need to purchase more
          than ${max} credits, please contact us via the Support link on the left sidebar.` : ''}
          >
            <div>
              <Button
                color='primary'
                variant='outlined'
                style={{ width: 120 }}
                onClick={action}
                disabled={atPlanLimit}
              >
                + Buy More
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={classes.headerDiv}>
        {billingSettings.settings?.lookups_plus ? (
          <div className={classes.titleDiv}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ font: 'font: normal normal medium 14px/21px Poppins', marginRight: 10 }}>{title}</Typography>
              <Chip
                label='Email + Phone'
                style={{ backgroundColor: '#E2E2E2', marginRight: 10 }}
              />
            </div>
            <Typography style={{ fontWeight: 'bold' }}>{totalUsed}/{allocated}</Typography>
          </div>
        ) : (
          <div className={classes.titleDiv}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ font: 'font: normal normal medium 14px/21px Poppins', marginRight: 10 }}>{title}</Typography>
              <Chip
                label='Email addresses only'
                style={{ backgroundColor: '#E2E2E2', marginRight: 10 }}
              />
              <Chip
                label={(
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon icon='upArrowCircle' />
                    <div className={classes.linkText} onClick={action}>
                      Upgrade to Contact Lookup+
                    </div>
                    to get contacts' phone numbers
                  </div>)}
              />
            </div>
            <Typography style={{ fontWeight: 'bold' }}>{totalUsed}/{allocated}</Typography>
          </div>
        )}
        {!enrichEnabled &&
          <ProgressBar current={totalUsed} total={max} />}
        {enrichEnabled &&
          <ProgressBar current={prospectUsed} secondary={enrichUsed} total={max} />}
        {enrichEnabled &&
          <div className={classes.textSummaryDiv}>
            <div className={classes.prospectLookUpDiv}>
              <div className={classes.greenCircle} />
              <Typography className={classes.subtitle}>Prospect Look-ups:</Typography>
              <Typography className={`${classes.usedTypography} ${classes.subtitle}`}>{prospectUsed}</Typography>
            </div>
            <div className={classes.contactEnrichmentDiv}>
              <div className={classes.darkGreenCircle} />
              <Typography className={classes.subtitle}>Contact Enrichments:</Typography>
              <Typography className={`${classes.usedTypography} ${classes.subtitle}`}>{enrichUsed}</Typography>
            </div>
          </div>}
      </div>
      <div className={classes.dividerDiv}>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.checkboxContainerDiv}>
        <div>
          <Checkbox
            onChange={() => handleSetEnrichEnabled()}
            checked={enrichEnabled}
            className={classes.checkbox}
          />
        </div>
        <div style={{ paddingTop: 6 }}>
          <Typography className={classes.infoText}>
            Also use contact credits for contact enrichment
          </Typography>
          <div style={{ width: 'fit-content', display: 'inline-flex', marginLeft: 5 }}>
            <InformationIcon
              message={'Use your contact credits for enrichment to gather data like name, company, title, phone number, and more about site visitors whose email address you\'ve captured'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
