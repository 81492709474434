import { useContext, useState } from 'react'
import EditContactModal from './EditContactModal'
import CustomFieldsModal from 'components/CustomFieldsModal'
import CustomFieldsPage from 'components/CustomFieldsPage'
import { createCustomField } from 'api/custom_fields'
import { createContact } from 'api/contacts'
import AppPage from 'cf-components/AppPage'
import ContactTable from './ContactTable'
import CustomField from 'classes/customFields'
import { queryClient } from 'App'
import Persona from 'classes/personas'
import BuyingCommitteeTable from 'pages/accounts/AccountManagement/BuyingCommittee/BuyingCommitteeTable'
import BuyingCommitteeModal from 'pages/accounts/AccountManagement/BuyingCommittee/BuyingCommitteeModal'
import AIBuyingCommitteeModal from 'pages/accounts/AccountManagement/BuyingCommittee/AIBuyingCommitteeModal'
import { components, SessionContext } from 'session-context'

function ContactManagementPage () {
  const { isOn, canAdd } = useContext(SessionContext)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [fieldModalOpen, setFieldModalOpen] = useState(false)
  const [personaModalOpen, setPersonaModalOpen] = useState(false)
  const [aiPersonaModalOpen, setAiPersonaModalOpen] = useState(false)
  const [showPersonaMenu, setShowPersonaMenu] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [update, setUpdate] = useState(0)
  const { data: customFields } = CustomField.loadAll(undefined, 'people')
  const [anchorEl, setAnchorEl] = useState(null)
  const personasOn = isOn(components.PERSONAS)
  const canAddPersonas = canAdd(components.PERSONAS)

  function addAccount (account) {
    setAccounts({ ...accounts, [account.id]: account })
  }

  const dbAttributes = [
    'first_name',
    'last_name',
    'honorific',
    'phone',
    'mobile_phone',
    'email',
    'title',
    'description',
    'other_email',
    'other_phone',
    'custom_fields',
    'company_name',
    'company_website',
    'company_industry',
    'company_employee_count',
    'company_revenue',
    'city',
    'state',
    'country',
    'bio',
    'role',
    'seniority',
    'linkedin_handle',
    'company_sector',
    'company_phone',
    'company_description',
    'company_location',
    'company_timezone',
    'company_twitter_handle',
    'company_tech',
    'company_linkedin_handle',
    'avatar_url',
    'company_avatar_url',
    'facebook_handle',
    'twitter_handle',
    'instagram_handle'
  ]

  function createPerson (values) {
    const customFieldNames = customFields?.list.map(field => field.name)

    const customAttributes = {}
    customFieldNames.forEach(attr => {
      customAttributes[attr] = values[attr]
    })

    const personAttributes = {}
    dbAttributes.forEach(attr => {
      personAttributes[attr] = values[attr]
    })

    personAttributes.custom_fields = customAttributes

    const account_id = values.account_id

    createContact({ personAttributes, account_id })
      .then(response => {
        setUpdate(update + 1)
      })
  }

  function createNewField (values) {
    const forObject = 'people'
    createCustomField({ values, forObject })
      .then(response => {
        queryClient.invalidateQueries('customFields')
      })
  }

  function handlePersonaClick (event) {
    setAnchorEl(event.currentTarget)
    setShowPersonaMenu(true)
  }

  const tabs = [
    { name: 'Contacts', url: '/contact_management/contacts', action: () => setEditModalOpen(true), actionText: 'Add Contact', bgColor: 'white' }
  ]

  if (personasOn) {
    tabs.push(
      {
        name: 'Buying Committee',
        url: '/contact_management/buying_committee',
        action: handlePersonaClick,
        actionText: 'Add Persona',
        disabled: !canAddPersonas || !personasOn,
        tooltip: !canAddPersonas ? (
          'You\'re at the maximum number of personas for your plan. Uprade your plan or delete a persona to create a new one.'
        ) : (
          !personasOn ? (
            'Personas are not available in your current plan. Upgrade your plan to access this feature.'
          ) : undefined
        )
      }
    )
  }
  if (isOn(components.PLAYMAKER)) {
    tabs.push({ name: 'Contact Custom Fields', url: '/contact_management/custom_fields', action: () => setFieldModalOpen(true), actionText: 'Add Custom Field' })
  }

  document.title = 'Contact Management | Signals'

  return (
    <>
      <AppPage
        title='Contact Management'
        tabs={tabs}
        padding={0}
      >
        <ContactTable />
        {personasOn && (
          <BuyingCommitteeTable
            showPersonaMenu={showPersonaMenu}
            setShowPersonaMenu={setShowPersonaMenu}
            setPersonaModalOpen={setPersonaModalOpen}
            setAiPersonaModalOpen={setAiPersonaModalOpen}
            anchorEl={anchorEl}
          />
        )}
        <CustomFieldsPage forObject='people' />
      </AppPage>
      <EditContactModal
        open={editModalOpen}
        onHide={() => setEditModalOpen(false)}
        accounts={accounts}
        addAccount={addAccount}
        person={null}
        customFields={customFields?.list || []}
        savePerson={values => {
          createPerson(values)
          setEditModalOpen(false)
        }}
      />
      <BuyingCommitteeModal
        open={personaModalOpen}
        onHide={() => setPersonaModalOpen(false)}
        persona={new Persona({ attributes: {} })}
      />
      <AIBuyingCommitteeModal
        open={aiPersonaModalOpen}
        onHide={() => setAiPersonaModalOpen(false)}
        canAddAmount={canAddPersonas}
      />
      {isOn(components.PLAYMAKER) && (
        <CustomFieldsModal
          open={fieldModalOpen}
          onHide={() => setFieldModalOpen(false)}
          save={createNewField}
        />
      )}
    </>
  )
}

export default ContactManagementPage
