import SegmentDefaultPage from 'pages/segments/SegmentDefaultPage'
import Account from 'classes/accounts'
import { TableProvider } from 'library/table/TableProvider';
import { Actions, Fields } from 'cf-components/table/Table'
import { useContext, useState } from 'react';
import { SessionContext } from 'session-context';
import EditAlertModal from '../AccountManagement/Alerts/EditAlertModal';
import TableList from '../../../cf-components/table/TableList';
import NotificationsIcon from '@material-ui/icons/Notifications'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { SignalsScoreGear } from 'cf-components/SignalsScoreGear';

const SegmentPage = ({ match }: any) => {
  const segmentID = match.params.segment_id
  const { user } = useContext(SessionContext)
  const [alertDomain, setAlertDomain] = useState<string>('')
  const [alertModalOpen, setAlertModalOpen] = useState(false)

  function DisplayScore ({ row }: { row: Account }) {
    return <SignalsScoreGear score={row.engagementScore} placement='left' domain={row.domain} />
  }

  const fields: Fields<Account> = [
    { name: 'name', type: 'avatar', label: 'Company Name' },
    { name: 'accountType', type: 'text', minWidth: 200, emptyState: 'No Account Type' },
    { name: 'contacts', type: 'text', label: 'Contacts', minWidth: 100, emptyState: '0' },
    { name: 'opportunityStatus', type: 'opp', label: 'Opportunity Status', minWidth: 200 },
    { name: 'oppAmountCents', type: 'text', noDisplay: true },
    { name: 'ownerUserId', type: 'userAvatar', label: 'Account Owner', minWidth: 300 },
    { name: 'engagementScore', type: 'custom', component: DisplayScore, label: 'Signals Score', minWidth: 200 }
  ]

  const adminAlerts = user && user.attributes.perms?.tenant_admin_alerts && user?.attributes?.perms?.manager

  const actions: Actions<Account> = [
    { name: 'Go to Account Page', action: (row) => window.open(`/#/accounts/${row.domain}`), icon: VisibilityIcon }
  ]
  if (adminAlerts) {
    actions.push({ name: 'Create Alert for Account', action: (row) => { setAlertDomain(row?.domain); setAlertModalOpen(true) }, icon: NotificationsIcon })
  }

  return (
    <SegmentDefaultPage
      segmentID={segmentID}
      objectType='accounts'
    >
      <TableProvider<Account>
        ObjectClass={Account}
        fields={fields}
        actions={actions}
        noEmail
        gridTemplateColumns='80px 1fr 1fr 1fr 1fr 1fr 120px 80px'
        initialSearchParams={{
          filters: [],
          sortColumn: 'name',
          pageNumber: 1,
          sortOrder: 'asc',
          pageSize: 20
        }}
        extraLoadProps={segmentID}
      >
        <TableList<Account> maxHeight='73vh' disableSnoozeFilter />
      </TableProvider>
      <EditAlertModal
        open={alertModalOpen}
        onHide={() => { setAlertDomain(''); setAlertModalOpen(false) }}
        domain={alertDomain}
        isGlobal
      />
    </SegmentDefaultPage>
  )
}

export default SegmentPage
