import { useEffect, useContext, useState, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useRouteMatch } from 'react-router-dom'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import { SessionContext } from 'session-context'
import AccessControl from 'components/AccessControl'
import { getCredentialsStatus } from 'api/integrations'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 230,
    height: '100vh',
    overflow: 'auto',
    fontFamily: 'Poppins, sans serif'
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemSelected: {
    fontSize: '15px',
    color: '#2A2A2A',
    fontWeight: 600
  },
  listItemUnselected: {
    fontSize: '15px',
    color: '#7A7A7A'
  }
}))

const ConversationsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/conversations' {...props} />
))

const AgentsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/agent_report' {...props} />
))

const SalesforceROIOverviewLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/roi_overview' {...props} />
))

const SalesforceROIPipelineSourcedLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/roi_pipeline_sourced' {...props} />
))

const SalesforceROIPipelineInfluencedLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/roi_pipeline_influenced' {...props} />
))

const SalesforceROIPipelineWonLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/roi_closed_won' {...props} />
))

const SalesforceROIDealFlowLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/roi_deal_flow' {...props} />
))

function ReportsNavBar () {
  const classes = useStyles()
  const [hasSalesforceCredentials, setHasSalesforceCredentials] = useState(false)
  const reportNameMatch = useRouteMatch('/reports/:reportName')
  const reportName = reportNameMatch?.params?.reportName
  const { user } = useContext(SessionContext)
  const hasSalesforceAdvanced = user?.attributes?.perms?.tenant_salesforce_advanced
  useEffect(() => {
    getCredentialsStatus('salesforce').then(res => {
      setHasSalesforceCredentials(res?.data?.attributes?.has_credentials || false)
    })
  }, [])

  const [openSections, setOpenSections] = useState(['dashboard', 'salesforce_roi'])

  function handleSectionClick (sectionName) {
    if (openSections.includes(sectionName)) {
      setOpenSections(openSections.filter(i => i !== sectionName))
    } else {
      setOpenSections([...openSections, sectionName])
    }
  }

  return (
    <div>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        disablePadding
        subheader={
          <ListSubheader component='div' id='nested-list-subheader' disableSticky>
            Reports
          </ListSubheader>
        }
        className={classes.root}
      >
        <ListItem
          button onClick={() => { handleSectionClick('dashboard') }}
        >
          <div className={classes.itemHeader}>
            Dashboard
          </div>
        </ListItem>
        <Collapse in={openSections.includes('dashboard')} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <AccessControl requiredPermission='manager'>
              <ListItem
                button
                component={ConversationsLink}
                className={classes.nested}
              >
                <div
                  className={reportName === 'conversations' ? classes.listItemSelected : classes.listItemUnselected}
                >
                  Conversations
                </div>
              </ListItem>
            </AccessControl>
            <ListItem
              button
              component={AgentsLink}
              className={classes.nested}
            >
              <div
                className={reportName === 'agent_report' ? classes.listItemSelected : classes.listItemUnselected}
              >
                Agent Performance
              </div>
            </ListItem>
          </List>
        </Collapse>
        {hasSalesforceCredentials && hasSalesforceAdvanced && (
          <AccessControl requiredPermission='semi_admin'>
            <ListItem
              button onClick={() => { handleSectionClick('salesforce_roi') }}
            >
              <div className={classes.itemHeader}>
                ROI
              </div>
            </ListItem>
            <Collapse in={openSections.includes('salesforce_roi')} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  button
                  component={SalesforceROIOverviewLink}
                  className={classes.nested}
                >
                  <div
                    className={reportName === 'roi_overview' ? classes.listItemSelected : classes.listItemUnselected}
                  >
                    Overview
                  </div>
                </ListItem>
                <ListItem
                  button
                  component={SalesforceROIPipelineInfluencedLink}
                  className={classes.nested}
                >
                  <div
                    className={reportName === 'roi_pipeline_influenced' ? classes.listItemSelected : classes.listItemUnselected}
                  >
                    Pipeline Influenced
                  </div>
                </ListItem>
                <ListItem
                  button
                  component={SalesforceROIPipelineSourcedLink}
                  className={classes.nested}
                >
                  <div
                    className={reportName === 'roi_pipeline_sourced' ? classes.listItemSelected : classes.listItemUnselected}
                  >
                    Pipeline Sourced
                  </div>
                </ListItem>
                <ListItem
                  button
                  component={SalesforceROIPipelineWonLink}
                  className={classes.nested}
                >
                  <div
                    className={reportName === 'roi_closed_won' ? classes.listItemSelected : classes.listItemUnselected}
                  >
                    Closed Won
                  </div>
                </ListItem>
                <ListItem
                  button
                  component={SalesforceROIDealFlowLink}
                  className={classes.nested}
                >
                  <div
                    className={reportName === 'roi_deal_flow' ? classes.listItemSelected : classes.listItemUnselected}
                  >
                    Deal Flow
                  </div>
                </ListItem>
              </List>
            </Collapse>
          </AccessControl>
        )}

      </List>
    </div>
  )
}

export default ReportsNavBar
