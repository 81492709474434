import { queryResult, useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { doPatch } from 'api/api'
import { employeeValueMap, getMaxRequired, noRestrictionAnnualRevenue, noRestrictionEmployeeCount, revenueValueMap } from 'pages/accounts/AccountICP/accountICPHelpers'
import { isEqual } from 'lodash'
import { initialStateICPSetup } from 'pages/accounts/AccountICP/ICPProvider'

export const initialICPFilters = {
  industry: [],
  state: [],
  country: [],
  technologies: [],
  annualRevenue: { gt: 0, lt: 12 },
  employeeCount: { gt: 0, lt: 13 },
  rawData: { matchedFields: {}, counts: {} }
}

const defaultRequiredFields = {
  industry: false,
  location: false,
  annual_revenue: false,
  employee_count: false,
  technologies: false
}

const defaultState = {
  account_types: [],
  include_closed_lost: false,
  skip_crm: false,
  segments: [],
  required_fields: defaultRequiredFields,
  required_match_count: 5,
  filter_comparators: {},
  segment_ids: [0]
}

const getICPFilters = (display_filters: any): ICPFilters<'formatted'>[] => {
  if (display_filters) {
    const evGt = display_filters.employee_count.gt
    const evLt = display_filters.employee_count.lt
    const rvLt = display_filters.annual_revenue.lt
    const rvGt = display_filters.annual_revenue.gt
    const icpFilters: ICPFilters<'formatted'> = {
      employeeCount: {
        gt: evGt || 0,
        lt: evLt || 13
      },
      annualRevenue: {
        gt: rvGt || 0,
        lt: rvLt || 12
      },
      industry: display_filters?.industry || [],
      country: display_filters?.country || [],
      technologies: display_filters?.technologies || [],
      state: display_filters?.state || [],
      rawData: display_filters?.raw_data || []
    }
    return [icpFilters]
  }
  return [defaultICPFilters]
}

export interface RawData {
  matchedFields: {
    annual_revenue?: string[]
    number_of_employees?: string[]
    technologies?: string[]
    industry?: string[]
    state?: string[]
    country?: string[]
  }
  counts: {
    annual_revenue?: { [k: string]: number }
    number_of_employees?: { [k: string]: number }
    industry?: { [k: string]: number }
    state?: { [k: string]: number }
    country?: { [k: string]: number }
    technologies?: { [k: string]: number }
  }
}

export interface ICPFilters<Format extends 'strings' | 'formatted' = 'strings'> {
  state?: string[]
  country?: string[]
  industry?: string[]
  technologies?: string[]
  annualRevenue?: Format extends 'strings' ? string[] : { gt?: number; lt?: number; }
  employeeCount?: Format extends 'strings' ? string[] : { gt?: number; lt?: number; }
  rawData?: RawData
}

const defaultICPFilters = {
  state: [],
  country: [],
  industry: [],
  technologies: [],
  annualRevenue: { gt: 0, lt: 12 },
  employeeCount: { gt: 0, lt: 13 },
  rawData: undefined
}

export type ICPRequiredFields = {
  industry: boolean
  location: boolean
  annual_revenue: boolean
  employee_count: boolean
  technologies: boolean
}

export type ICPFilterComparators = {
  technologies: string
}

export class ICP extends GenericObject {
  accountTypes: string[];
  includeClosedLost: boolean;
  skipCRM: boolean;
  filters: ICPFilters<'formatted'>[];
  requiredFields: ICPRequiredFields
  requiredMatchCount: number
  filterComparators: ICPFilterComparators
  segmentID: number

  static get (): queryResult<ICP> {
    return useDoQuery<ICP>({ path: '/icp', useChatURL: true, objectClass: ICP, single: true })
  }

  static upsert ({ segments, accountTypes, icp }: { segments: ICPFilters<'formatted'>[], accountTypes: typeof initialStateICPSetup, icp: ICP }): Promise<any> {
    const dataSegments = segments.map((values: any) => ({
      annual_revenue: values.annualRevenue,
      state: values.state,
      country: values?.country?.filter((v: any) => v !== null && v !== undefined) || [],
      technologies: values?.technologies?.filter((v: any) => v !== null && v !== undefined) || [],
      employee_count: values.employeeCount,
      industry: values?.industry?.filter((v: any) => v !== null && v !== undefined) || [],
      raw_data: values.rawData
    }))

    const dataAccountTypes = {
      customer_account_types: accountTypes.customerAccountTypes,
      include_closed_lost: accountTypes.includeClosedLost
    }

    const maxRequired = getMaxRequired(segments[0])
    const icpData = {
      ...icp,
      required_match_count: Math.min(icp.requiredMatchCount, maxRequired),
      required_fields: icp.requiredFields,
      filter_comparators: icp.filterComparators
    }
    const values = segments[0]
    const evGt = values.employeeCount?.gt !== undefined ? employeeValueMap[values.employeeCount.gt] : null
    const evLt = values.employeeCount?.lt && values.employeeCount.lt < employeeValueMap.length ? employeeValueMap[values.employeeCount.lt] : null
    const rvGt = values.annualRevenue?.gt !== undefined ? revenueValueMap[values.annualRevenue.gt] : null
    const rvLt = values.annualRevenue?.lt && values.annualRevenue.lt < revenueValueMap.length ? revenueValueMap[values.annualRevenue.lt] : null
    const employeeCount = { gt: evGt, lt: evLt }
    const annualRevenue = { gt: rvGt, lt: rvLt }
    const setEmployeeCount = isEqual(employeeCount, noRestrictionEmployeeCount) ? null : employeeCount
    const mappedRangeNumbers = {
      employee_count: setEmployeeCount,
      annual_revenue: isEqual(annualRevenue, noRestrictionAnnualRevenue) ? null : annualRevenue
    }

    const data = {
      attributes: {
        segments: dataSegments,
        account_types: dataAccountTypes,
        icp: icpData,
        mapped_range_numbers: mappedRangeNumbers
      }
    }

    return doPatch({ path: '/icp', useChatURL: true, data: data })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const icp = row?.attributes || defaultState
    this.accountTypes = icp.account_type
    this.includeClosedLost = icp.include_closed_lost
    this.skipCRM = icp.skip_crm
    this.filters = getICPFilters(icp.display_filters)
    this.requiredFields = icp.required_fields
    this.requiredMatchCount = icp.required_match_count
    this.filterComparators = icp.filter_comparators
    this.segmentID = icp.segment_ids[0]
  }
}
