import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  fadeIn: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  fadeOut: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    })
  }
}))

type FaderProps = {
  onScreen: boolean
  text: string
  color: string
  fontSize: string
  fontStyle: string
  marginLeft?: string
}

export function Fader (props: FaderProps) {
  const classes = useStyles()

  return (
    <div
      className={
        clsx({
          [classes.fadeIn]: props.onScreen,
          [classes.fadeOut]: !props.onScreen
        })
      }
    >
      <div style={{ color: props.color, fontSize: props.fontSize, fontStyle: props.fontStyle, marginLeft: props.marginLeft ? props.marginLeft : '0px', fontFamily: 'Poppins' }}>
        {props.text}
      </div>
    </div>
  )
}
