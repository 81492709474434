import { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Company from 'classes/companies'
import Account from 'classes/accounts'
import { format } from 'date-fns'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import { ContactInfoAvatar } from 'cf-components/Avatars'
import { initiateConversation } from 'api/conversations'
import { getSegmentsFromDomain } from 'api/segments'
import { Button, Icon, Popper, Tooltip } from 'library/materialUI'
import { ClickAwayListener, Grow, Paper, Divider, Fab } from '@material-ui/core'
import { SavedReply } from 'classes/savedReplies'
import { ScrollBox } from 'cf-components/ScrollBox'
import AccessControl from 'components/AccessControl'
import { SignalsScoreGear } from 'cf-components/SignalsScoreGear'
import OppStatusText from 'pages/dashboard/OppStatusText'
import { DisplayLink } from 'library/table/tableHelpers'
import { components, SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  chatBox: {
    height: '100%',
    backgroundColor: 'white',
    width: 450,
    fontFamily: 'Poppins, sans serif',
    display: 'grid',
    gridTemplateRows: '68px calc(100% - 68px)'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 10px 25px',
    color: 'white',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 600
  },
  body: {
    padding: 20,
    overflowY: 'auto'
  },
  visitorAvatar: {
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    color: '#8E5AE2',
    height: 24,
    width: 24
  },
  contactButton: {
    height: 24,
    width: 24,
    '&$disabled': {
      backgroundColor: '#7AC9FF'
    }
  },
  infoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  itemTitle: {
    color: theme.palette.primary.main,
    marginRight: 50
  },
  primary: {
    color: theme.palette.primary.main
  },
  itemData: {
    textAlign: 'right'
    // Space by around 50px from title
  },
  messageDiv: {
    marginTop: 20,
    padding: 0
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15
  },
  savedReplies: {
    height: '325px',
    width: '275px'
  },
  replyList: {
    maxHeight: 325
  },
  replyRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    gridGap: 10,
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'poppins',
    padding: '5px',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  replyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontFamily: 'poppins'
  },
  buttons: {
    fontSize: '12px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 24
  }
}))

function InsertButton (props) {
  const classes = useStyles()

  return (
    <Fab
      variant='extended'
      size='small'
      className={classes.buttons}
      color='primary'
      onClick={() => props.insertReply(props.contents.replace('<p>', '').replace('</p>', ''))}
    >
      Insert
    </Fab>
  )
}

function ReplyRow (props) {
  const classes = useStyles()
  const insertReply = props.insertReply
  const contents = props.contents
  return (
    <div>
      <div
        className={classes.replyRow}
      >
        <div className={classes.replyText}>
          {props.title}
        </div>
        <InsertButton insertReply={insertReply} contents={contents} />
      </div>
      <Divider />
    </div>
  )
}

function SavedRepliesContent (props) {
  const setMessage = props.setMessage
  const replies = props.replies
  const insertReply = (value) => setMessage(value)
  const classes = useStyles()

  return (
    <div style={{ padding: '5px' }}>
      <div style={{ paddingLeft: '5px', paddingTop: '10px', paddingBottom: '10px' }}>
        Saved Replies
      </div>
      <ScrollBox className={`${classes.replyList}} no-scroll`}>
        {replies.map(row => (
          <ReplyRow
            title={row.name}
            key={row.id}
            contents={row.content}
            insertReply={insertReply}
          />
        ))}
      </ScrollBox>
    </div>
  )
}

function SavedRepliesPopup (props) {
  const classes = useStyles()
  const [openReplies, setOpenReplies] = useState(false)
  const { data: replies, isLoading } = SavedReply.loadAll()
  const setMessage = props.setMessage

  if (isLoading) {
    return <></>
  }

  return (
    <div className={classes.primary} style={{ textAlign: 'right', marginBottom: '10px' }}>
      <Button
        endIcon={<ExpandMoreIcon />}
        variant='outlined'
        color='primary'
        onClick={() => setOpenReplies(true)}
      >
        Saved Replies
      </Button>
      {openReplies &&
        <Popper
          open={openReplies}
          transition style={{ zIndex: 10000, position: 'absolute', right: 30, left: 'auto', top: '40%' }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center bottom'
              }}
            >
              <Paper id='menu-list-grow'>
                <ClickAwayListener onClickAway={() => setOpenReplies(false)}>
                  <div className={classes.savedReplies}>
                    <SavedRepliesContent setMessage={setMessage} replies={replies.list} />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>}
    </div>
  )
}

function LiveViewChatBox (props) {
  const classes = useStyles()
  const fmat = 'MM/dd/yyyy'
  const { isOn } = useContext(SessionContext)
  const domain = props?.participant?.domain
  const [message, setMessage] = useState('')
  const [accountType, setAccountType] = useState('')
  const [segments, setSegments] = useState('')
  const { data: compList, isLoading } = Company.loadAll()
  const { data: acc } = Account.loadOneByDomain(props?.participant?.domain)
  const [loadingSegments, setLoadingSegments] = useState(false)
  const mobile = props.mobile || false
  const handleChange = (event) => {
    const cleanText = event.target.value.replace('<p>', '').replace('</p>', '').replace('<br>', '')
    setMessage(cleanText)
  }

  function remapEnterKey (event) {
    if (event.key === 'Enter' && event.shiftKey) {
      return
    }
    if (event.key === 'Enter') {
      sendMessage()
    }
  }

  function sendMessage () {
    const visitorParticipantID = props.participant.id
    const startUrl = props.participant.currentUrl
    initiateConversation({ message, visitorParticipantID, startUrl })
      .then(response => {
        const link = '/#/chat/' + response.data.id
        if (!mobile) {
          window.open(link, '_blank')
        } else {
          window.open(link, '_self')
        }
      })
    props.close()
    setMessage('')
  }

  useEffect(() => {
    if (domain && !loadingSegments) {
      getSegmentsFromDomain(domain).then((response) => {
        if (response.data.length > 0) {
          const segmentNames = response.data.map(segment => segment.attributes.name)
          setSegments(segmentNames.join(', '))
        } else {
          setSegments('No segments')
        }
        setLoadingSegments(false)
      })
      setLoadingSegments(true)
    } else {
      setSegments('No segments')
    }
    // eslint-disable-next-line
  }, [domain])

  if (props.participant) {
    const recentConversationID = props.participant.recentConversationID
    const recentConversationTimestamp = props.participant.recentConversationTimestamp
    const domain = props.participant.domain
    if (accountType === '') {
      if (!isLoading) {
        const account = compList.list.find(company => company.domain === domain)
        if (account) {
          setAccountType(account.accountType)
        }
      }
    }
    let hasRecentConversation = false
    if (recentConversationTimestamp) {
      const minutesSinceConversation = (new Date() - new Date(recentConversationTimestamp)) / 1000 / 60
      if (minutesSinceConversation < 60) {
        hasRecentConversation = true
      }
    }
    return (
      <div className={classes.chatBox} style={mobile ? { width: '100%' } : { width: '450px' }}>
        <div className={classes.header}>
          <div>
            {isOn(components.CHAT) && (
              <>
                {mobile ? 'Quick Chat' : 'Live Chat'}
              </>
            )}
          </div>
          <IconButton onClick={() => props.close()}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        </div>
        <div className={classes.body}>
          <div className={classes.visitorAvatar}>
            <ContactInfoAvatar
              contact={props.participant.person}
              participantID={props.participant.id}
              size='lg'
            />
            <SignalsScoreGear score={acc?.engagementScore || 0} domain={props?.participant?.domain} specificSize={75} />
          </div>
          <div>
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Company
              </div>
              <div className={classes.itemData}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <DisplayLink field={props.participant.domain} link={'/accounts/' + props.participant.domain}>
                    {props.participant.company || props.participant.sixSenseName || props.participant.domain}
                  </DisplayLink>
                  {props.participant.isICP && <Icon icon='icp' />}
                </div>
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Opportunity Status
              </div>
              <div className={classes.itemData}>
                <OppStatusText status={props.participant.opportunityStatus} />
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Title
              </div>
              <div className={classes.itemData}>
                {props.participant.person?.attributes.title || 'Title Unknown'}
                {props.participant?.fromBuyingCommittee &&
                  <Tooltip title={`This person is a member of your buying committee in your ${props.participant.person?.attributes?.persona} persona.`}>
                    <div style={{ marginLeft: '5px' }}>
                      <Icon icon='buyingCommittee' />
                    </div>
                  </Tooltip>}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Account Type
              </div>
              <div className={classes.itemData}>
                {accountType}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Segments
              </div>
              <div className={classes.itemData}>
                {loadingSegments ? <div>...loading</div> : segments}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Current Page
              </div>
              <div className={classes.itemData}>
                <a href={props.participant.currentUrl} target='_blank' rel='noreferrer' className={classes.primary}>
                  {props.participant.currentPage}
                </a>
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Time on Site
              </div>
              <div className={classes.itemData}>
                {props.participant.timeOnSite}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Total Pages Viewed
              </div>
              <div className={classes.itemData}>
                {props.participant.totalPagesViewed}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Conversations to Date
              </div>
              <div className={classes.itemData}>
                {props.participant.conversationsToDate}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Site Visits
              </div>
              <div className={classes.itemData}>
                {props.participant.totalVisits}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                First Visit
              </div>
              <div className={classes.itemData}>
                {props?.participant?.firstSeen ? format(new Date(props.participant.firstSeen), fmat) : ''}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Traffic Source
              </div>
              <div className={classes.itemData}>
                {props.participant.trafficSource}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Location
              </div>
              <div className={classes.itemData}>
                {props.participant.location}
              </div>
            </div>
          </div>
          <AccessControl requiredPermission='send_chats' requiredComponent={components.CHAT}>
            {!mobile && (
              <div className={classes.messageDiv}>
                <div>
                  {hasRecentConversation ? (
                    <div style={{ marginBottom: '5px' }}>
                      A conversation has already started with this visitor
                    </div>
                  ) : (
                    <SavedRepliesPopup setMessage={setMessage} />
                  )}
                  <TextField
                    variant='outlined'
                    multiline
                    rows={5}
                    value={message}
                    onChange={handleChange}
                    disabled={hasRecentConversation}
                    fullWidth
                    placeholder={hasRecentConversation ? ('Conversation already started') : ('Start chatting with ' + (props.participant.name || 'this visitor'))}
                    onKeyPress={remapEnterKey}
                    style={hasRecentConversation ? { backgroundColor: '#0000001A' } : { backgroundColor: 'white' }}
                  />
                  {hasRecentConversation ? (
                    <Button
                      variant='contained'
                      fullWidth
                      color='primary'
                      href={'/#/chat/' + recentConversationID}
                      target='_blank'
                    >
                      View Conversation
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={sendMessage}
                    >
                      Send
                    </Button>
                  )}
                  <div className={classes.buttonDiv}> </div>
                </div>
              </div>
            )}
          </AccessControl>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

export default LiveViewChatBox
