/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import { ModalSection } from 'library/Modal'
import { Select, Button } from 'cf-components/material-wrappers/MaterialComponents'
import Picker from 'cf-components/Pickers'
import * as yup from 'yup'
import { Tooltip, Typography } from '@material-ui/core'
import { useContext } from 'react'
import { Icon } from 'library/materialUI'
import { ShareContext } from 'share-context'
import Alert from '@material-ui/lab/Alert'
import { RoutingRules } from 'classes/routingRules'

const useStyles = makeStyles(theme => ({
  routeTypeDiv: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    gridGap: 15,
    marginBottom: 10
  },
  routingBlock: {
    display: 'grid',
    gridGap: 10,
    marginBottom: 10
  },
  blockControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  noRouteType: {
    marginBottom: 10
  },
  inlineOptions: {
    display: 'flex',
    alignItems: 'center'
  },
  editIcon: {
    display: 'flex',
    marginLeft: '15px',
    cursor: 'pointer'
  }
}))

const methodsWithoutIDs = ['account_owner', 'last_routed', 'global_routing']
export const routeOptionsSchema = yup.array().of(yup.object({
  method: yup.string(),
  type: yup.string(),
  objectIDs: yup.mixed().when('method', {
    is: (method: string) => !methodsWithoutIDs.includes(method),
    then: yup.array().of(yup.number()).min(1)
  })
}))

type handleChangeProperty = ({ value, index, property }: { value: any, index: number, property: string }) => void

/**
 * route is equivalent to RouteOption in channelmanager
 */
export type route = {
  method: 'single_agent' | 'last_routed' | 'group' | 'team' | 'account_owner' | 'global_routing' | ''
  objectIDs: number[]
  type: 'round_robin' | 'quick_draw' | 'all_agents' | ''
}

const labelTitleMapping: Record<string, string> = {
  single_agent: 'Routing to single agent',
  last_routed: 'Routing to last routed agent',
  group: 'Routing to group of agents',
  team: 'Routing to a team',
  account_owner: 'Routing by account owner',
  global_routing: 'Routing by global routing rules'
}

export function RouteLabel (routeOptions: route[]): string {
  if (routeOptions?.length > 0) {
    return labelTitleMapping[routeOptions[0].method]
  }
  return 'Configure routing'
}

const methodOptions = [
  { value: 'single_agent', label: 'Route to single agent' },
  { value: 'last_routed', label: 'Last routed agent' },
  { value: 'group', label: 'Route to group of agents' },
  { value: 'team', label: 'Route to a team' },
  { value: 'account_owner', label: 'Route by assigned owner' },
  { value: 'global_routing', label: 'Use Global Routing Rules' }
]

const editableMethods = ['account_owner', 'global_routing']

const salesloftOptions = [
  { value: 'single_agent', label: 'Assign to single agent' },
  { value: 'last_routed', label: 'Last routed agent' },
  { value: 'group', label: 'Round robin within a group of agents' },
  { value: 'team', label: 'Round robin within a team' },
  { value: 'account_owner', label: 'Assign by account owner' }
]

const liveChatOptions = [
  { value: 'round_robin', label: 'Round Robin' },
  { value: 'quick_draw', label: 'Quick Draw' }
]

const alertOptions = [
  { value: 'round_robin', label: 'Round Robin' },
  { value: 'all_agents', label: 'All agents' }
]

interface RouteToPickerProps {
  route: route
  handleChange: ({ value, property }: { value: any, property: string }) => void
  inline?: boolean
}

function RouteToPicker ({ route, handleChange, inline }: RouteToPickerProps): JSX.Element {
  const method = route.method
  const objectType = method === 'team' ? 'teams' : 'users'
  // const integration = props.integration

  if (method === 'last_routed' || method === 'account_owner' || method === 'global_routing') {
    return <div />
  } else if (method === 'single_agent' || method === 'team') {
    return (
      <div style={{ width: '100%', minWidth: inline ? 250 : undefined }}>
        <Picker
          objectType={objectType}
          selection={route?.objectIDs?.length ? route.objectIDs[0] : null}
          setSelection={(value: number) => {
            const valueArray = value ? [value] : []
            handleChange({ value: valueArray, property: 'objectIDs' })
          }}
        // loadAllProps={{searchParams: {queryParams: {integration_filter: integration}}}}
        />
      </div>
    )
  }

  return (
    <div style={{ width: '100%', minWidth: inline ? 250 : undefined }}>
      <Picker
        objectType={objectType}
        multiple
        selection={route.objectIDs}
        setSelection={(value: number[]) => handleChange({ value, property: 'objectIDs' })}
      />
    </div>
  )
}

function RouteTypePicker (props: any): JSX.Element {
  const route = props.route
  const options = props.alertAgent ? alertOptions : liveChatOptions
  const selected = options.find(option => option.value === route.type)
  if (['team', 'group', 'global_routing'].includes(route.method)) {
    return (
      <Select
        options={options}
        value={selected?.value}
        label='Route Type'
        onChange={(value: any) => props.handleChange({ value, property: 'type' })}
        disableClear
      />
    )
  }
  return (
    <div />
  )
}

type optionProps = {
  route: route
  index: number
  handleChangeProperty: handleChangeProperty
  actionText: string
  permissions?: Record<string, boolean>
  alertAgent?: boolean
  routeTypeOptions?: boolean
  inline?: boolean
  exclude?: string[]
}

export function RouteOption ({ route, index, handleChangeProperty, actionText, alertAgent, routeTypeOptions, inline, exclude }: optionProps): JSX.Element {
  const classes = useStyles()
  const { crmIntegration, flags } = useContext(ShareContext)
  const handleChange = ({ value, property }: any): void => {
    handleChangeProperty({ value, index, property })
  }
  const selected = actionText === 'assign' ? salesloftOptions.find(option => option.value === route.method) : methodOptions.find(option => option.value === route.method)
  // let options = actionText === 'assign' ? salesloftOptions : methodOptions
  // if (permissions && !permissions.routeByAccountOwner) {
  //   options = options.filter(option => option.value !== 'account_owner')
  // }
  const inlineStyle = inline ? { marginBottom: 0, minWidth: 250, marginRight: 10 } : {}
  let options = (actionText === 'assign' ? salesloftOptions : methodOptions).filter(option => !exclude?.includes(option.value))

  if (!flags['global-routing']) {
    options = options.filter((option) => option.value !== 'global_routing')
  }

  return (
    <div
      className={inline ? classes.inlineOptions : ''}
    >
      <div style={inlineStyle} className={(!routeTypeOptions || actionText === 'assign') ? classes.noRouteType : classes.routeTypeDiv}>
        <div style={editableMethods.includes(route.method) ? { display: 'flex', alignItems: 'center', width: '100%' } : undefined}>
          <Select
            options={options}
            value={selected?.value}
            onChange={(value: any) => handleChange({ value, property: 'method' })}
            placeholder='Select route method'
            disableClear
          />
          {route.method === 'account_owner' && crmIntegration && (
            <Tooltip title='Edit ownership rules'>
              <div onClick={() => window.open(`/#/settings/${crmIntegration}`)} className={classes.editIcon}>
                <Icon icon='edit' specificSize={20} color='#9933FF' />
              </div>
            </Tooltip>
          )}
          {route.method === 'global_routing' && (
            <Tooltip title='Edit global routing rules'>
              <div onClick={() => window.open('/#/settings/global_routing')} className={classes.editIcon}>
                <Icon icon='edit' specificSize={20} color='#9933FF' />
              </div>
            </Tooltip>
          )}
        </div>
        {
          routeTypeOptions && actionText !== 'assign' && (
            <RouteTypePicker
              route={route}
              handleChange={handleChange}
              alertAgent={alertAgent}
            />
          )
        }
      </div>
      <RouteToPicker
        route={route}
        handleChange={handleChange}
        inline
      />
    </div>
  )
}

type RoutingBlockProps = {
  route: route
  index: number
  handleChangeProperty: handleChangeProperty
  handleRemoveGroup: (index: number) => void
  actionText: string
  permissions?: Record<string, boolean>
  alertAgent?: boolean
  routeTypeOptions?: boolean
}

function RoutingBlock ({ route, index, handleChangeProperty, handleRemoveGroup, actionText, permissions, alertAgent, routeTypeOptions }: RoutingBlockProps): JSX.Element {
  const classes = useStyles()
  const label = index ? 'Then try:' : 'First try:'
  const { data, isLoading } = RoutingRules.loadOne()
  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className={classes.routingBlock}>
      <div className={classes.blockControls}>
        <div>{label}</div>
        {Boolean(index) &&
          <Button
            onClick={() => handleRemoveGroup(index)}
            label='remove'
            variant='text'
          />}
      </div>
      {route.method === 'global_routing' && !index && !data?.globalFallback?.method && (
        <Alert severity='info'>
          Your rules do not contain a global fallback. Be sure to set a fallback here to account for anyone not covered by your routing rules.
        </Alert>
      )}
      <RouteOption
        route={route}
        index={index}
        handleChangeProperty={handleChangeProperty}
        actionText={actionText}
        permissions={permissions}
        alertAgent={alertAgent}
        routeTypeOptions={routeTypeOptions}
      />
    </div>
  )
}

type routeSelectorProps = {
  routeOptions: route[]
  handleChange: (routeOptions: any) => void
  title?: string
  subtitle?: string
  description?: string
  actionText?: string
  limit?: number
  permissions?: Record<string, boolean>
  alertAgent?: boolean
  routeTypeOptions?: boolean
  borderColor?: string
}

const defaultOption: route = { method: 'single_agent', objectIDs: [], type: 'round_robin' }

export const defaultRoutingOptions = [defaultOption]

export function RoutingSelector (props: routeSelectorProps): JSX.Element {
  const routeOptions = props.routeOptions
  const actionText = props.actionText
  const permissions = props.permissions

  const handleNewGroup = (): void => {
    const options = [...routeOptions].concat(defaultRoutingOptions)
    props.handleChange(options)
  }

  const handleChangeProperty = ({ value, index, property }: any): void => {
    const options = [...routeOptions]
    options[index] = { ...options[index], [property]: value }
    if (property === 'method') {
      options[index] = { ...options[index], objectIDs: [] }
    }
    props.handleChange(options)
  }

  const handleRemoveGroup = (index: number): void => {
    const options = [...routeOptions]
    options.splice(index, 1)
    props.handleChange(options)
  }

  return (
    <ModalSection
      title={props.title ? props.title : 'Routing'}
      subtitle={props.subtitle}
      borderColor={props.borderColor}
    >
      {props.description ? <Typography variant='body2'>{props.description}</Typography> : <></>}
      <div>
        {routeOptions.map((route, index) => (
          <RoutingBlock
            route={route}
            key={index}
            index={index}
            handleChangeProperty={handleChangeProperty}
            handleRemoveGroup={handleRemoveGroup}
            actionText={actionText || ''}
            permissions={permissions}
            alertAgent={props.alertAgent}
            routeTypeOptions={props.routeTypeOptions}
          />
        ))}
        {props.limit && routeOptions.length >= props.limit ? <></> : (
          <div>
            <Button
              variant='outlined'
              onClick={handleNewGroup}
              label='Add Fallback Criteria'
            />
          </div>
        )}
      </div>
    </ModalSection>
  )
}
