import { useState, useEffect, useCallback, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LiveViewTable from './LiveViewTable'
import LiveViewMapPage from './LiveViewMap'
import useInterval from 'use-interval'
import Loader from 'library/loading/Loader'
import { getLiveView, contactSearch } from 'api/contacts'
import clsx from 'clsx'
import { LiveViewDrawer } from './LiveViewDrawer'
import LiveViewHeader from './LiveViewHeader'
import LiveViewFilterBar from './LiveViewFilterBar'
import { ControlButtons } from 'cf-components/FilterBar/FilterBar'
import { useDebounce } from 'use-debounce'
import Slider from '@material-ui/core/Slider'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { DisplaySettings } from 'classes/displaySettings'
import { EVENT_TYPE, Emitter } from 'emitter'

const drawerWidth = 450

const useStyles = makeStyles(theme => ({
  pauseButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 15
  },
  message: {
    marginBottom: 30,
    fontSize: '23px',
    color: '#8E5AE2',
    display: 'flex',
    justifyContent: 'center'
  },
  initializing: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 9999
  },
  liveViewPage: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    backgroundColor: 'white',
    position: 'relative'
  },
  fullScreenLiveViewPage: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    backgroundColor: 'white',
    position: 'relative'
  },
  wrap: {
    '& .jtk-surface': {
      overflowY: 'auto',
      height: '100%',
      width: '100%'
    },
    width: '100%',
    marginRight: 0,
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  wrapShift: {
    '& .jtk-surface': {
      overflowY: 'auto',
      height: '100%',
      width: '100%'
    },
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  customMenu: {
    paddingTop: 10,
    paddingBottom: 5,
    width: 400,
    fontFamily: 'Poppins, sans serif',
    '& div:nth-child(even)': {
      backgroundColor: '#F1F1F1'
    }
  },
  filterItem: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    alignItems: 'center',
    padding: '3px 10px',
    gridGap: 10,
    height: 36
  },
  checkboxItem: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    alignItems: 'center',
    padding: '0px 10px',
    gridGap: 10,
    height: 42
  },
  checkboxDiv: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filterHeader: {
    fontSize: 18,
    padding: '0px 10px 5px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mapContainer: {
    height: 'calc(100% - 55px)',
    display: 'flex',
    width: '100%',
    position: 'relative'
  }
}))

const initialState = {
  openOpp: false,
  highSignalsScore: false,
  buyingCommittee: false,
  matchesICP: false,
  otherTraffic: false
}

const trafficFilterReducer = (state, action) => {
  switch (action.type) {
    case 'openOpp':
      return { ...state, openOpp: action.value }
    case 'highSignalsScore':
      return { ...state, highSignalsScore: action.value }
    case 'buyingCommittee':
      return { ...state, buyingCommittee: action.value }
    case 'matchesICP':
      return { ...state, matchesICP: action.value }
    case 'otherTraffic':
      return { ...state, otherTraffic: action.value }
    case 'setAll':
      if (
        Object.keys(state).filter(key => state[key]).length === 4 &&
        action.value === Object.keys(state).filter(key => !state[key])[0]
      ) {
        return {
          openOpp: false,
          highSignalsScore: false,
          buyingCommittee: false,
          matchesICP: false,
          otherTraffic: false
        }
      }
      return {
        openOpp: true,
        highSignalsScore: true,
        buyingCommittee: true,
        matchesICP: true,
        otherTraffic: true
      }
    default:
      return state
  }
}

const defaultFilters = {
  visits: [0, 10],
  conversations: [0, 10],
  pageViews: [0, 10],
  timeOnSite: [0, 30],
  sourceKnown: false,
  contactInfo: false,
  following: false
}

function ValueLabelComponent (props) {
  const { children, open, value } = props

  const title = value === 10 ? '10+' : value

  return (
    <Tooltip open={open} enterTouchDelay={0} placement='top' title={title}>
      {children}
    </Tooltip>
  )
}

function MinutesValueLabel (props) {
  const { children, open, value } = props

  const title = value === 30 ? '30+ min' : value + ' min'

  return (
    <Tooltip open={open} enterTouchDelay={0} placement='top' title={title}>
      {children}
    </Tooltip>
  )
}

function CustomFilterMenu (props) {
  const classes = useStyles()
  const [state, setState] = useState(props.filters)

  const handleChange = (newValue, filter) => {
    setState({
      ...state,
      [filter]: newValue
    })
  }

  return (
    <div className={classes.customMenu}>
      <div className={classes.filterHeader}>
        Live View Filters
        <Button
          color='primary'
          onClick={() => {
            setState(defaultFilters)
          }}
        >
          Reset
        </Button>
      </div>
      <div className={classes.filterItem}>
        Visits
        <div style={{ display: 'flex' }}>
          <Slider
            value={state.visits}
            onChange={(event, newValue) => handleChange(newValue, 'visits')}
            max={10}
            valueLabelDisplay='auto'
            ValueLabelComponent={ValueLabelComponent}
          />
        </div>
      </div>
      <div className={classes.filterItem}>
        Conversations
        <Slider
          value={state.conversations}
          onChange={(event, newValue) => handleChange(newValue, 'conversations')}
          max={10}
          valueLabelDisplay='auto'
          ValueLabelComponent={ValueLabelComponent}
        />
      </div>
      <div className={classes.filterItem}>
        Page Views
        <Slider
          value={state.pageViews}
          onChange={(event, newValue) => handleChange(newValue, 'pageViews')}
          max={10}
          valueLabelDisplay='auto'
          ValueLabelComponent={ValueLabelComponent}
        />
      </div>
      <div className={classes.filterItem}>
        Time on Site
        <Slider
          value={state.timeOnSite}
          onChange={(event, newValue) => handleChange(newValue, 'timeOnSite')}
          max={30}
          valueLabelDisplay='auto'
          ValueLabelComponent={MinutesValueLabel}
        />
      </div>
      <div className={classes.checkboxItem}>
        Source Known
        <div className={classes.checkboxDiv}>
          <Checkbox
            color='primary'
            checked={state.sourceKnown}
            onChange={(event) => handleChange(event.target.checked, 'sourceKnown')}
          />
        </div>
      </div>
      <div className={classes.checkboxItem}>
        Contact Info
        <div className={classes.checkboxDiv}>
          <Checkbox
            color='primary'
            checked={state.contactInfo}
            onChange={(event) => handleChange(event.target.checked, 'contactInfo')}
          />
        </div>
      </div>
      {/* <div className={classes.checkboxItem}>
        Following
        <div className={classes.checkboxDiv}>
          <Checkbox
            color='primary'
            checked={state.following}
            onChange={(event) => handleChange(event.target.checked, 'following')}
          />
        </div>
      </div> */}
      <ControlButtons
        handleCancel={() => props.handleClose()}
        handleApply={() => {
          props.setFilters(state)
          props.handleClose()
        }}
      />
    </div>
  )
}

export interface TempParticipant {
  id: number
}

export function MapParticipantList (dataRows, contacts): TempParticipant[] {
  const rows = dataRows.map(row => {
    const location = row.attributes.city + ', ' + row.attributes.region_code
    const personID = row.relationships.is_person.data.id
    const person = contacts[personID]
    let name = ''
    let company = ''
    let timeOnSite
    if (person) {
      name = person.attributes.first_name + ' ' + person.attributes.last_name
      company = person?.attributes?.company_name || ''
    }
    let domain
    let sixSenseName
    if (row.attributes.company_name) {
      if (person) {
        domain = person.attributes.domain
      } else {
        domain = row.attributes.domain
      }
      sixSenseName = row.attributes.company_name
    }

    const now = new Date()
    const start = new Date(row.attributes.session_start)
    const minutes = Math.round((now - start) / 1000 / 60)
    if (minutes > 30) {
      timeOnSite = '30+ min'
    } else if (minutes < 1) {
      timeOnSite = 'Few seconds'
    } else {
      timeOnSite = minutes + ' min'
    }

    return (
      {
        id: row.attributes.participant_id,
        eventID: row.attributes.event_type ? (row.attributes.participant_id + row.attributes.event_type) : '',
        personID: personID,
        name: name,
        person: person,
        company: company,
        currentPage: row.attributes.current_page,
        currentUrl: row.attributes.current_url,
        eventType: row.attributes.event_type,
        timeOnSite: timeOnSite,
        totalPagesViewed: row.attributes.page_views,
        conversationsToDate: row.attributes.conversation_count,
        totalVisits: row.attributes.total_visits,
        firstSeen: row.attributes.first_seen,
        recentConversationTimestamp: row.attributes.recent_conversation_timestamp,
        recentConversationID: row.attributes.recent_conversation_id,
        trafficSource: row.attributes.referrer,
        location: location,
        city: row.attributes.city,
        region: row.attributes.region_code,
        latitude: row.attributes.latitude,
        longitude: row.attributes.longitude,
        country: row.attributes.country_name,
        continent: row.attributes.continent_name,
        time: minutes,
        domain: domain,
        sixSenseName: sixSenseName,
        signalsScore: row.attributes.score,
        isICP: row.attributes.is_icp,
        opportunityStatus: row.attributes.opp_status,
        fromBuyingCommittee: row.attributes.from_buying_committee
      })
  })

  return rows
}

function meetsFilterCriteria (filters, row) {
  if (filters.contactInfo && !row.personID) {
    return false
  }
  if (filters.sourceKnown && !row.trafficSouce) {
    return false
  }
  const lowerVisits = filters.visits[0]
  const upperVisits = filters.visits[1]
  if (lowerVisits > 0 && row.totalVisits < lowerVisits) {
    return false
  }
  if (upperVisits < 10 && row.totalVisits > upperVisits) {
    return false
  }
  const lowerConversations = filters.conversations[0]
  const upperConversations = filters.conversations[1]
  if (lowerConversations > 0 && row.conversationsToDate < lowerConversations) {
    return false
  }
  if (upperConversations < 10 && row.conversationsToDate > upperConversations) {
    return false
  }
  const lowerViews = filters.pageViews[0]
  const upperViews = filters.pageViews[1]
  if (lowerViews > 0 && row.totalPagesViewed < lowerViews) {
    return false
  }
  if (upperViews < 10 && row.totalPagesViewed > upperViews) {
    return false
  }
  const lowerTime = filters.timeOnSite[0]
  const upperTime = filters.timeOnSite[1]
  if (lowerTime > 0 && row.time < lowerTime) {
    return false
  }
  if (upperTime < 30 && row.time > upperTime) {
    return false
  }
  return true
}

function filterRows (searchTerm, rows, filters, trafficFilterState) {
  let filteredRows = rows
  if (searchTerm) {
    const search = searchTerm.toLowerCase()
    filteredRows = rows.filter(r => {
      if (r?.name && r.name.toLowerCase().includes(search)) {
        return true
      } else if (r?.company && r.company.toLowerCase().includes(search)) {
        return true
      } else if (r?.location && r.location.toLowerCase().includes(search)) {
        return true
      } else if (r?.currentPage && r.currentPage.toLowerCase().includes(search)) {
        return true
      }
      return false
    })
  }

  return filteredRows.filter(r => meetsFilterCriteria(filters, r))
}

const sortValues = [
  { label: 'Visitor', id: 'name' },
  { label: 'Company', id: 'company' },
  { label: 'Current Web Page', id: 'currentPage' },
  // { label: 'Highlights', id: 'eventType' },
  { label: 'Location', id: 'location' },
  { label: 'Signals Score', id: 'signalsScore' }
]

function LiveViewPage (props) {
  const classes = useStyles()
  const [liveParticipants, setLiveParticipants] = useState([])
  const [active, setActive] = useState(true)
  const [contacts, setContacts] = useState({})
  const [initializing, setInitializing] = useState(true)
  const [refresh, setRefreshed] = useState(false)
  const [search, setSearch] = useState('')
  const [sortCriteria, setSortCriteria] = useState('signalsScore')
  const [sortAscending, setSortAscending] = useState(false)
  const [debouncedSearch] = useDebounce(search, 500)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [mode, setMode] = useState('map')
  const [participant, setParticipant] = useState(null)
  const [showLoading, setShowLoading] = useState(true)
  const [filters, setFilters] = useState(defaultFilters)
  const [displayRegion, setDisplayRegion] = useState('world')
  const [autoRotateGlobe, setAutoRotateGlobe] = useState(false)
  const { settings, isLoading } = DisplaySettings.load()
  const [trafficFilterState, trafficFilterDispatch] = useReducer(trafficFilterReducer, initialState)
  const [showHiddenElements, setShowHiddenElements] = useState(true)
  const [fullScreen, setFullScreen] = useState(false)
  const [timer, setTimer] = useState(null)
  const [autoFocus, setAutoFocus] = useState(false)

  /*
    Just as a note when we are in fullscreen mode autofocus should be enabled by default
    If the user is looking at the globe view then globe rotation should be on as a default
  */
  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
      setShowHiddenElements(true)
      setFullScreen(false)
      Emitter.emit(EVENT_TYPE.FULLSCREEN, false)
    } else {
      const el = document.documentElement
      el.requestFullscreen(el)
      setFullScreen(true)
      setAutoFocus(true)
      setAutoRotateGlobe(true)
      Emitter.emit(EVENT_TYPE.FULLSCREEN, true)
    }
  }

  const mouseMoveEvent = (e) => {
    if (timer) clearTimeout(timer)
    setShowHiddenElements(true)
    setTimer(setTimeout(() => {
      if (document.fullscreenElement) {
        setShowHiddenElements(false)
      }
    }, 5000))
  }

  function handleParticipantSelect (participant) {
    setParticipant(participant)
    setDrawerOpen(true)
  }

  const handleSetRegion = (region) => {
    setDisplayRegion(region)
    DisplaySettings.update({ page: 'live_view_region', type: 'region', settings: region })
  }
  const refreshParticipants = useCallback(() => {
    getLiveView()
      .then(response => {
        const contactIDs = response.data.map(row => row.relationships.is_person.data.id).filter(id => id)
        if (contactIDs.length) {
          contactSearch({ contactIDs }).then(contactResponse => {
            let contactDict = {}
            contactResponse.data.map(
              contact => (contactDict = { ...contactDict, [contact.id]: { ...contact } })
            )
            setContacts(contactDict)
            setLiveParticipants(MapParticipantList(response.data, contactDict))
            setInitializing(false)
            setRefreshed(r => !r)
          })
        } else {
          setLiveParticipants(MapParticipantList(response.data, {}))
          setInitializing(false)
          setRefreshed(r => !r)
        }
      })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (!settings.liveViewRegion) {
        DisplaySettings.create({ page: 'live_view_region', type: 'region', settings: 'world' })
        setDisplayRegion('world')
      } else setDisplayRegion(settings.liveViewRegion.settings.region)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, settings])

  useEffect(() => {
    if (active) {
      refreshParticipants()
    }
  }, [refreshParticipants, active])

  useEffect(() => {
    if (!initializing) {
      setTimeout(() => {
        setShowLoading(false)
      }, 700)
    }
  }, [initializing])

  useInterval(() => {
    if (active) {
      refreshParticipants()
    }
  }, 10000)

  const regionFilter = (rows) => {
    switch (displayRegion) {
      case 'unitedStates':
        return rows.filter(row => row.country === 'United States')
      case 'africa':
        return rows.filter(row => row.continent === 'Africa')
      case 'asia':
        return rows.filter(row => row.continent === 'Asia')
      case 'europe':
        return rows.filter(row => row.continent === 'Europe')
      case 'northAmerica':
        return rows.filter(row => row.continent === 'North America')
      case 'southAmerica':
        return rows.filter(row => row.continent === 'South America')
      case 'oceania':
        return rows.filter(row => row.continent === 'Oceania')
      default:
        return rows
    }
  }

  const trafficFiltering = (rows) => {
    return rows.filter(r => {
      if (!trafficFilterState.openOpp && r.opportunityStatus === 'Open Opp') {
        return true
      }
      if (!trafficFilterState.highSignalsScore && r.signalsScore >= 70) {
        return true
      }
      if (!trafficFilterState.buyingCommittee && r.fromBuyingCommittee) {
        return true
      }
      if (!trafficFilterState.matchesICP && r.isICP) {
        return true
      }
      if (!trafficFilterState.otherTraffic && !r.fromBuyingCommittee && r.signalsScore < 70 && !r.isICP && r.opportunityStatus !== 'Open Opp') {
        return true
      }
      return false
    })
  }

  const filteredRows = filterRows(debouncedSearch, liveParticipants, filters)
  const displayRows = regionFilter(trafficFiltering(filteredRows))

  const sortOptions = {
    sortValues: sortValues,
    sortCriteria: sortCriteria,
    setSortCriteria: setSortCriteria,
    sortAscending: sortAscending,
    setSortAscending: setSortAscending
  }

  const filterOptions = {
    customFilterMenu: CustomFilterMenu,
    menuProps: {
      filters: filters,
      setFilters: setFilters
    }
  }

  return (
    <div className={fullScreen ? classes.fullScreenLiveViewPage : classes.liveViewPage} onMouseMove={fullScreen ? mouseMoveEvent : undefined} style={{ cursor: showHiddenElements ? 'default' : 'none' }}>
      {showLoading && (
        <div className={classes.initializing}>
          <div>
            <div className={classes.message}>
              Initializing Live View
            </div>
            <div className={classes.loader}>
              <Loader type='spinner' />
            </div>
          </div>
        </div>
      )}
      {!fullScreen && (
        <LiveViewHeader
          mode={mode}
          setMode={setMode}
          active={active}
          setActive={setActive}
        />
      )}
      <div id='live-view-page' style={{ position: 'relative', height: fullScreen ? '100%' : 'calc(100% - 45px)' }}>
        <div className={clsx(classes.wrap, { [classes.wrapShift]: drawerOpen })}>
          <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <div style={{ height: '100%', overflowY: 'auto', width: '100%', backgroundColor: '#f8f8f8' }}>
              <LiveViewFilterBar
                rows={liveParticipants}
                displayRegion={displayRegion}
                autoRotateGlobe={autoRotateGlobe}
                setAutoRotateGlobe={setAutoRotateGlobe}
                setDisplayRegion={handleSetRegion}
                search={search}
                setSearch={setSearch}
                sortOptions={mode === 'list' ? sortOptions : null}
                onlineCount={liveParticipants.length}
                filterOptions={filterOptions}
                mode={mode}
                trafficFilterState={trafficFilterState}
                trafficFilterDispatch={trafficFilterDispatch}
                setRefreshed={setRefreshed}
                showHidden={showHiddenElements}
                autoFocus={autoFocus}
                setAutoFocus={setAutoFocus}
              />
              <div className={classes.mapContainer}>
                {mode === 'list' ? (
                  <LiveViewTable
                    rows={displayRows}
                    contacts={contacts}
                    handleParticipantSelect={handleParticipantSelect}
                    participant={participant}
                    sortOptions={sortOptions}
                  />
                ) : (
                  <LiveViewMapPage
                    data={displayRows}
                    displayRegion={displayRegion}
                    autoRotate={autoRotateGlobe}
                    handleParticipantSelect={handleParticipantSelect}
                    initializing={initializing}
                    refresh={refresh}
                    setFullScreen={handleFullScreen}
                    showHidden={showHiddenElements}
                    autoFocus={autoFocus}
                  />
                )}
              </div>
            </div>
            <LiveViewDrawer
              open={drawerOpen}
              participant={participant}
              close={() => setDrawerOpen(false)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveViewPage
